import React, { Component } from 'react';
import propTypes from 'prop-types';
import axios from 'axios';
import { Form } from 'react-bootstrap';

export default class SldpGroupDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      groups: props.groups,
    };
    this.updateCurSelection = this.updateCurSelection.bind(this);
  }

  componentDidMount() {
    const { props } = this;
    let projectType;
    if (props.projectType === '-1') {
      projectType = 'all';
    } else projectType = props.projectType;
    if (projectType !== '-1') {
      axios.get('/sldp-groups/'.concat(projectType)).then((res) => {
        this.setState({
          groups: res.data,
        });
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { props } = this;
    if (prevProps.projectType !== props.projectType) {
      axios.get('/sldp-groups/'.concat(props.projectType)).then((res) => {
        this.setState({
          groups: res.data,
        });
      });
    }
  }

  updateCurSelection(e) {
    const { props } = this;
    props.parentCallback(e.target.value);
  }

  render() {
    const { props } = this;
    const curState = this.state;
    const allGroups = curState.groups;
    const selectOptions = allGroups.map((group) => {
      const { members } = group;
      let membersStr = '';
      for (let i = 0; i < members.length; i += 1) {
        membersStr += members[i].firstName;
        if (i < members.length - 1) {
          membersStr += ', ';
        }
      }
      return (
        <option id={group.group_id} key={group.group_id} value={group.group_id}>
          ({group.secName}) {membersStr}
        </option>
      );
    });

    return (
      <div>
        <Form.Control value={props.default} as="select" onChange={this.updateCurSelection}>
          <option value="-1">---Select Group---</option>
          {selectOptions}
        </Form.Control>
      </div>
    );
  }
}

SldpGroupDropdown.propTypes = {
  groups: propTypes.arrayOf(
    propTypes.shape({
      group_id: propTypes.number,
      secName: propTypes.string,
      projectType: propTypes.string,
      contractIndex: propTypes.number,
      numPartners: propTypes.number,
      benchmarkA: propTypes.number,
      benchmarkB: propTypes.number,
      commissioned: propTypes.string,
      benchA_date: propTypes.string,
      benchB_date: propTypes.string,
      comm_date: propTypes.string,
      past_semester: propTypes.number,
      radMentorID: propTypes.number,
      radProjectTitle: propTypes.string,
      members: propTypes.arrayOf(
        propTypes.shape({
          userID: propTypes.number,
          firstName: propTypes.string,
          lastName: propTypes.string,
        }),
      ),
    }),
  ),
  parentCallback: propTypes.func,
  default: propTypes.string,
  projectType: propTypes.string,
};

SldpGroupDropdown.defaultProps = {
  groups: [
    {
      group_id: -1,
      secName: '-1',
      projectType: '-1',
      contractIndex: -1,
      numPartners: -1,
      benchmarkA: -1,
      benchmarkB: -1,
      commissioned: '-1',
      benchA_date: '-1',
      benchB_date: '-1',
      comm_date: '-1',
      past_semester: -1,
      radMentorID: -1,
      radProjectTitle: '-1',
      members: [
        {
          userID: -1,
          firstName: '-1',
          lastName: '-1',
        },
        {
          userID: -1,
          firstName: '-1',
          lastName: '-1',
        },
        {
          userID: -1,
          firstName: '-1',
          lastName: '-1',
        },
      ],
    },
  ],
  default: '-1',
  parentCallback: () => {},
  projectType: '-1',
};
