import React, { Component } from 'react';
import axios from 'axios';
import { Button, Table, Form } from 'react-bootstrap';
import propTypes from 'prop-types';

const config = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
};
const qs = require('querystring-es3');

export default class ManageRnDProjects extends Component {
  constructor(props) {
    super(props);

    this.state = {
      projects: props.projects,
      curEditing: props.curEditing,
      curNewName: props.curNewName,
      curEditingName: props.curEditingName,
    };

    this.setCurEditing = this.setCurEditing.bind(this);
    this.updateCurEditingName = this.updateCurEditingName.bind(this);
    this.updateCurNewName = this.updateCurNewName.bind(this);
    this.reloadProjects = this.reloadProjects.bind(this);
    this.submitEdit = this.submitEdit.bind(this);
    this.doneEditing = this.doneEditing.bind(this);
    this.deleteProject = this.deleteProject.bind(this);
    this.submitNewProject = this.submitNewProject.bind(this);
  }

  componentDidMount() {
    axios.get('/rnd-projects/').then((res) => {
      console.log('response', res);
      this.setState({
        projects: res.data,
      });
    });
  }

  setCurEditing(project = {}) {
    this.setState({
      curEditing: project.proj_ID,
      curEditingName: project.Project_name,
    });
  }

  updateCurEditingName(e) {
    this.setState({
      curEditingName: e.target.value,
    });
  }

  updateCurNewName(e) {
    this.setState({
      curNewName: e.target.value,
    });
  }

  reloadProjects() {
    axios.get('/rnd-projects/').then((res) => {
      console.log('response', res);
      this.setState({
        projects: res.data,
      });
    });
  }

  submitEdit(id) {
    const curState = this.state;
    const name = curState.curEditingName;

    axios
      .put(`/rnd-projects/${id}`, qs.stringify({ name }), config)
      .then((response) => {
        console.log('response', response);
        this.doneEditing();
        this.reloadProjects();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  doneEditing() {
    this.setState({
      curEditing: -1,
      curEditingName: '',
    });
  }

  submitNewProject() {
    const { curNewName } = this.state;

    axios
      .post('/rnd-projects/', qs.stringify({ name: curNewName }), config)
      .then((response) => {
        console.log('response', response);
        this.reloadProjects();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  deleteProject(id) {
    axios
      .delete(`/rnd-projects/${id}`)
      .then((response) => {
        console.log('response', response);
        this.reloadProjects();
      })
      .catch((err) => {
        console.log(err);
      });
    this.setCurEditing(-1); // we're no longer editing any sections
  }

  render() {
    const { projects, curEditing } = this.state;
    let trs;

    console.log('Cur state in RND Projects: ', this.state);

    if (curEditing === -1) {
      // nothing is being edited
      trs = projects.map((project) => (
        <tr key={`row ${project.proj_ID}`} id={`row ${project.proj_ID}`}>
          <td key={`id ${project.proj_ID}`} id={`id ${project.proj_ID}`}>
            {project.proj_ID}
          </td>
          <td key={`name${project.proj_ID}`} id={`name${project.proj_ID}`}>
            {project.Project_name}
          </td>
          <td>
            <Button
              variant="primary"
              key={`edit-button ${project.proj_ID}`}
              onClick={() => this.setCurEditing(project)}
            >
              Edit
            </Button>
          </td>
        </tr>
      ));
    } else {
      // something is being edited
      trs = projects.map((project) => {
        if (curEditing !== project.proj_ID) {
          // this specific row is not the one being edited
          return (
            <tr key={`row ${project.proj_ID}`} id={`row ${project.proj_ID}`}>
              <td key={`id-edit ${project.proj_ID}`} id={`id-edit ${project.proj_ID}`}>
                {project.proj_ID}
              </td>
              <td key={`namee-edit${project.proj_ID}`} id={`namee-edit${project.proj_ID}`}>
                {project.Project_name}
              </td>
              <Button
                variant="primary"
                key={`edit-button ${project.proj_ID}`}
                onClick={() => this.setCurEditing(project.proj_ID)}
              >
                Edit
              </Button>
            </tr>
          );
        }
        // this row is being edited
        return (
          <tr key={`tr ${project.proj_ID}`} id={`tr ${project.proj_ID}`}>
            <td key={`id-edit${project.proj_ID}`} id={`id ${project.proj_ID}`}>
              {project.proj_ID}
            </td>
            <td key={`name-edit ${project.proj_ID}`} id={`end-edit ${project.proj_ID}`}>
              <input
                key={`input-name-${project.proj_ID}`}
                defaultValue={project.Project_name}
                onBlur={this.updateCurEditingName}
              />
            </td>
            <td key={`edit-cancel${project.proj_ID}`} id={`edit-cancel${project.proj_ID}`}>
              <Button
                variant="primary"
                key={`submit-edit-button ${project.proj_ID}`}
                onClick={() => this.submitEdit(project.proj_ID)}
              >
                Submit
              </Button>
              <Button
                variant="secondary"
                key={`cancel-button ${project.proj_ID}`}
                onClick={() => this.doneEditing()}
              >
                Cancel
              </Button>
              <Button
                variant="danger"
                key={`delete-button ${project.proj_ID}`}
                onClick={() => this.deleteProject(project.proj_ID)}
              >
                Delete
              </Button>
            </td>
          </tr>
        );
      });
    }

    return (
      <div>
        <Table bordered striped hover>
          <thead>
            <tr>
              <th>ID</th>
              <th>Project Name</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>{trs}</tbody>
        </Table>

        <hr />
        <h2>Create Project</h2>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Name</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <Form>
                  <Form.Control onChange={this.updateCurNewName} />
                </Form>
              </td>
              <td>
                <Button key="make-new-project-button" onClick={this.submitNewProject}>
                  Submit
                </Button>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    );
  }
}

ManageRnDProjects.propTypes = {
  projects: propTypes.arrayOf(
    propTypes.shape({
      Project_name: propTypes.string,
      proj_ID: propTypes.number,
    }),
  ),
  curEditing: propTypes.number,
  curNewName: propTypes.string,
  curEditingName: propTypes.string,
};

ManageRnDProjects.defaultProps = {
  projects: [
    {
      Project_name: '-1',
      proj_ID: -1,
    },
  ],
  curEditing: -1,
  curNewName: '',
  curEditingName: '',
};
