import styled from 'styled-components';

export const LinkText = styled.div`
  color: #007bff;
  cursor: pointer;
`;

export const CenterGrayText = styled.div`
  text-align: center;
  padding-top: 100px;
  color: gray;
`;
