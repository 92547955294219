/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Button, Form, Col, Row, Tab, Tabs } from 'react-bootstrap';
import './sldp.scss';
import axios from 'axios';
import SldpForm from './sldpForm';
import SectionsDropdown from '../convenientDropdowns/sectionsDropdown';
import Alerts from '../alert/alertCard';
import UserPermissionsComponent from '../../../UserPermissionsComponent';
import { Spinner } from '../sharedComponents/spinner';
import { LinkText, CenterGrayText } from '../sharedComponents/commonComponents';

const searchTranslation = { 1: 'Member Last Name', 2: 'Section', 3: 'Project Type' };

export default class SldpActions extends UserPermissionsComponent {
  constructor(props) {
    super(props);
    this.state = {
      userInfo: {},
      sldpList: {},
      groupResults: {},
      searchBy: 1,
      searchValue: '',
      isLoading: true,
      isLoadingResults: false,
      searchResults: {}, // obj => group IDs:list of names
    };
    this.handleSelect = this.handleSelect.bind(this);
    this.handlesearchValue = this.handlesearchValue.bind(this);
    this.populateForm = this.populateForm.bind(this);
    this.childFunc = React.createRef();
  }

  // loading user data into userInfo
  componentDidMount() {
    this.setState({ isLoading: true });
    // console.log('UserPermissionsComponent.isTA', this.isTA())
    // console.log('UserPermissionsComponent.isTA)', UserPermissionsComponent.isTA)
    axios
      .get('/getUserInfo/')
      .then((result) => {
        this.setState({
          userInfo: result.data ? result.data : {},
        });
        if (result.data) {
          axios.get('/sldp-forms-sldp-list/').then((res) => {
            const sldpListTemp = {};
            const { data } = res;
            for (let index = 0; index < data.length; index += 1) {
              const sldpInfo = data[index];
              sldpListTemp[sldpInfo.sldpID] = `${sldpInfo.longName} (${sldpInfo.shortName})`;
            }
            this.setState({ sldpList: sldpListTemp });
          });
        }
        this.setState({ isLoading: false });
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  }

  handleSelect = (e) => {
    const curState = this.state;
    if (parseInt(e.target.value, 10) === 3) {
      this.setState({
        searchBy: e.target.value,
        searchValue: String(Object.keys(curState.sldpList)[0]),
      });
    } else if (parseInt(e.target.value, 10) === 2) {
      this.setState({
        searchBy: e.target.value,
        searchValue: -1,
      });
    } else {
      this.setState({ searchBy: e.target.value, searchValue: '' });
    }
  };

  handlesearchValue = (e) => {
    this.setState({ searchValue: e.target.value });
  };

  nullChecker = (input) => {
    if (input === null || input === undefined) {
      return 'N/A';
    }
    return input;
  };

  searchRequest = () => {
    // console.log('searchRequest UserPermissionsComponent.isTA', this.isTA(), this.isHeadTA(), this.isStudent())
    if (!(this.isTA() || this.isProf())) {
      return;
    }
    const curState = this.state;
    this.setState({ groupResults: {} });
    if (curState.searchBy === '1' || curState.searchBy === 1) {
      if (curState.searchValue !== '') {
        this.setState({ isLoadingResults: true });
        axios
          .get(`/sldp-forms-last-name/`.concat(curState.searchValue.trim()))
          .then((res) => {
            const dataOrganized = {};
            for (let ind = 0; ind < res.data.length; ind += 1) {
              const i = res.data[ind];
              this.setState({ groupResults: { ...curState.groupResults } });
              const [firstname, lastname, secName, sldpType, sldpID, secID] = this.nullChecker([
                i.firstName,
                i.lastName,
                i.secName,
                i.shortName,
                i.sldpID,
                i.secID,
              ]);
              if (dataOrganized[i.group_id] !== undefined) {
                dataOrganized[i.group_id].name.push(`${firstname} ${lastname}`);
              } else {
                dataOrganized[i.group_id] = {
                  secName,
                  sldpID,
                  secID,
                  name: [`${firstname} ${lastname}`],
                  sldpType,
                };
              }
            }
            this.setState({ searchResults: dataOrganized });
          })
          .then(() => {
            this.setState({ isLoadingResults: false });
          });
      }
    } else if (curState.searchBy === '2' || curState.searchBy === 2) {
      if (
        curState.searchValue !== -1 &&
        curState.searchValue !== '-1' &&
        curState.searchValue !== ''
      ) {
        this.setState({ isLoadingResults: true });
        axios
          .get(`/sldp-forms-secName/`.concat(curState.searchValue))
          .then((res) => {
            const dataOrganized = {};
            for (let ind = 0; ind < res.data.length; ind += 1) {
              const i = res.data[ind];
              const [firstname, lastname, secName, sldpType, sldpID, secID] = this.nullChecker([
                i.firstName,
                i.lastName,
                i.secName,
                i.shortName,
                i.sldpID,
                i.secID,
              ]);
              if (dataOrganized[i.group_id] !== undefined) {
                dataOrganized[i.group_id].name.push(`${firstname} ${lastname}`);
              } else {
                dataOrganized[i.group_id] = {
                  secName,
                  sldpType,
                  sldpID,
                  secID,
                  name: [`${firstname} ${lastname}`],
                };
              }
            }
            this.setState({ searchResults: dataOrganized });
          })
          .catch((err) => {
            this.childFunc.current.addAlert({
              type: 1,
              title: `Groups Could Not Be Retrieved`,
              text: JSON.stringify(err),
            });
            this.setState({ isLoadingResults: false });
          })
          .then(() => {
            this.setState({ isLoadingResults: false });
          });
      }
    } else if (curState.searchBy === '3' || curState.searchBy === 3) {
      if (curState.searchValue !== '') {
        this.setState({ isLoadingResults: true });
        axios
          .get(`/sldp-forms-project-type/`.concat(String(curState.searchValue).trim()))
          .then((res) => {
            const dataOrganized = {};
            for (let ind = 0; ind < res.data.length; ind += 1) {
              const i = res.data[ind];
              const [firstname, lastname, secName, sldpType, sldpID, secID] = this.nullChecker([
                i.firstName,
                i.lastName,
                i.secName,
                i.shortName,
                i.sldpID,
                i.secID,
              ]);
              if (dataOrganized[i.group_id] !== undefined) {
                dataOrganized[i.group_id].name.push(`${firstname} ${lastname}`);
              } else {
                dataOrganized[i.group_id] = {
                  secName,
                  sldpID,
                  sldpType,
                  secID,
                  name: [`${firstname} ${lastname}`],
                };
              }
            }
            this.setState({ searchResults: dataOrganized });
          })
          .catch((err) => {
            this.childFunc.current.addAlert({
              type: 1,
              title: `Groups Could Not Be Retrieved`,
              text: JSON.stringify(err),
            });
            this.setState({ isLoadingResults: false });
          })
          .then(() => {
            this.setState({ isLoadingResults: false });
          });
      }
    }
  };

  getCurSection = (childData) => {
    this.setState({
      searchValue: childData,
    });
  };

  searchMethod = () => {
    const curState = this.state;
    if (parseInt(curState.searchBy, 10) === 1) {
      return (
        <Form.Control
          placeholder="Search By Last Name ..."
          onChange={(e) => this.handlesearchValue(e)}
          value={curState.searchValue}
        />
      );
    }
    if (parseInt(curState.searchBy, 10) === 2) {
      return (
        <div className="maxWidth">
          <SectionsDropdown
            default={curState.searchValue}
            limited
            parentCallback={this.getCurSection}
          />
        </div>
      );
    }
    return (
      <Form.Control
        value={curState.searchValue}
        as="select"
        onChange={(e) => this.handlesearchValue(e)}
      >
        {Object.keys(curState.sldpList).map((sldpID) => (
          <option key={sldpID} value={sldpID}>
            {curState.sldpList[sldpID]}
          </option>
        ))}
      </Form.Control>
    );
  };

  populateForm = async (groupID, chosenResult) => {
    console.log(
      'searchRequest UserPermissionsComponent.isTA',
      this.isTA(),
      this.isHeadTA(),
      this.isStudent(),
    );
    if (!(this.isTA() || this.isProf())) {
      return;
    }
    const [memberName, sldpType, secName, sldpID, secID] = [
      chosenResult.name.join(', '),
      chosenResult.sldpType,
      chosenResult.secName,
      chosenResult.sldpID,
      chosenResult.secID,
    ];
    const urlAdd = `${String(sldpID)}/${String(groupID)}`;
    axios.get(`/sldp-req-by-id/`.concat(urlAdd.trim())).then((res) => {
      this.setState({
        searchResults: {
          [groupID]: { memberName, secID, sldpType, secName, requirements: res.data },
        },
      });
    });
  };

  render() {
    const curState = this.state;

    if (curState.isLoading) {
      return <Spinner />;
    }

    if (
      !(this.isTA() || this.isProf()) ||
      Object.keys(curState.userInfo).length === 0 ||
      !curState.userInfo
    ) {
      return (
        <div>
          <h1 className="centered">Please Sign In To View This Page</h1>
        </div>
      );
    }
    return (
      <div className="sldpActionAll">
        <Alerts ref={this.childFunc} alert={[]} />
        <h3 className="display-6 mb-3">Search Forms</h3>
        <Row>
          <Col xs={1}>
            <div className="searchBy">
              <b>Search By</b>
            </div>
          </Col>
          <Col xs={11} id="noPadding">
            <div className="flex">
              <Form.Control
                value={curState.searchBy}
                as="select"
                variant="primary"
                onChange={this.handleSelect}
                id="searchBy"
              >
                {Object.keys(searchTranslation).map((choice) => (
                  <option id={choice} key={choice} value={choice}>
                    {searchTranslation[choice]}
                  </option>
                ))}
              </Form.Control>
              <this.searchMethod />
            </div>
          </Col>
        </Row>
        <Row id="paddingTop">
          <Col>
            <Button onClick={() => this.searchRequest()} variant="primary">
              Submit Request
            </Button>
          </Col>
        </Row>
        <hr />
        {Object.keys(curState.searchResults).length > 1 ? (
          <div>
            <h3>{Object.keys(curState.searchResults).length} Results Found</h3>
            <p id="grayText">The Group ID and members of each group are displayed</p>
            <ul id="groupsResultList">
              {curState.isLoadingResults ? (
                <Spinner />
              ) : (
                Object.keys(curState.searchResults).map((groupID) => (
                  <li key={groupID}>
                    <LinkText
                      onClick={() => this.populateForm(groupID, curState.searchResults[groupID])}
                    >
                      <u>
                        {curState.searchResults[groupID].sldpType}, Section:{' '}
                        {curState.searchResults[groupID].secName} <br /> Members:{' '}
                        {curState.searchResults[groupID].name.join(', ')} <br />
                      </u>
                      <hr />
                    </LinkText>
                  </li>
                ))
              )}
            </ul>
          </div>
        ) : null}

        {Object.keys(curState.searchResults).length === 1 ? (
          <Tabs defaultActiveKey="1">
            <Tab eventKey="1" title="Benchmark A">
              <SldpForm
                alert={(alerts) => this.childFunc.current.addAlert(alerts)}
                userInfo={curState.userInfo}
                form={0}
                groupInfo={curState.searchResults}
              />
            </Tab>
            <Tab eventKey="2" title="Benchmark B">
              <SldpForm
                alert={(alerts) => this.childFunc.current.addAlert(alerts)}
                userInfo={curState.userInfo}
                form={1}
                groupInfo={curState.searchResults}
              />
            </Tab>
            <Tab eventKey="3" title="Commissioning">
              <SldpForm
                alert={(alerts) => this.childFunc.current.addAlert(alerts)}
                userInfo={curState.userInfo}
                form={2}
                groupInfo={curState.searchResults}
              />
            </Tab>
          </Tabs>
        ) : null}

        {Object.keys(curState.searchResults).length === 0 ? (
          <CenterGrayText>No results found...</CenterGrayText>
        ) : null}
      </div>
    );
  }
}
