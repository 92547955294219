import React, { Component } from 'react';
import axios from 'axios';
import { Button, Table, Modal } from 'react-bootstrap';
import propTypes from 'prop-types';
import SubmitReportForm from './submitReportForm';

const colorDict = {
  PENDING: 'goldenrod',
  DENIED: 'crimson',
  APPROVED: 'limegreen',
};

const calculateNumHoursInOneReport = (report) => {
  if (report.id === -1) return -1;
  const timeIn = parseInt(report.timein.slice(3, 5) + report.timein.slice(6, 8), 10);
  const timeOut = parseInt(report.timeout.slice(3, 5) + report.timeout.slice(6, 8), 10);
  const hoursSpent = timeOut - timeIn;
  if (hoursSpent % 100 === 0) {
    return hoursSpent / 100;
  }
  return Math.floor(hoursSpent / 100) + 0.5;
};

const calculateNumApprovedHoursForAllReports = (reports) => {
  let ret = 0;
  reports.forEach((report) => {
    if (report.status === 2) {
      ret += calculateNumHoursInOneReport(report);
    }
  });
  return ret;
};

export default class ViewReportsByUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reports: props.reports,
      isCurViewingReport: false,
      curVisibleReport: props.defaultCurVisibleReport,
      numApprovedHours: props.numApprovedHours, // used to display the number of approved hours at the top of the table
    };
    this.toggleShowReport = this.toggleShowReport.bind(this);
  }

  componentDidMount() {
    const { props } = this;
    axios.get(`/reports/${props.userID}/${props.payPeriodID}`).then((res) => {
      console.log('response', res);
      const numApprovedHours = calculateNumApprovedHoursForAllReports(res.data);
      this.setState({
        reports: res.data,
        numApprovedHours,
      });
    });
  }

  componentDidUpdate(prevProps) {
    const { props } = this;
    if (prevProps.payPeriodID !== props.payPeriodID) {
      axios.get(`/reports/${props.userID}/${props.payPeriodID}`).then((res) => {
        console.log('reports array: ', res.data);
        const numApprovedHours = calculateNumApprovedHoursForAllReports(res.data);
        this.setState({
          reports: res.data,
          numApprovedHours,
        });
      });
    }
  }

  toggleShowReport(report = { id: -1, reporter: -1 }) {
    this.setState((prevState) => ({
      curVisibleReport: { id: report.id, reporter: report.reporter },
      isCurViewingReport: !prevState.isCurViewingReport,
    }));
  }

  render() {
    const curState = this.state;
    console.log('STATE: ', curState);
    const { props } = this;
    const allReports = curState.reports;
    const numOfReports = allReports.length;
    const trs = allReports.map((
      report, // TODO: Come back to this after pagination is fixed
    ) => (
      <tr key={report.id} id={report.id}>
        <td key={`name-${report.id}${report.reporter}`} id={`name-${report.id}${report.reporter}`}>
          {`${report.firstName} ${report.lastName}`}
        </td>
        <td
          key={`worktype-${report.id}${report.workType}`}
          id={`worktype-${report.id}${report.workType}`}
        >
          {report.workType}
        </td>
        <td
          key={`submitDate-${report.id}${report.submitDate}`}
          id={`submitDate-${report.id}${report.submitDate}`}
        >
          {report.submitDate.slice(0, 10)}
        </td>
        <td
          key={`numHoursWorked-${report.id}${report.timein}`}
          id={`numHoursWorked-${report.id}${report.timein}`}
        >
          {calculateNumHoursInOneReport(report)}
        </td>
        <td
          key={`status-${report.id.toString()}`}
          id={`status-${report.id.toString()}`}
          style={
            report.status === 0
              ? { color: colorDict.PENDING }
              : report.status === 1
              ? { color: colorDict.DENIED }
              : { color: colorDict.APPROVED }
          }
        >
          <b>{report.status === 0 ? 'PENDING' : report.status === 1 ? 'DENIED' : 'APPROVED'}</b>
        </td>
        <td key={`manage-${report.id}`} id={`manage-${report.id}`}>
          <Button onClick={() => this.toggleShowReport(report)} variant="primary">
            Manage
          </Button>
        </td>
      </tr>
    ));

    return (
      <div>
        {curState.isCurViewingReport && (
          <Modal
            show={curState.isCurViewingReport}
            onHide={this.toggleShowReport}
            dialogClassName="modal-80w"
            animation
          >
            <Modal.Header closeButton>
              <Modal.Title>Viewing Report</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              {curState.curVisibleReport.reporter === props.userID && (
                <SubmitReportForm isEditing reportID={curState.curVisibleReport.id} />
              )}
              {!(curState.curVisibleReport.reporter === props.userID) && (
                <SubmitReportForm isViewing reportID={curState.curVisibleReport.id} />
              )}
            </Modal.Body>

            <Modal.Footer>
              <Button onClick={() => this.toggleShowReport()} variant="primary">
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}
        <p>
          You have <b>{curState.numApprovedHours}</b> approved hours for this pay period.
        </p>
        {!curState.isCurViewingReport && numOfReports !== 0 && (
          <div>
            <div>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Reporter</th>
                    <th>Work Type</th>
                    <th>Date Submitted</th>
                    <th>Num Hours Worked</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>{trs}</tbody>
              </Table>
            </div>
          </div>
        )}
      </div>
    );
  }
}

ViewReportsByUser.propTypes = {
  payPeriodID: propTypes.string,
  userID: propTypes.string,
  reports: propTypes.arrayOf(
    propTypes.shape({
      body: propTypes.string,
      comment: propTypes.string,
      committeeWork: propTypes.number,
      date: propTypes.string,
      end: propTypes.string,
      expGradingID: propTypes.number,
      expLabID: propTypes.number,
      expRecitationID: propTypes.number,
      firstName: propTypes.string,
      id: propTypes.number,
      lastName: propTypes.string,
      numOfReports: propTypes.number,
      problem: propTypes.string,
      rAndDProj_ID: propTypes.number,
      radprojectID: propTypes.string,
      radprojectText: propTypes.string,
      report_payperiodID: propTypes.number,
      reporter: propTypes.number,
      secID: propTypes.number,
      start: propTypes.string,
      status: propTypes.number,
      submitDate: propTypes.string,
      workType: propTypes.string,
    }),
  ),
  defaultCurVisibleReport: propTypes.shape({
    id: propTypes.number,
    reporter: propTypes.number,
  }),
  numApprovedHours: propTypes.number,
};

ViewReportsByUser.defaultProps = {
  payPeriodID: '-1',
  userID: '-1',
  reports: [
    {
      body: '-1',
      comment: '-1',
      committeeWork: -1,
      date: '-1',
      end: '-1',
      expGradingID: -1,
      expLabID: -1,
      expRecitationID: -1,
      firstName: '-1',
      id: -1,
      lastName: '-1',
      numOfReports: -1,
      problem: '-1',
      rAndDProj_ID: -1,
      radprojectID: '-1',
      radprojectText: '-1',
      report_payperiodID: -1,
      reporter: -1,
      secID: -1,
      start: '-1',
      status: -1,
      submitDate: '-1',
      workType: '-1',
    },
  ],
  defaultCurVisibleReport: { id: -1, reporter: -1 },
  numApprovedHours: -1,
};
