import React, { Component } from 'react';

import { Table, Row, Col, Button, Modal } from 'react-bootstrap';

import propTypes from 'prop-types';

import axios from 'axios';

import './createContractForm.scss';

import SldpKitDropdown from '../convenientDropdowns/sldpKitDropdown';
import ItemDropdown from '../convenientDropdowns/itemDropdown';

export default class CreateContractForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      membersStr: props.membersStr,
      group: props.group, // all information of the current group we're making the contract for
      sldpName: props.sldpName, // name of the current SLDP we're making the contract for
      contractedItems: props.contractedItems, // array of items that are currently being added to the contract
      sldpKitID: props.sldpKitID, // id of the kit the group will be using

      errorModal: props.errorModal, // bool that controls display of error modal
      errorMsg: props.errorModalMsg, // message that's displayed by error modal
    };

    this.submitContract = this.submitContract.bind(this);
    this.updateSldpKit = this.updateSldpKit.bind(this);
    this.validateSubmitContract = this.validateSubmitContract.bind(this);
  }

  componentDidMount() {
    const { props } = this;
    axios.get('/sldp-group/'.concat(props.groupID)).then((res) => {
      axios.get('/sldp/'.concat(props.sldpID)).then((res2) => {
        // make readable group string
        const { members } = res.data[0];
        let membersStr = '';
        for (let i = 0; i < members.length; i += 1) {
          membersStr += `${members[i].firstName} ${members[i].lastName}`;
          if (i < members.length - 1) {
            membersStr += ', ';
          }
        }
        this.setState({
          membersStr,
          group: res.data[0],
          sldpName: res2.data[0].shortName,
        });
      });
    });
  }

  updateContractedItems = (items) => {
    this.setState({
      contractedItems: items,
    });
    console.log('Updated contracted items in contract table component: ', items);
  };

  updateSldpKit(kitID) {
    this.setState({
      sldpKitID: kitID,
    });
  }

  validateSubmitContract(curState) {
    console.log('running validation');
    if (curState.contractedItems.length === 0) {
      this.toggleErrorModal('There are no items in this contract :(');
      return false;
    }
    if (curState.sldpKitID === '-1') {
      this.toggleErrorModal('Please pick an SLDP kit.');
      return false;
    }
    return true;
  }

  submitContract() {
    const { props } = this;
    const curState = this.state;
    if (this.validateSubmitContract(curState)) {
      const submitObj = {
        groupID: props.groupID,
        items: curState.contractedItems,
        sldpKitID: curState.sldpKitID,
      };
      console.log('submitting when the API is up and running: ', submitObj);
    }
  }

  toggleErrorModal(errorMsg) {
    this.setState((prevState) => ({
      errorModal: !prevState.errorModal,
      errorModalMsg: errorMsg,
    }));
  }

  render() {
    const curState = this.state;
    const { props } = this;
    console.log('STATE: ', this.state);
    return (
      <div>
        <div className="newContractHeader">
          <h2>
            New <b>{curState.sldpName}</b> Contract
          </h2>
          <h6>{curState.membersStr}</h6>
        </div>
        <p>
          <b>Locker number:</b> TODO: Need to add auto-assigning locker numbers
        </p>
        <p>
          <b>Kit number:</b>
        </p>
        <SldpKitDropdown sldpID={props.sldpID} availableOnly parentCallback={this.updateSldpKit} />
        <ContractedItems parentCallback={this.updateContractedItems} sldpID={props.sldpID} />
        <Button onClick={this.submitContract} variant="primary">
          Submit
        </Button>
        <Modal
          show={curState.errorModal}
          onHide={() => {
            this.toggleErrorModal(props.errorModalMsg);
          }}
          animation
        >
          <Modal.Header closeButton>
            <Modal.Title>Whoops</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>{curState.errorModalMsg}</p>
          </Modal.Body>

          <Modal.Footer>
            <Button
              onClick={() => {
                this.toggleErrorModal(props.errorModalMsg);
              }}
              variant="secondary"
            >
              Okay
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

class ContractedItems extends Component {
  // This component shows all the items that are contracted by the group.
  // By default, it contains all the items in the default kit of the given SLDP

  constructor(props) {
    super(props);

    this.state = {
      contractedItems: props.contractedItems, // all items being given to the group. Defaulted with SLDP items.

      addingItemID: props.addingItemID, // id of item that is (potentially) being added to the group
      addingItemAmount: props.addingItemAmount, // amount of the item ^

      errorModal: props.errorModal, // bool that is true whenever a user makes a mistake when adding an item to the contract
      errorModalMsg: props.errorModalMsg, // the message that shows up in the error
    };
    this.toggleErrorModal = this.toggleErrorModal.bind(this);
    this.validateAddItemToContract = this.validateAddItemToContract.bind(this);
    this.removeItemFromContract = this.removeItemFromContract.bind(this);
  }

  componentDidMount() {
    const { props } = this;
    axios.get(`/inventory-kits/${props.sldpID}`).then((res) => {
      this.setState({
        contractedItems: res.data,
      });
      props.parentCallback(res.data);
    });
  }

  updateAddingItemID = (itemID) => {
    this.setState({
      addingItemID: itemID,
    });
    console.log('Want to add item with ID: ', itemID);
  };

  // This function updates the amount of the item that we're adding to the contract.
  updateAddingItemAmount = (e) => {
    this.setState({
      addingItemAmount: e.target.value,
    });
  };

  // This function updates the amount of an item with ID itemID that's already in the contract.
  updateItemAmount = (itemID, amount) => {
    const curState = this.state;
    const { props } = this;
    const items = curState.contractedItems;
    for (let i = 0; i < items.length; i += 1) {
      const curItem = items[i];
      if (curItem.itemID === itemID) {
        curItem.amount = parseInt(amount, 10);
        items[i] = curItem;
      }
    }
    this.setState({
      contractedItems: items,
    });
    props.parentCallback(items);
  };

  addItemToContract = () => {
    if (this.validateAddItemToContract()) {
      const curState = this.state;
      const { props } = this;
      const items = curState.contractedItems;
      let item;
      axios.get(`/inventory-item/${curState.addingItemID}`).then((res) => {
        item = {
          cost: res.data[0].cost,
          itemName: res.data[0].itemName,
          itemID: res.data[0].itemID,
          unit: res.data[0].unit,
          amount: Math.trunc(curState.addingItemAmount),
        };
        items.push(item);
        this.setState({
          contractedItems: items,
        });
        props.parentCallback(items);
      });
    }
  };

  removeItemFromContract(itemID) {
    this.setState(
      (prevState) => ({
        contractedItems: prevState.contractedItems.filter((item) => item.itemID !== itemID),
      }),
      () => {
        // this is a callback function that only runs after the state is set asynchronously.
        const curState = this.state;
        const { props } = this;
        props.parentCallback(curState.contractedItems);
      },
    );
  }

  validateAddItemToContract() {
    const curState = this.state;
    const items = curState.contractedItems;

    // first check that the item isn't already in the contract
    for (let i = 0; i < items.length; i += 1) {
      console.log('items i: ', items[i]);
      if (items[i].itemID === parseInt(curState.addingItemID, 10)) {
        this.toggleErrorModal(
          `This contract already has at least one ${items[i].itemName}. If you want to add more, try updating the amount instead.`,
        );
        return false;
      }
    }

    // make sure an actual item was selected
    if (curState.addingItemID === '-1') {
      this.toggleErrorModal(
        'You forgot to select an item name. Please fix this error and try again.',
      );
      return false;
    }

    // make sure a positive non-zero value was given
    if (curState.addingItemAmount < 1) {
      this.toggleErrorModal('Please enter a positive, non-zero integer for the item amount.');
      return false;
    }

    // make sure a whole number was given
    if (curState.addingItemAmount % 1 !== 0) {
      this.toggleErrorModal('Please enter a whole-number amount.');
      return false;
    }

    return true;
  }

  toggleErrorModal(errorMsg) {
    this.setState((prevState) => ({
      errorModal: !prevState.errorModal,
      errorModalMsg: errorMsg,
    }));
  }

  render() {
    const curState = this.state;
    const { props } = this;
    const trs = curState.contractedItems.map((item) => {
      const { itemID } = item;
      const { itemName } = item;
      const { amount } = item;
      const itemCost = item.cost;
      const itemUnit = item.unit;
      return (
        <tr key={`contractedItems-tr-${itemID}`}>
          <td key={`contractedItems-name-${itemID}`}>{itemName}</td>
          <td key={`contractedItems-amount-${itemID}`}>
            <input
              key={`input-amount-${itemID}`}
              defaultValue={amount}
              onBlur={(e) => this.updateItemAmount(itemID, e.target.value)}
              type="number"
              min="1"
              step="1"
            />
          </td>
          <td key={`contractedItems-unit-${itemID}`}>{itemUnit || 'n/a'}</td>
          <td key={`contractedItems-totalCost-${itemID}`}>{+(itemCost * amount).toFixed(2)}</td>
          <td>
            <Button variant="danger" onClick={() => this.removeItemFromContract(itemID)}>
              Remove
            </Button>
          </td>
        </tr>
      );
    });
    return (
      <div>
        <h4>Items</h4>
        <p>
          Below you&apos;ll find a table filled with the default items that can be found in the kit
          for your selected SLDP. Feel free to add more items based on the group&apos;s needs.
        </p>
        <Table responsive striped bordered hover>
          <thead>
            <tr>
              <th>Item Name</th>
              <th>Amount in Kit (must be int)</th>
              <th>Unit</th>
              <th>Total Cost</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>{trs}</tbody>
        </Table>
        <Row>
          <Col xs={2}>
            <p>
              <b>Add item:</b>
            </p>
          </Col>
          <Col xs={3}>
            <ItemDropdown
              parentCallback={this.updateAddingItemID}
              default={curState.addingItemID}
            />
          </Col>
          <Col xs={2}>
            <p>
              <b>Amount:</b>
            </p>
          </Col>
          <Col xs={3}>
            <input
              onChange={this.updateAddingItemAmount}
              value={curState.addingItemAmount}
              type="number"
            />
          </Col>
          <Col xs={2}>
            <Button onClick={this.addItemToContract} variant="secondary">
              Add to Contract
            </Button>
          </Col>
        </Row>
        <Modal
          show={curState.errorModal}
          onHide={() => {
            this.toggleErrorModal(props.errorModalMsg);
          }}
          animation
        >
          <Modal.Header closeButton>
            <Modal.Title>Whoops</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>{curState.errorModalMsg}</p>
          </Modal.Body>

          <Modal.Footer>
            <Button
              onClick={() => {
                this.toggleErrorModal(props.errorModalMsg);
              }}
              variant="secondary"
            >
              Okay
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

CreateContractForm.propTypes = {
  group: propTypes.shape({
    group_id: propTypes.number,
    secName: propTypes.string,
    projectType: propTypes.string,
    contractIndex: propTypes.number,
    numPartners: propTypes.number,
    benchmarkA: propTypes.number,
    benchmarkB: propTypes.number,
    commissioned: propTypes.string,
    benchA_date: propTypes.string,
    benchB_date: propTypes.string,
    comm_date: propTypes.string,
    past_semester: propTypes.number,
    radMentorID: propTypes.number,
    radProjectTitle: propTypes.string,
    members: propTypes.arrayOf(
      propTypes.shape({
        userID: propTypes.number,
        firstName: propTypes.string,
        lastName: propTypes.string,
      }),
    ),
  }),
  sldpName: propTypes.string,
  groupID: propTypes.string,
  sldpID: propTypes.string,
  contractedItems: propTypes.arrayOf(
    propTypes.shape({
      amount: propTypes.number,
      cost: propTypes.number,
      id: propTypes.number,
      itemID: propTypes.number,
      itemName: propTypes.string,
      sldpID: propTypes.number,
      unit: propTypes.string,
    }),
  ),
  membersStr: propTypes.string,
  sldpKitID: propTypes.string,

  errorModal: propTypes.bool,
  errorModalMsg: propTypes.string,
};

CreateContractForm.defaultProps = {
  group: {
    group_id: -1,
    secName: '-1',
    projectType: '-1',
    contractIndex: -1,
    numPartners: -1,
    benchmarkA: -1,
    benchmarkB: -1,
    commissioned: '-1',
    benchA_date: '-1',
    benchB_date: '-1',
    comm_date: '-1',
    past_semester: -1,
    radMentorID: -1,
    radProjectTitle: '-1',
    members: [
      {
        userID: -1,
        firstName: '-1',
        lastName: '-1',
      },
    ],
  },
  sldpName: '-1',
  groupID: '-1',
  sldpID: '-1',
  contractedItems: [
    {
      amount: -1,
      cost: -1,
      id: -1,
      itemID: -1,
      itemName: '-1',
      sldpID: -1,
      unit: '-1',
    },
  ],
  membersStr: '-1',
  sldpKitID: '-1',

  errorModal: false,
  errorModalMsg: '-1',
};

ContractedItems.propTypes = {
  contractedItems: propTypes.arrayOf(
    propTypes.shape({
      amount: propTypes.number,
      cost: propTypes.number,
      id: propTypes.number,
      itemID: propTypes.number,
      itemName: propTypes.string,
      sldpID: propTypes.number,
      unit: propTypes.string,
    }),
  ),
  sldpID: propTypes.string,
  addingItemID: propTypes.string,
  addingItemAmount: propTypes.number,
  parentCallback: propTypes.func,

  errorModal: propTypes.bool,
  errorModalMsg: propTypes.string,
};

ContractedItems.defaultProps = {
  contractedItems: [
    {
      amount: -1,
      cost: -1,
      id: -1,
      itemID: -1,
      itemName: '-1',
      sldpID: -1,
      unit: '-1',
    },
  ],
  sldpID: '-1',
  addingItemID: '-1',
  addingItemAmount: 1,
  parentCallback: () => {
    console.log('Missing callback in ContractedItems component.');
  },

  errorModal: false,
  errorModalMsg: '-1',
};
