import React, { Component } from 'react';
import MakeupLabRequest from '../../components/makeupLabs/makeupLabRequest';
import ViewMakeupLabRequests from '../../components/makeupLabs/viewMakeupLabRequests';
import ViewMakeupLabsPending from '../../components/makeupLabs/viewMakeupLabsPending';
import ViewMakeupLabsCompleted from '../../components/makeupLabs/viewMakeupLabsCompleted';

export default class MakeupLabs extends Component {
  render() {
    return (
      <div>
        <h3 className="display-6 mb-3">Request a Makeup Lab</h3>
        <MakeupLabRequest />
        <hr />
        <h3 className="display-6">Makeup Labs to Complete</h3>
        <ViewMakeupLabsPending status="OPEN" />
        <hr />
        <h3 className="display-6">Makeup Labs Pending Requests</h3>
        <ViewMakeupLabRequests status="OPEN" />
        <hr />
        <h3 className="display-6">Makeup Labs Completed</h3>
        <ViewMakeupLabsCompleted status="OPEN" />
      </div>
    );
  }
}
