import { Component } from 'react';
import axios from 'axios';

export default class UserPermissionsComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userInfo: {},
    };
  }

  componentDidMount() {
    axios.get('/getUserInfo/').then((res) => {
      console.log('response', res);
      this.setState({
        userInfo: res.data ? res.data : {},
      });
    });
  }

  //   isAdmin() {
  //     const { userInfo } = this.state;
  //     return false;
  //   }

  isLoggedIn = () => {
    const { userInfo } = this.state;
    return userInfo;
  };

  isTA = () => {
    if (this.isLoggedIn()) {
      const { userInfo } = this.state;
      return userInfo.groupID === 2;
    }
    return false;
  };

  isProf = () => {
    if (this.isLoggedIn()) {
      const { userInfo } = this.state;
      return userInfo.groupID === 3;
    }
    return false;
  };

  isWC = () => {
    if (this.isLoggedIn()) {
      const { userInfo } = this.state;
      return userInfo.groupID === 4;
    }
    return false;
  };

  isStudent = () => {
    if (this.isLoggedIn()) {
      const { userInfo } = this.state;
      return userInfo.groupID === 5;
    }
    return false;
  };

  // Committee Permissions

  isHeadTA = () => {
    if (this.isLoggedIn()) {
      console.log('FROM *USER PERMISSIONS COMPONENT');
      const { userInfo } = this.state;
      return userInfo.committeeID === 1;
    }
    return false;
  };

  isWebDevComm = () => {
    if (this.isLoggedIn()) {
      const { userInfo } = this.state;
      return userInfo.committeeID === 2;
    }
    return false;
  };

  isRnDComm = () => {
    if (this.isLoggedIn()) {
      const { userInfo } = this.state;
      return userInfo.committeeID === 3;
    }
    return false;
  };

  isGradingComm = () => {
    if (this.isLoggedIn()) {
      const { userInfo } = this.state;
      return userInfo.committeeID === 4;
    }
    return false;
  };

  isPrototypingComm = () => {
    if (this.isLoggedIn()) {
      const { userInfo } = this.state;
      return userInfo.committeeID === 5;
    }
    return false;
  };

  isNetworkingComm = () => {
    if (this.isLoggedIn()) {
      const { userInfo } = this.state;
      return userInfo.committeeID === 6;
    }
    return false;
  };

  isPurchasingComm = () => {
    if (this.isLoggedIn()) {
      const { userInfo } = this.state;
      return userInfo.committeeID === 7;
    }
    return false;
  };

  isInventoryComm = () => {
    if (this.isLoggedIn()) {
      const { userInfo } = this.state;
      return userInfo.committeeID === 8;
    }
    return false;
  };

  isCommunicationsComm = () => {
    if (this.isLoggedIn()) {
      const { userInfo } = this.state;
      return userInfo.committeeID === 9;
    }
    return false;
  };

  isIDBEComm = () => {
    if (this.isLoggedIn()) {
      const { userInfo } = this.state;
      return userInfo.committeeID === 10;
    }
    return false;
  };
}
