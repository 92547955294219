import React, { Component } from 'react';
import { Button, Form, Col, Row, Table, Modal, Card, Spinner } from 'react-bootstrap';
import propTypes from 'prop-types';
import axios from 'axios';

import './submitReportForm.scss';

import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import datePickerToUtcISO from '../../../utils/datePickerToUtcISO';

import UsersDropdown from '../convenientDropdowns/usersDropdown';
import SLDPGroupDropdown from '../convenientDropdowns/sldpGroupDropdown';
import SectionDropdown from '../convenientDropdowns/sectionsDropdown';
import WorktypeDropdown from '../convenientDropdowns/worktypeDropdown';
import LabsDropdown from '../convenientDropdowns/labsDropdown';
import SubmissionsDropdown from '../convenientDropdowns/submissionsDropdown';
import RnDProjectsDropdown from '../convenientDropdowns/rndProjectsDropdown';
import RecPresentationsDropdown from '../convenientDropdowns/recPresentationsDropdown';
import TimeSelector from '../convenientDropdowns/timeSelector';
import DateSelector from '../dateSelector/dateSelector';

const config = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
};
const qs = require('querystring-es3');

export default class SubmitReportForm extends Component {
  constructor(props) {
    super(props);

    const currentTaSelections = {};
    if (!(props.isViewing || props.isEditing)) {
      // this only is meant to increase the number of TA dropdowns when writing a report if necessary
      for (let i = 0; i < props.numOfTas; i += 1) {
        currentTaSelections[i] = {
          userID: i === 0 ? props.userID : '-1',
          timeIn: '-1',
          timeOut: '-1',
        };
      }
    }

    this.state = {
      curRowID: props.numOfTas, // rowID we will give to any newly added rows; ensures all rows have unique IDs
      currentTaSelections, // current selections for each TA on the report

      showSubmitModal: props.showSubmitModal, // warning that pops up when user submits a report
      showIncompleteFormModal: props.showIncompleteFormModal, // warning that a user tried to submit an incomplete form
      incompleteFormModalMessage: props.incompleteFormModalMessage,

      curWorktype: props.curWorktype, // current selected worktype of the report
      curDate: props.curDate, // used for all report submission
      curSection: props.curSection, // used for some worktypes
      curLab: props.curLab, // used for lab
      isCommitteeWork: props.isCommitteeWork, // used for grading
      curSubmission: props.submissionInfo, // used for grading
      curRnDProject: props.curRnDProject, // used for R&D
      curRecPresentationInfo: props.curRecPresentationInfo, // used for recitation
      curRadGroup: props.curRadGroup, // used for RAD
      curRadProjectName: props.curRadProjectName, // used for RAD
      curStatus: props.curStatus, // approved/pending/denied

      defaultBodyText: props.defaultBodyText, // only used when editing a report
      defaultProblemText: props.defaultProblemText, // only used when editing a report
      defaultAdminText: props.defaultAdminText, // only used when editing a report

      loadSpinner: props.loadSpinner, // used to display spinner upon report submission when waiting for API return
    };
    this.addTaRow = this.addTaRow.bind(this);
    this.removeTaRow = this.removeTaRow.bind(this);
    this.submitReport = this.submitReport.bind(this);
    this.toggleSubmitModal = this.toggleSubmitModal.bind(this);
    this.toggleIncompleteFormModal = this.toggleIncompleteFormModal.bind(this);
    this.updateStatus = this.updateStatus.bind(this);
    this.setAllSameTimeAs = this.setAllSameTimeAs.bind(this);
    this.removeAllExtraTaRows = this.removeAllExtraTaRows.bind(this);
  }

  componentDidMount() {
    const { props } = this;
    const taSelections = {};
    if (props.isEditing || props.isViewing) {
      axios.get(`/reports/${props.reportID}`).then((res) => {
        let i;
        for (i = 0; i < Object.keys(res.data).length; i += 1) {
          const { userID } = res.data[i];
          const { timein } = res.data[i];
          const { timeout } = res.data[i];
          taSelections[i] = {
            userID,
            timeIn: timein.slice(3, 5) + timein.slice(6, 8),
            timeOut: timeout.slice(3, 5) + timeout.slice(6, 8),
          };
        }
        console.log('date in component did mount', res.data[0].date);

        this.setState({
          curWorktype: res.data[0].workType.toString(),
          curDate: res.data[0].date,
          currentTaSelections: taSelections,
          curSection: res.data[0].secID.toString(),
          curLab: res.data[0].expLabID.toString(),
          isCommitteeWork: res.data[0].committeeWork.toString(),
          curSubmission: res.data[0].expGradingID.toString(),
          curRnDProject: res.data[0].rAndDProj_ID.toString(),
          curRecPresentationInfo: res.data[0].expRecitationID.toString(),
          curRadGroup: res.data[0].radprojectID.toString(),
          curRadProjectName: res.data[0].radprojectText.toString(),
          curStatus: res.data[0].status,

          defaultBodyText: res.data[0].body ? res.data[0].body : '-1', // need the ternary to check for null values from the DB
          defaultProblemText: res.data[0].problem ? res.data[0].problem : '-1', // need the ternary to check for null values from the DB
          defaultAdminText: res.data[0].comment ? res.data[0].comment : '-1', // need the ternary to check for null values from the DB
        });
      });
    }
    axios.get(`/userGroup/2`).then((res) => {
      this.setState({
        usersDropdownData: res.data,
      });
    });
  }

  setAllSameTimeAs(rowID) {
    const { currentTaSelections } = this.state;
    const { timeIn, timeOut } = currentTaSelections[rowID];

    Object.keys(currentTaSelections).forEach((key) => {
      currentTaSelections[key].timeIn = timeIn;
      currentTaSelections[key].timeOut = timeOut;
    });

    this.setState({
      currentTaSelections,
    });
  }

  getTaSelectInfo = (childData, rowId) => {
    // console.log("TA SELECT INFO: ", childData)
    // console.log("ROW ID: ", rowId)
    const curState = this.state;

    const taSelections = { ...curState.currentTaSelections };

    if (!(rowId in taSelections)) taSelections[rowId] = {};
    taSelections[rowId].userID = childData;

    this.setState({
      currentTaSelections: taSelections,
    });
  };

  getTimeInSelectInfo = (childData, rowId) => {
    // console.log("TIME IN SELECT INFO: ", childData)
    // console.log("ROW ID: ", rowId)
    const curState = this.state;

    const taSelections = { ...curState.currentTaSelections };

    if (!(rowId in taSelections)) taSelections[rowId] = {};
    taSelections[rowId].timeIn = childData;

    this.setState({
      currentTaSelections: taSelections,
    });
  };

  getTimeOutSelectInfo = (childData, rowId) => {
    // console.log("TIME OUT SELECT INFO", childData)
    // console.log("ROW ID: ", rowId)
    const curState = this.state;

    const taSelections = { ...curState.currentTaSelections };

    if (!(rowId in taSelections)) taSelections[rowId] = {};
    taSelections[rowId].timeOut = childData;

    this.setState({
      currentTaSelections: taSelections,
    });
  };

  getWorktypeInfo = (childData) => {
    this.setState({
      curWorktype: childData,
    });
  };

  getSectionInfo = (childData) => {
    this.setState({
      curSection: childData,
    });
    console.log('Updated section to: ', childData);
  };

  getLabInfo = (childData) => {
    this.setState({
      curLab: childData,
    });
    console.log('Updated lab to: ', childData);
  };

  getSubmissionInfo = (childData) => {
    this.setState({
      curSubmission: childData,
    });
    console.log('Updated submission to: ', childData);
  };

  getRnDProjectInfo = (childData) => {
    this.setState({
      curRnDProject: childData,
    });
    console.log('Updated R&D project to: ', childData);
  };

  getRadGroupInfo = (childData) => {
    this.setState({
      curRadGroup: childData,
    });
    console.log('Updated RAD group to: ', childData);
  };

  getRecPresentationInfo = (childData) => {
    this.setState({
      curRecPresentationInfo: childData,
    });
    console.log('Updated rec presentation to: ', childData);
  };

  updateDate = (date) => {
    this.setState({
      curDate: date,
    });
    console.log('Updated date to: ', date);
  };

  updateIsCommitteeWork = () => {
    this.setState((prevState) => ({
      isCommitteeWork: !prevState.isCommitteeWork,
    }));
  };

  updateCurRadProjectName(e) {
    this.setState({
      curRadProjectName: e.target.value,
    });
    console.log('Rad proj name: ', e.target.value);
  }

  updateStatus(status) {
    this.setState({
      curStatus: status,
    });
    console.log('Current status: ', status);
  }

  toggleSubmitModal() {
    this.setState((prevState) => ({
      showSubmitModal: !prevState.showSubmitModal,
    }));
  }

  toggleIncompleteFormModal(message) {
    this.setState((prevState) => ({
      showIncompleteFormModal: !prevState.showIncompleteFormModal,
      incompleteFormModalMessage: message,
    }));
  }

  addTaRow() {
    const { curRowID } = this.state;
    const { currentTaSelections } = this.state;
    currentTaSelections[curRowID] = {
      userID: '-1',
      timeIn: '-1',
      timeOut: '-1',
    };

    this.setState((prevState) => ({
      currentTaSelections,
      curRowID: prevState.curRowID + 1,
    }));
  }

  removeTaRow(rowID) {
    const { currentTaSelections, curRowID } = this.state;
    delete currentTaSelections[rowID];

    if (Object.keys(currentTaSelections).length === 0) {
      currentTaSelections[curRowID] = {
        userID: '-1',
        timeIn: '-1',
        timeOut: '-1',
      };
      this.setState((prevState) => ({
        currentTaSelections,
        curRowID: prevState.curRowID + 1,
      }));
    } else {
      this.setState({
        currentTaSelections,
      });
    }
  }

  removeAllExtraTaRows() {
    const { currentTaSelections } = this.state;
    const newTaSelections = currentTaSelections;
    Object.keys(currentTaSelections).forEach((key) => {
      const curRow = currentTaSelections[key];
      if (curRow.userID === '-1') delete newTaSelections[key];
    });

    this.setState({
      currentTaSelections: newTaSelections,
    });
  }

  submitReport() {
    const curState = this.state;
    const { props } = this;
    const whatWasDoneBody = curState.whatWasDoneEditor.getData();
    const problemsEncounteredBody = curState.problemsEncounteredEditor.getData();
    let adminCommentBody = '-1';
    if (curState.adminCommentEditor) {
      adminCommentBody = curState.adminCommentEditor.getData();
    }
    let taSelections = curState.currentTaSelections;
    const worktype = parseInt(curState.curWorktype, 10);
    const date = datePickerToUtcISO(curState.curDate);
    const section = parseInt(curState.curSection, 10);
    const lab = parseInt(curState.curLab, 10);
    const isCommitteeWork = parseInt(curState.isCommitteeWork, 10) ? 1 : 0;
    const submission = parseInt(curState.curSubmission, 10);
    const rndProj = parseInt(curState.curRnDProject, 10);
    const recPres = parseInt(curState.curRecPresentationInfo, 10);
    const radGroup = parseInt(curState.curRadGroup, 10);
    const radProjectName = curState.curRadProjectName;
    const status = curState.curStatus;

    // a bunch of form validation:

    // validate that all tas on the report have their names, timeIn, and timeOut on the report
    let taSelectionsValid = true;
    Object.keys(taSelections).forEach((key) => {
      const curRow = taSelections[key];
      if (curRow.userID === '-1' || curRow.timeIn === '-1' || curRow.timeOut === '-1') {
        // user forgot to give info for all TAs on the report
        taSelectionsValid = false;
      }
    });
    if (!taSelectionsValid) {
      this.toggleIncompleteFormModal(
        'Missing information for one of the TAs on the report. Make sure you have all TAs selected with their time in and time out',
      );
      return;
    }

    if (whatWasDoneBody === '') {
      this.toggleIncompleteFormModal(
        'Make sure you give a description of what you did on the report.',
      );
      return;
    }

    // worktype validations

    if (!worktype || worktype === -1) {
      // user forgot to give a worktype
      this.toggleIncompleteFormModal('Forgot to enter worktype!');
      return;
    }

    if (worktype === 2) {
      // grading
      if (isCommitteeWork === 0) {
        if (section === -1 || submission === -1) {
          this.toggleIncompleteFormModal('Make sure you include the section and submission info!');
          return;
        }
      }
    }

    if (worktype === 17) {
      // lab training
      if (lab === -1) {
        this.toggleIncompleteFormModal('Make sure you include the lab!');
        return;
      }
    }

    if (worktype === 4) {
      // lab
      if (section === -1 || lab === -1) {
        this.toggleIncompleteFormModal('Make sure you include the section and lab info!');
        return;
      }
    }

    if (worktype === 11) {
      // R&D
      if (rndProj === -1) {
        this.toggleIncompleteFormModal(
          'Make sure you include the project info! Select N/A if none apply.',
        );
        return;
      }
    }

    if (worktype === 23) {
      // RAD
      if (section === -1 || radGroup === -1) {
        this.toggleIncompleteFormModal('Make sure you include the RAD group and the section.');
        return;
      }
    }

    if (worktype === 12) {
      // Recitation
      if (section === -1 || recPres === -1) {
        this.toggleIncompleteFormModal(
          'Make sure you include the section and recitation presentation.',
        );
        return;
      }
    }

    taSelections = JSON.stringify(taSelections); // needed for posting
    if (props.isEditing) {
      this.setState({ loadSpinner: true });
      axios
        .put(
          `/reports/${props.reportID}`,
          qs.stringify({
            workDone: whatWasDoneBody,
            probsEnc: problemsEncounteredBody,
            taTimeRanges: taSelections,
            worktype,
            date,
            section,
            lab,
            recPres,
            isCommitteeWork,
            submission,
            rndProj,
            reporter: parseInt(props.userID, 10), // TODO: Update to real reporter (currently points at Dan)
            radprojectID: radGroup,
            radprojectText: radProjectName,
            status,
            comment: adminCommentBody,
          }),
          config,
        )
        .then((response) => {
          this.setState({ loadSpinner: false });
          props.parentCallback(true);
          console.log(response);
          console.log('We definitely should be getting an update');
        })
        .catch((err) => {
          this.setState({ loadSpinner: false });

          props.parentCallback(false);
          console.log(err);
        });
    } else {
      this.setState({ loadSpinner: true });
      axios
        .post(
          '/reports/',
          qs.stringify({
            workDone: whatWasDoneBody,
            probsEnc: problemsEncounteredBody,
            taTimeRanges: taSelections,
            worktype,
            date,
            section,
            lab,
            recPres,
            isCommitteeWork,
            submission,
            rndProj,
            reporter: parseInt(props.userID, 10), // TODO: Update to real reporter (currently points at Dan)
            status: 0,
            radprojectID: radGroup,
            radprojectText: radProjectName,
          }),
          config,
        )
        .then((response) => {
          this.setState({ loadSpinner: false });
          props.parentCallback(true);
          console.log(response);
        })
        .catch((err) => {
          this.setState({ loadSpinner: false });
          props.parentCallback(false);
          console.log(err);
        });
    }
  }

  render() {
    const curState = this.state;
    const { props } = this;

    const { currentTaSelections } = curState;

    console.log('cur ta selections: ', currentTaSelections);

    const taRows = Object.keys(currentTaSelections).map((rowID) => {
      console.log('rendering rowID: ', rowID);
      return (
        <Row key={rowID} id={rowID}>
          <Col xs={3}>
            <UsersDropdown
              rowId={rowID}
              default={currentTaSelections[rowID].userID.toString()}
              parentCallback={this.getTaSelectInfo}
              users={curState.usersDropdownData}
              isDisabled={props.isViewing}
            />
          </Col>
          <Col xs={2}>
            <TimeSelector
              rowId={rowID}
              default={currentTaSelections[rowID].timeIn}
              parentCallback={this.getTimeInSelectInfo}
              label="Time In"
              isDisabled={props.isViewing}
            />
          </Col>
          <Col xs={2}>
            <TimeSelector
              rowId={rowID}
              default={currentTaSelections[rowID].timeOut}
              parentCallback={this.getTimeOutSelectInfo}
              label="Time Out"
              isDisabled={props.isViewing}
            />
          </Col>
          {!props.isViewing && (
            <Col>
              <Button variant="outline-secondary" onClick={() => this.setAllSameTimeAs(rowID)}>
                Set all same time
              </Button>
              <Button variant="outline-danger" onClick={() => this.removeTaRow(rowID)}>
                Remove
              </Button>
            </Col>
          )}
        </Row>
      );
    });

    const worktypeExtras = [];
    if (curState.curWorktype === '4') {
      // Lab
      worktypeExtras.push(
        <tr>
          <td className="table-label">Section</td>
          <td>
            <SectionDropdown default={curState.curSection} parentCallback={this.getSectionInfo} />
          </td>
        </tr>,
      );
      worktypeExtras.push(
        <tr>
          <td className="table-label">Lab</td>
          <td>
            <LabsDropdown default={curState.curLab} parentCallback={this.getLabInfo} />
          </td>
        </tr>,
      );
    } else if (curState.curWorktype === '2') {
      // Grading
      worktypeExtras.push(
        <tr>
          <td className="table-label">Committee Work?</td>
          <td>
            <Form.Check
              defaultValue={curState.isCommitteeWork}
              onChange={this.updateIsCommitteeWork}
              id="is-grading-committee work"
            />
          </td>
        </tr>,
      );
      if (!curState.isCommitteeWork) {
        worktypeExtras.push(
          <tr>
            <td className="table-label">Section</td>
            <td>
              <SectionDropdown default={curState.curSection} parentCallback={this.getSectionInfo} />
            </td>
          </tr>,
        );
        worktypeExtras.push(
          <tr>
            <td className="table-label">Graded Item</td>
            <td>
              <SubmissionsDropdown
                default={curState.curSubmission}
                parentCallback={this.getSubmissionInfo}
              />
            </td>
          </tr>,
        );
      }
    } else if (curState.curWorktype === '17') {
      // Lab training
      worktypeExtras.push(
        <tr>
          <td className="table-label">Lab</td>
          <td>
            <LabsDropdown default={curState.curLab} parentCallback={this.getLabInfo} />
          </td>
        </tr>,
      );
    } else if (curState.curWorktype === '11') {
      // R&D
      worktypeExtras.push(
        <tr>
          <td className="table-label">Project</td>
          <td>
            <RnDProjectsDropdown
              default={curState.curRnDProject}
              parentCallback={this.getRnDProjectInfo}
            />
          </td>
        </tr>,
      );
    } else if (curState.curWorktype === '23') {
      // RAD
      worktypeExtras.push(
        <tr>
          <td className="table-label">Section</td>
          <td>
            <SectionDropdown default={curState.curSection} parentCallback={this.getSectionInfo} />
          </td>
        </tr>,
      );
      worktypeExtras.push(
        <tr>
          <td className="table-label">RAD Group</td>
          <td>
            <SLDPGroupDropdown
              projectType="1"
              default={curState.curRadGroup}
              parentCallback={this.getRadGroupInfo}
            />
          </td>
        </tr>,
      );
      worktypeExtras.push(
        <tr>
          <td className="table-label">Project Name</td>
          <td>
            <Form.Control
              placeholder={curState.curRadProjectName}
              onChange={this.updateRadProjectName}
            />
          </td>
        </tr>,
      );
    } else if (curState.curWorktype === '12') {
      // Recitation
      worktypeExtras.push(
        <tr>
          <td className="table-label">Section</td>
          <td>
            <SectionDropdown default={curState.curSection} parentCallback={this.getSectionInfo} />
          </td>
        </tr>,
      );
      worktypeExtras.push(
        <tr>
          <td className="table-label">Presentation</td>
          <td>
            <RecPresentationsDropdown
              default={curState.curRecPresentationInfo}
              parentCallback={this.getRecPresentationInfo}
            />
          </td>
        </tr>,
      );
    } else if (curState.curWorktype === '18') {
      worktypeExtras.push(
        <tr>
          <td className="table-label">Presentation</td>
          <td>
            <RecPresentationsDropdown
              default={curState.curRecPresentationInfo}
              parentCallback={this.getRecPresentationInfo}
            />
          </td>
        </tr>,
      );
    }
    return (
      <div>
        <Modal show={curState.showSubmitModal} onHide={this.toggleSubmitModal} animation>
          <Modal.Header closeButton>
            <Modal.Title>Are you sure you want to submit?</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>Make sure all the information you entered is correct before proceeding.</p>
            {props.isEditing && !props.isAdmin && (
              <p>
                <b>Note: </b>updating this report will set its status back to pending until a head
                TA approves/denies it.
              </p>
            )}
          </Modal.Body>

          <Modal.Footer>
            <Button onClick={this.toggleSubmitModal} variant="primary">
              Keep Writing
            </Button>
            <Button
              onClick={() => {
                this.submitReport();
                this.toggleSubmitModal();
              }}
              variant="secondary"
            >
              Submit{' '}
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={curState.showIncompleteFormModal}
          onHide={() => this.toggleIncompleteFormModal('Contact webdev')}
          animation
        >
          <Modal.Header closeButton>
            <Modal.Title>Form is incomplete!</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>Make sure you entered all the necessary information before pressing submit.</p>
            <p>
              <b>Error: </b>
              {curState.incompleteFormModalMessage}
            </p>
          </Modal.Body>

          <Modal.Footer>
            <Button
              onClick={() => this.toggleIncompleteFormModal('Contact webdev')}
              variant="primary"
            >
              Okay
            </Button>
          </Modal.Footer>
        </Modal>
        {props.isViewing && (
          <p>
            <b>Note: </b>you are not the author of this report. If you would like to edit something
            in this report, please contact the TA who submitted the report.
          </p>
        )}
        <Form>
          <Form.Label className="report-section-title">TAs on Report</Form.Label>
          {taRows}
          {!props.isViewing && (
            <Row>
              <Col>
                <Button variant="outline-primary" onClick={this.addTaRow}>
                  Add TA
                </Button>
                <Button variant="outline-danger" onClick={this.removeAllExtraTaRows}>
                  Remove extra
                </Button>
              </Col>
            </Row>
          )}
          <hr />
          <Form.Label className="report-section-title">What was Done</Form.Label>
          <Table bordered hover>
            <tbody>
              <tr>
                <td className="table-label">Worktype</td>
                <td>
                  <WorktypeDropdown
                    default={curState.curWorktype}
                    parentCallback={this.getWorktypeInfo}
                    isDisabled={props.isViewing}
                  />
                  {console.log('WORKTYPE: ', curState.curWorktype)}
                </td>
              </tr>
              <tr>
                <td className="table-label">Date of Work</td>
                <td>
                  <DateSelector
                    default={
                      props.isViewing || props.isEditing
                        ? new Date(curState.curDate)
                        : props.defaultDate
                    }
                    format="yyyy-MM-dd"
                    parentCallback={this.updateDate}
                    isDisabled={props.isViewing}
                  />
                </td>
              </tr>
              {worktypeExtras}
            </tbody>
          </Table>
          <CKEditor
            editor={ClassicEditor} // TODO: Adjust initial height so it looks better
            data={curState.defaultBodyText === '-1' ? '' : curState.defaultBodyText}
            disabled={props.isViewing}
            onInit={(editor) => {
              // You can store the "editor" and use when it is needed.
              this.setState({
                whatWasDoneEditor: editor,
              });
            }}
          />
          <hr />
          <Form.Label className="report-section-title">Problems Encountered (optional)</Form.Label>
          <CKEditor
            editor={ClassicEditor} // TODO: Adjust initial height so it looks better
            data={curState.defaultProblemText === '-1' ? '' : curState.defaultProblemText}
            disabled={props.isViewing}
            onInit={(editor) => {
              // You can store the "editor" and use when it is needed.
              this.setState({
                problemsEncounteredEditor: editor,
              });
            }}
          />
          <hr />
          <Form.Label className="report-section-title">Files</Form.Label>
          <Form.File name="file-1" id="file-1" />
          <Form.File name="file-2" id="file-2" />
          <hr />
          {(props.isEditing || props.isViewing) && (
            <div>
              <Card.Title className="card-title">Admin Comment</Card.Title>
              <CKEditor
                editor={ClassicEditor} // TODO: Adjust initial height so it looks better
                data={curState.defaultAdminText === '-1' ? '' : curState.defaultAdminText}
                disabled={!props.isAdmin}
                onInit={(editor) => {
                  // You can store the "editor" and use when it is needed.
                  this.setState({
                    adminCommentEditor: editor,
                  });
                }}
              />
              <hr />
              <p>
                This report is currently{' '}
                <b>
                  {curState.curStatus === 0
                    ? 'pending'
                    : curState.curStatus === 1
                    ? 'approved'
                    : 'denied'}
                </b>
                .
              </p>
            </div>
          )}
          {props.isAdmin && (
            <div>
              <Card.Title className="card-title">Decision</Card.Title>
              <Card.Text>
                Debug:{' '}
                <b>
                  {curState.curStatus === 0
                    ? 'pending'
                    : curState.curStatus === 1
                    ? 'approved'
                    : 'denied'}
                </b>
                . Select a different option below to update the status.
              </Card.Text>{' '}
              {/* TODO: get rid of this */}
              <RadioGroup default={curState.curStatus} parentCallback={this.updateStatus} />
            </div>
          )}
          {!props.isEditing && !props.isViewing && (
            <Button variant="primary" onClick={this.toggleSubmitModal}>
              Submit Report
            </Button>
          )}
          {props.isEditing && !props.isViewing && (
            <Button variant="primary" onClick={this.toggleSubmitModal}>
              Update Report
            </Button>
          )}
          {curState.loadSpinner && <Spinner variant="primary" animation="border" role="status" />}
        </Form>
      </div>
    );
  }
}

export class RadioGroup extends Component {
  render() {
    const { props } = this;
    return (
      <div>
        <label htmlFor="radio-pending">
          <input
            type="radio"
            name="Pending"
            value={0}
            checked={props.default === 0}
            onChange={() => props.parentCallback(0)}
          />
          Pending
        </label>
        <br />

        <label htmlFor="radio-approved">
          <input
            type="radio"
            name="Approved"
            value={1}
            checked={props.default === 1}
            onChange={() => props.parentCallback(1)}
          />
          Approved
        </label>
        <br />

        <label htmlFor="radio-denied">
          <input
            type="radio"
            name="Denied"
            value={2}
            checked={props.default === 2}
            onChange={() => props.parentCallback(2)}
          />
          Denied
        </label>
        <br />
      </div>
    );
  }
}

RadioGroup.propTypes = {
  default: propTypes.number,
  parentCallback: propTypes.func,
};

RadioGroup.defaultProps = {
  default: -1,
  parentCallback: () => {},
};

SubmitReportForm.propTypes = {
  userID: propTypes.string, // TA writing the report
  numOfTas: propTypes.number,
  currentTaSelections: propTypes.shape({
    rowID: propTypes.shape({
      userID: propTypes.string,
      timeIn: propTypes.string,
      timeOut: propTypes.string,
    }),
  }),
  showSubmitModal: propTypes.bool,
  showIncompleteFormModal: propTypes.bool,
  incompleteFormModalMessage: propTypes.string,

  curWorktype: propTypes.string,
  curSection: propTypes.string,
  curDate: propTypes.string,
  curLab: propTypes.string,
  isCommitteeWork: propTypes.bool,
  submissionInfo: propTypes.string,
  curRnDProject: propTypes.string,
  curRecPresentationInfo: propTypes.string,
  curRadGroup: propTypes.string,
  curRadProjectName: propTypes.string,
  curStatus: propTypes.number,

  isViewing: propTypes.bool,
  isEditing: propTypes.bool,
  reportID: propTypes.number,
  isAdmin: propTypes.bool,

  defaultBodyText: propTypes.string,
  defaultProblemText: propTypes.string,
  defaultAdminText: propTypes.string,

  loadSpinner: propTypes.bool,

  parentCallback: propTypes.func,

  defaultDate: propTypes.instanceOf(Date),
};

SubmitReportForm.defaultProps = {
  userID: '-1',
  numOfTas: 1,
  currentTaSelections: propTypes.shape({
    userID: '-1',
    timeIn: '-1',
    timeOut: '-1',
  }),
  showSubmitModal: false,
  showIncompleteFormModal: false,
  incompleteFormModalMessage: ":( You shouldn't be seeing this. Please contact WebDev!",

  curWorktype: '-1',
  curSection: '-1',
  curDate: '-1',
  curLab: '-1',
  isCommitteeWork: false,
  submissionInfo: '-1',
  curRnDProject: '-1',
  curRecPresentationInfo: '-1',
  curRadGroup: '-1',
  curRadProjectName: 'Project name',
  curStatus: -1,

  isViewing: false,
  isEditing: false,
  reportID: -1,
  isAdmin: false,

  defaultBodyText: '-1',
  defaultProblemText: '-1',
  defaultAdminText: '-1',

  loadSpinner: false,

  parentCallback: (b) => {
    console.log('error calling func with: ', b);
  },

  defaultDate: new Date(),
};
