import axios from 'axios';
import React, { Component } from 'react';
import { Button, DropdownButton, Dropdown } from 'react-bootstrap';
import * as AiIcons from 'react-icons/ai';
import * as RiIcons from 'react-icons/ri';

export default class LoginButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userInfo: {},
      isLoggedIn: false,
    };
  }

  componentDidMount() {
    axios.get('/getUserInfo/').then((res) => {
      console.log('response', res);
      this.setState({
        userInfo: res.data ? res.data : {},
        isLoggedIn: !!res.data,
      });
    });
  }

  handleLogIn = () => {
    window.location.assign('http://localhost:3001/auth/google');
  };

  handleLogOut = () => {
    window.location.assign('http://localhost:3001/logout');
  };

  render() {
    const { userInfo } = this.state;
    const { isLoggedIn } = this.state;

    return (
      <div>
        {isLoggedIn ? (
          <DropdownButton
            variant="outline-primary"
            title={`${userInfo.firstName} ${userInfo.lastName}`}
          >
            <Dropdown.Item href="/usersTesting" style={{ color: '#57068c' }}>
              <AiIcons.AiOutlineProfile /> Profile
            </Dropdown.Item>
            <Dropdown.Item href="#" style={{ color: '#57068c' }}>
              <RiIcons.RiAdminLine /> Admin Login#
            </Dropdown.Item>
            <Dropdown.Item href="#" style={{ color: '#57068c' }}>
              <AiIcons.AiOutlineUserAdd /> Membership Approval#
            </Dropdown.Item>
            <Dropdown.Item href="#" style={{ color: '#57068c' }}>
              <AiIcons.AiOutlineUser /> Demo Student#
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item onClick={this.handleLogOut} style={{ color: '#57068c' }}>
              <AiIcons.AiOutlineLogout /> Logout
            </Dropdown.Item>
          </DropdownButton>
        ) : (
          <Button onClick={this.handleLogIn} variant="outline-primary">
            <AiIcons.AiOutlineLogin /> Login
          </Button>
        )}
      </div>
    );
  }
}
