import axios from 'axios';
import propTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Form, Col, Row, Modal } from 'react-bootstrap';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import LabDropdown from '../convenientDropdowns/labsDropdown';
import SectionsDropdown from '../convenientDropdowns/sectionsDropdown';

const config = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
};
const qs = require('querystring-es3');

function getCurTime() {
  // Keep the time in `yyyy-MM-dd HH:mm` format.
  const d = new Date();
  const curYear = d.getFullYear();
  const curMonth = `00${d.getMonth()}${1}`.slice(-2);
  const curDate = `00${d.getDate()}`.slice(-2);
  const curHours = `00${d.getHours()}`.slice(-2);
  const curMinutes = `00${d.getMinutes()}`.slice(-2);
  return `${curYear}-${curMonth}-${curDate} ${curHours}:${curMinutes}`;
}

export default class MakeupLabRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSubmitModal: props.showSubmitModal,
      showIncompleteFormModal: props.showIncompleteFormModal,
      incompleteFormModalMessage: props.incompleteFormModalMessage,

      curSection: props.curSection, // Section ID of the makeup lab
      curLab: props.curLab, // Lab ID of the makeup lab
      reasonBody: props.reasonBody, // Reason to request for a makeup lab
    };
    this.toggleSubmitModal = this.toggleSubmitModal.bind(this);
    this.toggleIncompleteFormModal = this.toggleIncompleteFormModal.bind(this);
  }

  getCurSection = (childData) => {
    this.setState({
      curSection: childData,
    });
  };

  getCurLab = (childData) => {
    this.setState({
      curLab: childData,
    });
  };

  submitMakeupLabRequest = () => {
    const curState = this.state;
    const section = parseInt(curState.curSection, 10);
    const lab = parseInt(curState.curLab, 10);
    const time = getCurTime();
    console.log(section, lab, time);
    const reasonBody = curState.reasonEditor.getData();

    // User didn't provide a reason, the section or the specific lab to
    // makeup.
    if (reasonBody === '') {
      this.toggleIncompleteFormModal('Forgot to give a reason.');
      return;
    }
    if (section === -1) {
      this.toggleIncompleteFormModal('Please select your section.');
      return;
    }
    if (lab === -1) {
      this.toggleIncompleteFormModal('Please select the lab that you need to make up.');
      return;
    }

    const obj = {
      userID: 15148, // TODO: currently hardcoded to Lujie
      curSection: section,
      curLab: lab,
      curTime: time,
      reasonBody,
    };

    console.log('Posting to API: ', obj);
    axios
      .post('/makeuplabs/', qs.stringify(obj), config)
      .then((response) => {
        console.log(response);
        window.location.replace('/makeuplabs'); // TODO: change to a non hard refresh
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  toggleSubmitModal() {
    this.setState((prevState) => ({
      showSubmitModal: !prevState.showSubmitModal,
    }));
  }

  toggleIncompleteFormModal(message) {
    this.setState((prevState) => ({
      showIncompleteFormModal: !prevState.showIncompleteFormModal,
      incompleteFormModalMessage: message,
    }));
  }

  render() {
    const curState = this.state;
    return (
      <div>
        <Modal show={curState.showSubmitModal} onHide={this.toggleSubmitModal} animation>
          <Modal.Header closeButton>
            <Modal.Title>Are you sure you want to submit?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Make sure all the information you entered is correct before proceeding.</p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.toggleSubmitModal} variant="secondary">
              Keep Writing
            </Button>
            <Button
              onClick={() => {
                this.submitMakeupLabRequest();
                this.toggleSubmitModal();
              }}
              variant="primary"
            >
              Submit{' '}
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={curState.showIncompleteFormModal}
          onHide={() => this.toggleIncompleteFormModal('Contact webdev')}
          animation
        >
          <Modal.Header closeButton>
            <Modal.Title>Form is incomplete!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Make sure you entered all the necessary information before pressing submit.</p>
            <p>
              <b>Error: </b>
              {curState.incompleteFormModalMessage}
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => this.toggleIncompleteFormModal('Contact webdev')}
              variant="primary"
            >
              Okay
            </Button>
          </Modal.Footer>
        </Modal>
        <Form>
          <Row>
            <Col>
              <p>
                <b>Section:</b>
              </p>
            </Col>
            <Col xs={10}>
              <SectionsDropdown
                default={curState.curSection}
                limited
                parentCallback={this.getCurSection}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <p>
                <b>Lab:</b>
              </p>
            </Col>
            <Col xs={10}>
              <LabDropdown default={curState.curLab} limited parentCallback={this.getCurLab} />
            </Col>
          </Row>
          <Row>
            <Col>
              <p>
                <b>Reason:</b>
              </p>
            </Col>
            <Col xs={10}>
              <CKEditor
                editor={ClassicEditor}
                data={curState.reasonBody === '-1' ? '' : curState.reasonBody}
                onInit={(editor) => {
                  this.setState({
                    reasonEditor: editor,
                  });
                  console.log('Editor is ready to use!', editor);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Button variant="primary" onClick={this.toggleSubmitModal}>
                Submit Request
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

MakeupLabRequest.propTypes = {
  showSubmitModal: propTypes.bool,
  showIncompleteFormModal: propTypes.bool,
  incompleteFormModalMessage: propTypes.string,
  curSection: propTypes.string,
  curLab: propTypes.string,
  reasonBody: propTypes.string,
};

MakeupLabRequest.defaultProps = {
  showSubmitModal: false,
  showIncompleteFormModal: false,
  incompleteFormModalMessage: ":( You shouldn't be seeing this. Please contact WebDev!",
  curSection: '-1',
  curLab: '-1',
  reasonBody: '-1',
};
