import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import Announcements from './views/pages/announcements/index';
import AddAnnoucement from './views/pages/addAnnouncement/addAnnouncement';
import CommitteeAdministration from './views/pages/committeeAdministration/index';
import Register from './views/pages/register/index';
import Reports from './views/pages/reports/index';
import ReportsAdministration from './views/pages/reportsAdministration';
import Gradebook from './views/pages/gradebook';
import Replacements from './views/pages/replacements';
import Contracts from './views/pages/contracts/index';
import Inventory from './views/pages/inventory/index';
import UsersTesting from './views/pages/usersTesting/usersTesting';
import MakeupLabs from './views/pages/makeupLabs/index';
import LabDocuments from './views/pages/labDocuments/index';
import SldpActions from './views/pages/sldpActions/index';
import Sections from './views/pages/sections/index';
import StudentSubmission from './views/pages/studentSubmission/index';
import Payroll from './views/pages/payroll/index';
import Lectures from './views/pages/lectures/index';
import LecturesAdministration from './views/pages/lecturesAdministration/index';
import SldpManagementPage from './views/pages/sldpManagement/index';
import RadManagement from './views/pages/radManagement/index';
import Syllabus from './views/pages/syllabus/index';

export default class Routes extends Component {
  render() {
    return (
      <Switch>
        <Route path="/reports">
          <Reports />
        </Route>
        <Route path="/register">
          <Register />
        </Route>
        <Route path="/reports-administration">
          <ReportsAdministration />
        </Route>
        <Route path="/addAnnouncement">
          <AddAnnoucement />
        </Route>
        <Route path="/gradebook">
          <Gradebook />
        </Route>
        <Route path="/replacements">
          <Replacements />
        </Route>
        <Route path="/contracts">
          <Contracts />
        </Route>
        <Route path="/inventory">
          <Inventory />
        </Route>
        <Route path="/usersTesting">
          <UsersTesting />
        </Route>
        <Route path="/makeuplabs">
          <MakeupLabs />
        </Route>
        <Route path="/labdocuments">
          <LabDocuments />
        </Route>
        <Route path="/sldpActions">
          <SldpActions />
        </Route>
        <Route path="/committee-administration">
          <CommitteeAdministration />
        </Route>
        <Route path="/sections">
          <Sections />
        </Route>
        <Route path="/studentSubmission">
          <StudentSubmission />
        </Route>
        <Route path="/payroll">
          <Payroll />
        </Route>
        <Route path="/sldpmanagement">
          <SldpManagementPage />
        </Route>
        <Route path="/radmanagement">
          <RadManagement />
        </Route>
        <Route path="/syllabus">
          <Syllabus />
        </Route>
        <Route path="/lectures">
          <Lectures />
        </Route>
        <Route path="/lectures-administration">
          <LecturesAdministration />
        </Route>
        {/* NOTE: / must be the last path in the switch */}
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    );
  }
}

function Home() {
  return (
    <div>
      <h2 className="display-5 font-weight-light text-center" style={{ padding: '10px' }}>
        Welcome to General Engineering 1003
      </h2>
      <Announcements />
    </div>
  );
}
