import React, { Component } from 'react';
import propTypes from 'prop-types';
import { Form } from 'react-bootstrap';

export default class TimeSelector extends Component {
  updateCurSelection = (e) => {
    const { props } = this;
    if (props.rowId === '') {
      props.parentCallback(e.target.value);
    } else {
      props.parentCallback(e.target.value, props.rowId);
    }
  };

  render() {
    const { props } = this;
    const curLabel = props.label;
    return (
      <Form.Control
        disabled={props.isDisabled}
        value={props.default}
        required
        as="select"
        onChange={this.updateCurSelection}
      >
        <option value="-1">{curLabel}</option>
        <option value="0700">07:00 AM</option>
        <option value="0730">07:30 AM</option>
        <option value="0800">08:00 AM</option>
        <option value="0830">08:30 AM</option>
        <option value="0900">09:00 AM</option>
        <option value="0930">09:30 AM</option>
        <option value="1000">10:00 AM</option>
        <option value="1030">10:30 AM</option>
        <option value="1100">11:00 AM</option>
        <option value="1130">11:30 AM</option>
        <option value="1200">12:00 PM</option>
        <option value="1230">12:30 PM</option>
        <option value="1300">1:00 PM</option>
        <option value="1330">1:30 PM</option>
        <option value="1400">2:00 PM</option>
        <option value="1430">2:30 PM</option>
        <option value="1500">3:00 PM</option>
        <option value="1530">3:30 PM</option>
        <option value="1600">4:00 PM</option>
        <option value="1630">4:30 PM</option>
        <option value="1700">5:00 PM</option>
        <option value="1730">5:30 PM</option>
        <option value="1800">6:00 PM</option>
        <option value="1830">6:30 PM</option>
        <option value="1900">7:00 PM</option>
        <option value="1930">7:30 PM</option>
        <option value="2000">8:00 PM</option>
        <option value="2030">8:30 PM</option>
        <option value="2100">9:00 PM</option>
        <option value="2130">9:30 PM</option>
        <option value="2200">10:00 PM</option>
      </Form.Control>
    );
  }
}

TimeSelector.propTypes = {
  label: propTypes.string,
  rowId: propTypes.string,
  parentCallback: propTypes.func,
  default: propTypes.string,
  isDisabled: propTypes.bool,
};

TimeSelector.defaultProps = {
  label: 'Sel. Time',
  rowId: '',
  parentCallback: () => {},
  default: '-1',
  isDisabled: false,
};
