import React, { Component } from 'react';
import { Alert, Button, Table, Modal, Spinner } from 'react-bootstrap';
import propTypes from 'prop-types';
import axios from 'axios';

import ymdToMonthName from '../../../utils/ymdToMonthName';
import SqlUTCToNYTime from '../../../utils/sqlUTCToNYTime';

import UsersDropdown from '../convenientDropdowns/usersDropdown';

const colorDict = {
  REQUESTED: 'goldenrod',
  OPEN: 'limegreen',
  WAITING: 'crimson',
};

const config = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
};
const qs = require('querystring-es3');

class ViewRequestModal extends Component {
  render() {
    const { props } = this;
    const { specificReplacement } = props;
    return (
      <Modal
        show={props.show}
        onHide={props.onHide}
        animation={props.modalAnimation}
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Viewing Request</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <b>Requester: </b> {`${specificReplacement.firstName} ${specificReplacement.lastName}`}
          </p>
          <p>
            <b>Date: </b>{' '}
            {ymdToMonthName(SqlUTCToNYTime(specificReplacement.date_start).slice(0, 10))}
          </p>
          <p>
            <b>Time: </b>{' '}
            {`${SqlUTCToNYTime(specificReplacement.date_start).slice(11, 17)} to ${SqlUTCToNYTime(
              specificReplacement.date_end,
            ).slice(11, 17)}`}
          </p>
          <p>
            <b>Worktype: </b> {specificReplacement.workType}
          </p>
          {specificReplacement.workTypeID === 4 && (
            <div>
              <p>
                <b>Section:</b> {specificReplacement.secName}
              </p>
              <p>
                <b>Lab:</b>{' '}
                {`${specificReplacement.expLabShortName} - ${specificReplacement.expLabName}`}
              </p>
            </div>
          )}
          {specificReplacement.workTypeID === 12 && (
            <div>
              <p>
                <b>Section:</b> {specificReplacement.secName}
              </p>
              <p>
                <b>Lab:</b>{' '}
                {`${specificReplacement.expRecitationShortName} - ${specificReplacement.expRecitationName}`}
              </p>
            </div>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={props.onHide} variant="secondary" size="sm">
            Close
          </Button>
          <Button onClick={props.submit} variant="primary" size="sm">
            Apply for Job
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default class ViewReplacementRequests extends Component {
  constructor(props) {
    super(props);

    this.state = {
      replacementRequests: props.replacementRequests,
      specificReplacement: props.specificReplacement,
      replacerID: props.replacerID,
      viewModal: props.viewModal,

      loadSpinner: props.loadSpinner,
      success: props.success,
      error: props.error,
    };
    this.updateCurrentSpecificReplacement = this.updateCurrentSpecificReplacement.bind(this);
  }

  componentDidMount() {
    const { props } = this;
    if (props.status === 'OPEN') {
      axios.get('/replacements-openAndWaiting').then((res) => {
        console.log('response', res);
        this.setState({
          replacementRequests: res.data,
        });
      });
    } else {
      axios.get('/replacements-'.concat(props.status.toLowerCase())).then((res) => {
        console.log('response', res);
        this.setState({
          replacementRequests: res.data,
        });
      });
    }
  }

  toggleViewModal = () => {
    this.setState((prevState) => ({
      viewModal: !prevState.viewModal,
    }));
  };

  getTaSelectInfo = (childData) => {
    this.setState({
      replacerID: childData,
    });
  };

  toggleSuccess = () => {
    this.setState((prevState) => ({
      success: !prevState.success,
    }));
  };

  toggleError = () => {
    this.setState((prevState) => ({
      error: !prevState.error,
    }));
  };

  applyForReplacement = (replacementID) => {
    const { props } = this;
    const curState = this.state;
    if (!props.testSubmit()) {
      this.setState({
        loadSpinner: true,
        error: false,
        success: false,
      });
      axios
        .post(
          '/requestToFillReplacementRequest/',
          qs.stringify({
            userID: curState.replacerID,
            replacementID,
          }),
          config,
        )
        .then(() => {
          this.setState({
            loadSpinner: false,
            error: false,
            success: true,
          });
        })
        .catch(() => {
          this.setState({
            loadSpinner: false,
            error: true,
            success: false,
          });
        });
      this.toggleViewModal();
    } else {
      props.testSubmit({
        userID: curState.replacerID,
        replacementID,
      });
    }
  };

  updateCurrentSpecificReplacement(requestID, worktypeID) {
    axios.get('/replacements/'.concat(`${requestID}/${worktypeID}`)).then((res) => {
      this.setState({
        specificReplacement: res.data[0],
      });
      this.toggleViewModal();
    });
  }

  render() {
    const curState = this.state;
    const { props } = this;
    console.log('viewreplacementrequests props: ', props);
    console.log('viewreplacementrequests curState: ', curState);
    if (props.status === 'OPEN') {
      const { specificReplacement } = this.state;
      const { replacementRequests } = this.state;
      let i = -1;
      const trs = replacementRequests.map((request) => {
        i += 1; // this is used to update the requests state object. It matches with the keys of the objects in that object.
        const date = ymdToMonthName(SqlUTCToNYTime(request.date_start).slice(0, 10));
        const timeIn = SqlUTCToNYTime(request.date_start).slice(11, 17);
        const timeOut = SqlUTCToNYTime(request.date_end).slice(11, 17);
        const jobType = request.workType;
        const { status } = request;
        return (
          <tr key={i}>
            <td key={`${request.replacementID}-jobType`}>{jobType}</td>
            <td key={`${request.replacementID}-date`}>{date}</td>
            <td key={`${request.replacementID}-time`}>{`${timeIn}-${timeOut}`}</td>
            <td key={`${request.replacementID}-status`} style={{ color: colorDict[status] }}>
              <b>{status}</b>
            </td>
            <td key={`${request.replacementID}-viewButton`}>
              <Button
                variant="primary"
                size="sm"
                onClick={() =>
                  this.updateCurrentSpecificReplacement(request.replacementID, request.workTypeID)
                }
              >
                View
              </Button>
            </td>
          </tr>
        );
      });
      if (curState.replacementRequests.length !== 0) {
        return (
          <div>
            <p className="text-muted">TA submitting report (for testing):</p>
            <UsersDropdown
              default={curState.replacerID}
              parentCallback={this.getTaSelectInfo}
              groupID={2}
            />
            <br />
            <Table responsive bordered hover>
              <thead>
                <tr className="header-row">
                  <th>Job Type</th>
                  <th>Date</th>
                  <th>Time</th>
                  <th>Replacement Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>{trs}</tbody>
            </Table>
            {curState.loadSpinner && <Spinner variant="primary" animation="border" role="status" />}
            {curState.success && (
              <Alert variant="success" onClose={this.toggleSuccess} dismissible>
                Replacement request was successfully created.
              </Alert>
            )}
            {curState.error && (
              <Alert variant="danger" onClose={this.toggleError} dismissible>
                Error! Contact webdev.
              </Alert>
            )}
            {curState.viewModal && (
              <ViewRequestModal
                show={curState.viewModal}
                onHide={this.toggleViewModal}
                specificReplacement={curState.specificReplacement}
                submit={() => this.applyForReplacement(specificReplacement.replacementID, i)}
                modalAnimation={props.modalAnimation}
              />
            )}
          </div>
        );
      }
      return <p className="text-muted">Currently there is no open replacement request.</p>;
    }
    return <p>This is an error, contact webdev.</p>;
  }
}

ViewRequestModal.propTypes = {
  specificReplacement: propTypes.shape({
    comment: propTypes.string,
    date_end: propTypes.string,
    date_start: propTypes.string,
    expLabID: propTypes.number,
    expRecitationID: propTypes.number,
    firstName: propTypes.string,
    lastName: propTypes.string,
    num_cron_approved_reminders_sent: propTypes.number,
    num_cron_warnings_sent: propTypes.number,
    replacementID: propTypes.number,
    sectionID: propTypes.number,
    status: propTypes.string,
    userID: propTypes.number,
    workType: propTypes.string,
    workTypeID: propTypes.number,
    secName: propTypes.string,
    expLabShortName: propTypes.string,
    expLabName: propTypes.string,
    expRecitationShortName: propTypes.string,
    expRecitationName: propTypes.string,
  }),
  show: propTypes.bool,
  onHide: propTypes.func,
  modalAnimation: propTypes.func,
  submit: propTypes.func,
};

ViewRequestModal.defaultProps = {
  specificReplacement: {
    comment: '-1',
    date_end: '-1',
    date_start: '-1',
    expLabID: -1,
    expRecitationID: -1,
    firstName: '-1',
    lastName: '-1',
    num_cron_approved_reminders_sent: -1,
    num_cron_warnings_sent: -1,
    replacementID: -1,
    sectionID: -1,
    status: '-1',
    userID: -1,
    workType: '-1',
    workTypeID: -1,
    secName: '-1',
    expLabShortName: '-1',
    expLabName: '-1',
    expRecitationShortName: '-1',
    expRecitationName: '-1',
  },
  show: true,
  onHide: () => {},
  modalAnimation: () => {},
  submit: () => {},
};

ViewReplacementRequests.propTypes = {
  replacementRequests: propTypes.arrayOf(
    propTypes.shape({
      comment: propTypes.string,
      date_end: propTypes.string,
      date_start: propTypes.string,
      expLabID: propTypes.number,
      expRecitationID: propTypes.number,
      firstName: propTypes.string,
      lastName: propTypes.string,
      num_cron_approved_reminders_sent: propTypes.number,
      num_cron_warnings_sent: propTypes.number,
      replacementID: propTypes.number,
      sectionID: propTypes.number,
      status: propTypes.string,
      userID: propTypes.number,
      workType: propTypes.string,
      workTypeID: propTypes.number,
      secName: propTypes.string,
      expLabShortName: propTypes.string,
      expLabName: propTypes.string,
      expRecitationShortName: propTypes.string,
      expRecitationName: propTypes.string,
    }),
  ),
  specificReplacement: propTypes.shape({
    comment: propTypes.string,
    date_end: propTypes.string,
    date_start: propTypes.string,
    expLabID: propTypes.number,
    expRecitationID: propTypes.number,
    firstName: propTypes.string,
    lastName: propTypes.string,
    num_cron_approved_reminders_sent: propTypes.number,
    num_cron_warnings_sent: propTypes.number,
    replacementID: propTypes.number,
    sectionID: propTypes.number,
    status: propTypes.string,
    userID: propTypes.number,
    workType: propTypes.string,
    workTypeID: propTypes.number,
    secName: propTypes.string,
    expLabShortName: propTypes.string,
    expLabName: propTypes.string,
    expRecitationShortName: propTypes.string,
    expRecitationName: propTypes.string,
  }),
  viewModal: propTypes.bool,
  replacerID: propTypes.string,
  status: propTypes.string,

  loadSpinner: propTypes.bool,
  success: propTypes.bool,
  error: propTypes.bool,

  modalAnimation: propTypes.bool,
  testSubmit: propTypes.func,
};

ViewReplacementRequests.defaultProps = {
  replacementRequests: [
    {
      comment: '-1',
      date_end: '-1',
      date_start: '-1',
      expLabID: -1,
      expRecitationID: -1,
      firstName: '-1',
      lastName: '-1',
      num_cron_approved_reminders_sent: -1,
      num_cron_warnings_sent: -1,
      replacementID: -1,
      sectionID: -1,
      status: '-1',
      userID: -1,
      workType: '-1',
      workTypeID: -1,
      secName: '-1',
      expLabShortName: '-1',
      expLabName: '-1',
      expRecitationShortName: '-1',
      expRecitationName: '-1',
    },
  ],
  specificReplacement: {
    comment: '-1',
    date_end: '-1',
    date_start: '-1',
    expLabID: -1,
    expRecitationID: -1,
    firstName: '-1',
    lastName: '-1',
    num_cron_approved_reminders_sent: -1,
    num_cron_warnings_sent: -1,
    replacementID: -1,
    sectionID: -1,
    status: '-1',
    userID: -1,
    workType: '-1',
    workTypeID: -1,
    secName: '-1',
    expLabShortName: '-1',
    expLabName: '-1',
    expRecitationShortName: '-1',
    expRecitationName: '-1',
  },
  viewModal: false,
  replacerID: '-1',
  status: '-1',

  loadSpinner: false,
  success: false,
  error: false,

  modalAnimation: true, // we set this to false in our tests because animated modals cause tests to fail
  testSubmit: () => false, // we use this prop in testing to validate the data we're sending to the API
};
