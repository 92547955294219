import React, { Component } from 'react';

import { Tabs, Tab } from 'react-bootstrap';

import AllItems from '../../components/inventory/allItems';
import SldpKits from '../../components/inventory/sldpKits';
import IndividualItems from '../../components/inventory/individualItems';

export default class Inventory extends Component {
  render() {
    return (
      <div style={{ marginBottom: '170px' }}>
        <h2>Inventory</h2>
        <Tabs defaultActiveKey="allItems" id="inventory-items-tabs">
          <Tab eventKey="allItems" title="All Items" style={{ 'margin-top': '70px' }}>
            <AllItems />
          </Tab>
          <Tab eventKey="sldpKits" title="SLDP Kits" style={{ 'margin-top': '70px' }}>
            <SldpKits />
          </Tab>
          <Tab eventKey="individualItems" title="Individual Items" style={{ 'margin-top': '70px' }}>
            <IndividualItems />
          </Tab>
        </Tabs>
      </div>
    );
  }
}
