import React, { useState } from 'react';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const SidebarLink = styled(Link)`
  display: flex;
  color: #1c1c1c;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  list-style: none;
  height: 55px;
  text-decoration: none;
  font-size: 17px;

  &:hover {
    background: #fffffb;
    text-decoration: none;
    font-weight: 500;
    color: #1c1c1c;
    border-left: 4px solid #57068c;
    cursor: pointer;
  }
`;

const SidebarLabal = styled.span`
  margin-left: 16px;
`;

const DropdownLink = styled(Link)`
  height: 55px;
  padding-left: 3rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #1c1c1c;
  font-size: 16px;

  &:hover {
    text-decoration: none;
    background: #fffffb;
    font-weight: 500;
    font-size: 16px;
    color: #57068c;
    cursor: pointer;
  }
`;

const SubBar = ({ item }) => {
  const [status, setSubnavStatus] = useState(false);
  const showSubnav = () => {
    setSubnavStatus(!status);
    console.log('clicked!');
  };
  return (
    <>
      <SidebarLink to={item.path} onClick={item.subNav && showSubnav}>
        <div>
          {item.icon}
          <SidebarLabal>{item.title}</SidebarLabal>
        </div>
        <div>{item.subNav && status ? item.iconOpened : item.subNav ? item.iconClosed : null}</div>
      </SidebarLink>
      {status &&
        item.subNav.map((i) => (
          <DropdownLink to={i.path} key={i.title}>
            {i.icon}
            <SidebarLabal>{i.title}</SidebarLabal>
          </DropdownLink>
        ))}
    </>
  );
};

SubBar.propTypes = {
  item: propTypes.shape({
    icon: propTypes.elementType,
    iconClosed: propTypes.elementType,
    iconOpened: propTypes.elementType,
    path: propTypes.string,
    subNav: propTypes.arrayOf(
      propTypes.shape({
        icon: propTypes.elementType,
        path: propTypes.string,
        title: propTypes.string,
      }),
    ),
    title: propTypes.string,
  }),
};

SubBar.defaultProps = {
  item: {
    icon: <p>-1</p>,
    iconClosed: <p>-1</p>,
    iconOpened: <p>-1</p>,
    path: '-1',
    subNav: [
      {
        icon: <p>-1</p>,
        path: '-1',
        title: '-1',
      },
    ],
    title: '-1',
  },
};

export default SubBar;
