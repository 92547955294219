import React, { Component } from 'react';
import axios from 'axios';
import { Table, Tab, Tabs } from 'react-bootstrap';
import propTypes from 'prop-types';

import './gradebookForSection.scss';

const config = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
};
const qs = require('querystring-es3');

class GradebookTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      students: props.students,
      submitChangedToUpdate: props.submitChangedToUpdate, // used to change from submit function to update function if a user doesn't refresh page after submitting new grades.
    };
  }

  componentDidMount() {
    const { props } = this;
    axios.get(`/usersSection/${props.sectionID}`).then((res) => {
      console.log('response', res);
      this.setState({
        students: res.data,
      });
    });
  }

  updateState = () => {
    const { props } = this;
    axios.get(`/usersSection/${props.sectionID}`).then((res) => {
      console.log('response', res);
      this.setState({
        students: res.data,
      });
    });
  };

  submitGrade = (e, itemID, uid) => {
    const curState = this.state;
    const submitChangedToUpdateObjID = itemID.toString() + uid.toString();
    if (
      Object.prototype.hasOwnProperty.call(
        curState.submitChangedToUpdate,
        submitChangedToUpdateObjID,
      )
    ) {
      console.log('We are in the updateGrade function');
      this.updateGrade(e, curState.submitChangedToUpdate[submitChangedToUpdateObjID]);
      return;
    }

    if (e.target.value === '') return;
    let grade = parseInt(e.target.value, 10);
    if (e.target.value === '') grade = 0;
    const postObj = {
      itemID,
      uid,
      grade,
      gradetext: grade,
    };
    axios
      .post('/gradebook/', qs.stringify(postObj), config)
      .then((response) => {
        // console.log("THIS IS THE GB POST RESPONSE: ", response);

        // updating submitChangedToUpdate state item
        const obj = curState.submitChangedToUpdate;
        obj[submitChangedToUpdateObjID] = response.data.id;
        this.setState({
          submitChangedToUpdate: obj,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  updateGrade = (e, gradeID) => {
    let grade;
    if (e.target.value === '') grade = 0;
    else grade = parseInt(e.target.value, 10);
    const putObj = {
      grade,
      gradetext: grade,
    };
    axios
      .put(`/gradebook/${gradeID}`, qs.stringify(putObj), config)
      .then((response) => {
        console.log(response);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const { props } = this;
    const curState = this.state;
    console.log('GradebookTable props: ', props);
    console.log('GradebookTable state: ', curState);
    const { gradeItems, gradebook } = props;
    const { students } = curState;

    const relevantGradeItems = [];

    for (let i = 0; i < gradeItems.length; i += 1) {
      if (gradeItems[i].cat === props.category) relevantGradeItems.push(gradeItems[i]);
    }

    const ths = [];
    let trs = [];

    ths.push(<th>Student Name</th>);
    for (let i = 0; i < relevantGradeItems.length; i += 1) {
      ths.push(<th>{relevantGradeItems[i].name}</th>);
    }

    trs = students.map((student) => {
      let inputTds;
      // TODO: figure out why the below linter error occurs
      // eslint-disable-next-line no-return-assign
      return (
        <tr>
          <td id={student.userID}>{`${student.firstName} ${student.lastName}`}</td>
          {
            (inputTds = relevantGradeItems.map((item) => {
              let foundMatch = false;
              for (let i = 0; i < gradebook.length; i += 1) {
                // if (student.userID == 15175) console.log("GRADEBOOK FOR RHIANNON AT : ",i)
                if (gradebook[i].itemID === item.id && gradebook[i].uid === student.userID) {
                  foundMatch = true;
                  return (
                    <td
                      id={`td-${student.userID}-${item.id}`}
                      key={`td${student.userID}-${item.id}`}
                    >
                      <input
                        size="1"
                        id={`${student.userID}-${item.id}`}
                        defaultValue={gradebook[i].grade}
                        key={`${student.userID}-${item.id}`}
                        onBlur={(e) => this.updateGrade(e, gradebook[i].id)}
                      />
                    </td>
                  );
                }
              }
              if (!foundMatch) {
                return (
                  <td
                    id={`td-${student.userID}-${item.id}`}
                    key={`td-${student.userID}-${item.id}`}
                  >
                    <input
                      size="1"
                      id={`${student.userID}-${item.id}`}
                      key={`${student.userID}-${item.id}`}
                      onBlur={(e) => this.submitGrade(e, item.id, student.userID)}
                    />
                  </td>
                );
              }
              return inputTds;
            }))
          }
        </tr>
      );
    });

    return (
      <Table responsive striped bordered hover>
        <thead>
          <tr className="header-row">{ths}</tr>
        </thead>
        <tbody>{trs}</tbody>
      </Table>
    );
  }
}

export default class GradebookForSection extends Component {
  render() {
    const { props } = this;
    console.log('gradebookforsection props: ', props);
    console.log('gradebookforsection state: ', this.state);
    let tabs = [];
    const allGradeCategories = props.gradeCategories;

    // console.log("ALL GRADE CATEGORIES: ", allGradeCategories)

    tabs = allGradeCategories.map((cat) => (
      <Tab key={cat.id} eventKey={cat.id} title={cat.name}>
        <GradebookTable
          gradebook={props.gradebook}
          gradeItems={props.gradeItems}
          sectionID={props.sectionID}
          category={cat.id}
        />
      </Tab>
    ));
    return (
      <div>
        <h2 className="section-title">Gradebook for {props.sectionName}</h2>
        <Tabs
          className="gradebook-categories-tabs"
          key={`gradebook-categories-tabs-${props.sectionID}`}
          id={`gradebook-categories-tabs-${props.sectionID}`}
        >
          {tabs}
        </Tabs>
      </div>
    );
  }
}

GradebookTable.propTypes = {
  category: propTypes.number,
  students: propTypes.arrayOf(
    propTypes.shape({
      approved: propTypes.number,
      barcode: propTypes.string,
      firstName: propTypes.string,
      groupID: propTypes.number,
      idNum: propTypes.string,
      joinDate: propTypes.string,
      lastName: propTypes.string,
      quit: propTypes.number,
      report_pin: propTypes.string,
      sectionID: propTypes.number,
      userEmail: propTypes.string,
      userID: propTypes.number,
    }),
  ),
  gradeItems: propTypes.arrayOf(
    propTypes.shape({
      cat: propTypes.number,
      extID: propTypes.number,
      id: propTypes.number,
      isLetter: propTypes.number,
      max: propTypes.number,
      name: propTypes.string,
      seq: propTypes.number,
      submitID: propTypes.number,
      weight: propTypes.number,
    }),
  ),
  sectionID: propTypes.number,
  submitChangedToUpdate: propTypes.shape({
    gradeID: propTypes.number,
  }),
  gradebook: propTypes.arrayOf(
    propTypes.shape({
      grade: propTypes.number,
      gradetext: propTypes.string,
      id: propTypes.number,
      itemID: propTypes.number,
      uid: propTypes.number,
    }),
  ),
};

GradebookTable.defaultProps = {
  category: -1,
  students: [
    {
      approved: -1,
      barcode: '-1',
      firstName: '-1',
      groupID: -1,
      idNum: '-1',
      joinDate: '-1',
      lastName: '-1',
      quit: -1,
      report_pin: '-1',
      sectionID: -1,
      userEmail: '-1',
      userID: -1,
    },
  ],
  gradeItems: [
    {
      cat: -1,
      extID: -1,
      id: -1,
      isLetter: -1,
      max: -1,
      name: '-1',
      seq: -1,
      submitID: -1,
      weight: -1,
    },
  ],
  sectionID: -1,
  submitChangedToUpdate: {
    gradeID: -1,
  },
  gradebook: {
    grade: -1,
    gradetext: '-1',
    id: -1,
    itemID: -1,
    uid: -1,
  },
};

GradebookForSection.propTypes = {
  gradeCategories: propTypes.arrayOf(
    propTypes.shape({
      id: propTypes.number,
      name: propTypes.string,
    }),
  ),
  gradebook: propTypes.arrayOf(
    propTypes.shape({
      grade: propTypes.number,
      gradetext: propTypes.string,
      id: propTypes.number,
      itemID: propTypes.number,
      uid: propTypes.number,
    }),
  ),
  sectionID: propTypes.number,
  gradeItems: propTypes.arrayOf(
    propTypes.shape({
      cat: propTypes.number,
      extID: propTypes.number,
      id: propTypes.number,
      isLetter: propTypes.number,
      max: propTypes.number,
      name: propTypes.string,
      seq: propTypes.number,
      submitID: propTypes.number,
      weight: propTypes.number,
    }),
  ),
  sectionName: propTypes.string,
};

GradebookForSection.defaultProps = {
  gradeCategories: [
    {
      id: -1,
      name: '-1',
    },
  ],
  gradebook: propTypes.arrayOf(
    propTypes.shape({
      grade: -1,
      gradetext: '-1',
      id: -1,
      itemID: -1,
      uid: -1,
    }),
  ),
  sectionID: -1,
  gradeItems: propTypes.arrayOf(
    propTypes.shape({
      cat: -1,
      extID: -1,
      id: -1,
      isLetter: -1,
      max: -1,
      name: '-1',
      seq: -1,
      submitID: -1,
      weight: -1,
    }),
  ),
  sectionName: '-1',
};
