import React, { Component } from 'react';

import AnnouncementCard from '../../components/announcements/announcementCard';

import '../../components/announcements/announcements.scss';

export default class AddAnnouncement extends Component {
  render() {
    return <AnnouncementCard isNew />;
  }
}
