import axios from 'axios';
import propTypes from 'prop-types';
import React, { Component } from 'react';
import { Table, Button, Form, Col, Row } from 'react-bootstrap';

const config = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
};
const qs = require('querystring-es3');

export default class SldpMangement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newShortName: props.newShortName,
      newLongName: props.newLongName,
      editingShortName: props.editingShortName,
      editingLongName: props.editingLongName,
      editingSldpId: props.editingSldpId,
      ifNewRad: props.ifNewRad,
      curSldpInfo: props.curSldpInfo,
    };
  }

  componentDidMount() {
    axios.get('/sldp-all').then((res) => {
      this.setState({
        curSldpInfo: res.data,
      });
    });
  }

  updateNewShortName = (e) => {
    this.setState({
      newShortName: e.target.value,
    });
  };

  updateNewLongName = (e) => {
    this.setState({
      newLongName: e.target.value,
    });
  };

  updateIfNewRad = (e) => {
    this.setState({
      ifNewRad: e.target.checked,
    });
  };

  updateEditingShortName = (e) => {
    this.setState({
      editingShortName: e.target.value,
    });
  };

  updateEditingLongName = (e) => {
    this.setState({
      editingLongName: e.target.value,
    });
  };

  submitNewSldp = () => {
    const curState = this.state;
    const obj = {
      shortName: curState.newShortName,
      longName: curState.newLongName,
      isActive: '1',
      isSubOf: curState.ifNewRad ? '1' : '0',
    };
    console.log(obj);
    if (obj.shortName === '-1' || obj.longName === '-1') {
      console.log('ERROR!!Please check your input!');
      return;
    }
    axios
      .post('/sldp', qs.stringify(obj), config)
      .then(() => {
        this.setState({
          newShortName: '-1',
          newLongName: '-1',
        });
        this.componentDidMount();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  generateRadGroups = () => {
    let i;
    const body = [];
    for (i = 1; i < 11; i += 1) {
      const shortName = `RAD ${i}`;
      const longName = `RAD ${i} Group Name`;
      body.push([shortName, longName, '1', '1']);
    }
    console.log(body);
    axios
      .post('/rad-groups', qs.stringify(body), config)
      .then(() => {
        this.componentDidMount();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  toggleEditingProject = (project = {}) => {
    if (project === {}) {
      // Stop editing
      this.setState({
        editingSldpId: -1,
      });
    } else {
      // Start editing the table.
      this.setState({
        editingSldpId: project.sldpID,
        editingShortName: project.shortName,
        editingLongName: project.longName,
      });
    }
  };

  submitEditedProject = (sldp) => {
    const curState = this.state;
    const obj = {
      sldpID: sldp.sldpID,
      shortName: curState.editingShortName,
      longName: curState.editingLongName,
      isActive: sldp.isActive,
      isSubOf: sldp.isSubOf,
    };
    axios
      .put(`/sldp/${sldp.sldpID}`, qs.stringify(obj), config)
      .then(() => {
        this.componentDidMount();
        this.toggleEditingProject();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  changeSelectedSldpStatus = (sldp) => {
    const newStatus = sldp.isActive ? '0' : '1';
    const obj = {
      sldpID: sldp.sldpID,
      shortName: sldp.shortName,
      longName: sldp.longName,
      isActive: newStatus,
      isSubOf: sldp.isSubOf,
    };
    axios
      .put(`/sldp/${sldp.sldpID}`, qs.stringify(obj), config)
      .then(() => {
        this.componentDidMount();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const curState = this.state;
    const trs = curState.curSldpInfo.map((project) => {
      const { sldpID } = project;
      const { shortName } = project;
      const { longName } = project;
      const { isActive } = project;
      const { isSubOf } = project;
      if (curState.editingSldpId === sldpID) {
        return (
          <tr key={`editing-tr-${project.sldpID}`}>
            <td key={`editing-shortname-${sldpID}`}>
              <input
                key={`input-shortname-${sldpID}`}
                defaultValue={shortName}
                onBlur={this.updateEditingShortName}
              />
            </td>
            <td key={`editing-longname-${sldpID}`}>
              <input
                key={`input-longname-${sldpID}`}
                defaultValue={longName}
                onBlur={this.updateEditingLongName}
              />
            </td>
            <td key={`editing-isactive-${sldpID}`}>{isActive ? 'Yes' : 'No'}</td>
            <td key={`editing-israd-${sldpID}`}>{isSubOf ? 'Yes' : 'No'}</td>
            <td style={{ whiteSpace: 'nowrap' }}>
              <Button onClick={this.toggleEditingProject} variant="secondary">
                Cancel
              </Button>
              <Button onClick={() => this.submitEditedProject(project)} variant="success">
                Confirm
              </Button>
            </td>
          </tr>
        );
      }
      return (
        <tr key={project.sldpID}>
          <td key={`shortname-${sldpID}`}>{shortName}</td>
          <td key={`longname-${sldpID}`}>{longName}</td>
          <td key={`isactive-${sldpID}`}>{isActive ? 'Yes' : 'No'}</td>
          <td key={`israd-${sldpID}`}>{isSubOf ? 'Yes' : 'No'}</td>
          <td style={{ whiteSpace: 'nowrap' }}>
            <Button onClick={() => this.toggleEditingProject(project)} variant="primary">
              Edit
            </Button>
            {isActive === 1 && (
              <Button onClick={() => this.changeSelectedSldpStatus(project)} variant="danger">
                Disable
              </Button>
            )}
            {isActive === 0 && (
              <Button onClick={() => this.changeSelectedSldpStatus(project)} variant="success">
                Enable
              </Button>
            )}
          </td>
        </tr>
      );
    });
    return (
      <>
        <h4 className="display-5 pt-2 mb-4">Create a New SLDP</h4>
        <div>
          <Form>
            <Row>
              <Col>
                <p>Short Name:</p>
              </Col>
              <Col xs={10}>
                <input
                  placeholder="Short Name for the SLDP"
                  size="50"
                  onChange={this.updateNewShortName}
                  value={curState.newShortName === '-1' ? '' : curState.newShortName}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <p>Long Name:</p>
              </Col>
              <Col xs={10}>
                <input
                  placeholder="Long Name for the SLDP"
                  size="50"
                  onChange={this.updateNewLongName}
                  value={curState.newLongName === '-1' ? '' : curState.newLongName}
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>Is this a RAD project? </Col>
              <Col xs={7}>
                <input
                  key="if-new-rad"
                  type="checkbox"
                  onChange={this.updateIfNewRad}
                  defaultChecked={curState.ifNewRad}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Button variant="success" onClick={this.submitNewSldp}>
                  Submit New SLDP
                </Button>
                <Button variant="primary" onClick={this.generateRadGroups}>
                  Generate Ten New RAD Groups
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
        <h4 className="display-5 pt-4 mb-3">Update or Disable an Existing SLDP</h4>
        <p className="small text-muted">
          Refresh your page to view the changes reflected on other pages. <br />{' '}
        </p>
        <Table responsive striped bordered hover>
          <thead>
            <tr>
              <th>Short Name</th>
              <th>Long Name</th>
              <th>Active</th>
              <th>RAD</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>{trs}</tbody>
        </Table>
      </>
    );
  }
}

SldpMangement.propTypes = {
  newShortName: propTypes.string,
  newLongName: propTypes.string,
  editingShortName: propTypes.string,
  editingLongName: propTypes.string,
  editingSldpId: propTypes.number,
  ifNewRad: propTypes.bool,
  curSldpInfo: propTypes.arrayOf(
    propTypes.shape({
      sldpID: propTypes.number,
      shortName: propTypes.string,
      longName: propTypes.string,
      isActive: propTypes.number,
      isSubOf: propTypes.number,
    }),
  ),
};
SldpMangement.defaultProps = {
  newShortName: '-1',
  newLongName: '-1',
  editingShortName: '-1',
  editingLongName: '-1',
  editingSldpId: -1,
  ifNewRad: false,
  curSldpInfo: [
    {
      sldpID: -1,
      shortName: '-1',
      longName: '-1',
      isActive: -1,
      isSubOf: -1,
    },
  ],
};
