import React, { Component } from 'react';
import axios from 'axios';
import { Table } from 'react-bootstrap';
import propTypes from 'prop-types';

const backgroundColor = '#7851a9';
const whiteColor = 'white';

export default class SectionTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: props.items,
      users: props.users,
    };
  }

  componentDidMount() {
    axios.get('/sections/').then((res) => {
      this.setState({
        items: res.data,
      });
    });
    axios.get(`/users-active/`).then((res) => {
      this.setState({
        users: res.data,
      });
    });
  }

  renderHeaderData = (headerData) => {
    const trs = headerData.map((item) => (
      <th scope="col" style={{ background: backgroundColor, color: whiteColor }}>
        Section {item}
      </th>
    ));
    return (
      <tr>
        <th scope="col" style={{ background: backgroundColor, color: whiteColor }}>
          #
        </th>
        {trs}
      </tr>
    );
  };

  renderRowData = (rowHeader, rowData) => {
    let i = -1;
    const trs = rowData.map((item) => {
      i += 1;
      return <td key={`${i}-${rowHeader}`}>{item}</td>;
    });
    return (
      <tr>
        <th scope="row" width="200">
          {rowHeader}
        </th>
        {trs}
      </tr>
    );
  };

  renderTableData = (curItems, curUsers) => {
    const secName = curItems.map((state) => state.secName);
    const secProf = curItems.map((state) => curUsers[state.secProf]);
    const labTA1 = curItems.map((state) => curUsers[state.secTA1]);
    const labTA2 = curItems.map((state) => curUsers[state.secTA2]);
    const recTA = curItems.map((state) => curUsers[state.secRecTA]);
    const secWC = curItems.map((state) => curUsers[state.secWC]);
    const roamer = curItems.map((state) => curUsers[state.secRoamer]);
    const labRoom = curItems.map((state) => state.labRoom);
    const recRoom = curItems.map((state) => state.recRoom);
    return (
      <Table responsive striped bordered hover>
        <thead>{this.renderHeaderData(secName)}</thead>
        <tbody>
          {this.renderRowData('Professor', secProf)}
          {this.renderRowData('Lab TA', labTA1)}
          {this.renderRowData('Lab TA', labTA2)}
          {this.renderRowData('Recitation TA', recTA)}
          {this.renderRowData('Writing Consultant', secWC)}
          {this.renderRowData('Roaming TA', roamer)}
          {this.renderRowData('Lab Room', labRoom)}
          {this.renderRowData('Recitation Room', recRoom)}
        </tbody>
      </Table>
    );
  };

  render() {
    const curState = this.state;
    const curItems = curState.items;
    const curUsers = curState.users;
    const users = {};
    let i;
    let items = [];
    let curGroupName = 'A';

    for (i = 0; i < curUsers.length; i += 1) {
      const newKey = curUsers[i].userID;
      const newName = `${curUsers[i].firstName} ${curUsers[i].lastName}`;
      users[newKey] = newName;
    }
    const table = curItems.map((item) => {
      if (curGroupName === item.secgroupName) {
        items.push(item);
        return undefined;
      }
      const tempItems = Object.assign(items);
      items = [item];
      curGroupName = item.secgroupName;
      return <div>{this.renderTableData(tempItems, users)}</div>;
    });

    return (
      <div className="container">
        {table}
        {this.renderTableData(items, users)}
      </div>
    );
  }
}

SectionTable.propTypes = {
  items: propTypes.arrayOf(
    propTypes.shape({
      secgroupID: propTypes.number,
      secgroupName: propTypes.string,
      secID: propTypes.number,
      secName: propTypes.string,
      active: propTypes.number,
      secProf: propTypes.number,
      secTA1: propTypes.number,
      secTA2: propTypes.number,
      secRecTA: propTypes.number,
      secWC: propTypes.number,
      secGrader: propTypes.number,
      secRoamer: propTypes.number,
      secObserver: propTypes.number,
      labRoom: propTypes.string,
      recRoom: propTypes.string,
      lecRoom: propTypes.string,
      isHSSec: propTypes.number,
    }),
  ),
  users: propTypes.arrayOf(
    propTypes.shape({
      userID: propTypes.number,
      groupID: propTypes.number,
      sectionID: propTypes.number,
      idNum: propTypes.string,
      barcode: propTypes.string,
      approved: propTypes.number,
      joinDate: propTypes.string,
      userEmail: propTypes.string,
      firstName: propTypes.string,
      lastName: propTypes.string,
      report_pin: propTypes.string,
      quit: propTypes.number,
    }),
  ),
};

SectionTable.defaultProps = {
  items: [
    {
      secgroupID: -1,
      secgroupName: '-1',
      secID: -1,
      secName: '-1',
      active: -1,
      secProf: -1,
      secTA1: -1,
      secTA2: -1,
      secRecTA: -1,
      secWC: -1,
      secGrader: -1,
      secRoamer: -1,
      secObserver: -1,
      labRoom: '-1',
      recRoom: '-1',
      lecRoom: '-1',
      isHSSec: -1,
    },
  ],
  users: [
    {
      userID: -1,
      groupID: -1,
      sectionID: -1,
      idNum: '-1',
      barcode: '-1',
      approved: -1,
      joinDate: '-1',
      userEmail: '-1',
      firstName: '-1',
      lastName: '-1',
      report_pin: '-1',
      quit: -1,
    },
  ],
};
