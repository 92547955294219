import axios from 'axios';
import propTypes from 'prop-types';
import React, { Component } from 'react';
import { Table, Button } from 'react-bootstrap';
import SldpDropdown from '../convenientDropdowns/sldpDropdown';
import RadDropdown from '../convenientDropdowns/radDropdown';

const config = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
};
const qs = require('querystring-es3');

const formTypeDict = {
  0: 'Benchmark A',
  1: 'Benchmark B',
  2: 'Commission',
};

export default class SldpMangementTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showOnlyRad: props.showOnlyRad, // If set to 1, we are viewing RAD Management page.
      curSldpID: props.curSldpID,
      curLongName: props.curLongName,
      curSldpRequirementInfo: props.curSldpRequirementInfo,

      editingRequirementID: props.editingRequirementID, // ID for a specific requirement
      editingDescription: props.editingDescription, // Description for a task
      editingRequired: props.editingRequired, // Whether the task is required to complete
      editingFormType: props.editingFormType, // Form type of the task we're editing
      editingExtraCredit: props.editingExtraCredit, // Number of extra credits assigned to the task

      addingDescription: props.addingDescription, // Description for a task we're adding
      addingRequired: props.addingRequired, // Whether the task we're adding is required to complete
      addingFormType: props.addingFormType, // Form type of the task we're adding
      addingExtraCredit: props.addingExtraCredit, // Number of extra credits assigned to the task we're adding
    };
  }

  updateCurSldpID = (childData) => {
    // Sldp ID is defined and not equal to general RAD ID.
    if (childData !== '-1' && childData !== '1') {
      axios.get(`/sldp/${childData}`).then((res) => {
        this.setState({
          curSldpID: childData,
          curLongName: res.data[0].longName,
        });
        axios.get(`/sldpmanagement/${childData}`).then((result) => {
          this.setState({
            curSldpRequirementInfo: result.data,
          });
        });
      });
    } else {
      this.setState({
        curSldpID: childData,
      });
    }
  };

  updateEditingDescrption = (e) => {
    this.setState({
      editingDescription: e.target.value,
    });
  };

  updateEditingRequired = (e) => {
    const value = e.target.checked ? '1' : '0';
    this.setState({
      editingRequired: value,
    });
  };

  updateEditingFormType = (e) => {
    this.setState({
      editingFormType: e.target.value,
    });
  };

  updateEditingExtraCredit = (e) => {
    this.setState({
      editingExtraCredit: e.target.value,
    });
  };

  updateAddingDescription = (e) => {
    this.setState({
      addingDescription: e.target.value,
    });
  };

  updateAddingRequired = (e) => {
    const value = e.target.checked ? '1' : '0';
    this.setState({
      addingRequired: value,
    });
  };

  updateAddingFormType = (e) => {
    this.setState({
      addingFormType: e.target.value,
    });
  };

  updateAddingExtraCredit = (e) => {
    this.setState({
      addingExtraCredit: e.target.value,
    });
  };

  toggleEditingRequirement = (requirement = {}) => {
    if (requirement === {}) {
      // Stop editing
      this.setState({
        editingRequirementID: -1,
      });
    } else {
      // Start editing the table.
      this.setState({
        editingRequirementID: requirement.reqID,
        editingRequired: requirement.isRequired,
        editingDescription: requirement.description,
        editingFormType: requirement.formID,
        editingExtraCredit: requirement.points,
      });
    }
  };

  submitEditedItem = (reqID) => {
    const curState = this.state;
    const sldpID = curState.curSldpID;
    const obj = {
      description: curState.editingDescription,
      formID: curState.editingFormType,
      isRequired: curState.editingRequired,
      points: curState.editingExtraCredit,
    };
    axios
      .put(`/sldpmanagment/${reqID}`, qs.stringify(obj), config)
      .then(() => {
        this.updateCurSldpID(sldpID);
        this.toggleEditingRequirement();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  submitNewTask = () => {
    const curState = this.state;
    const sldpID = curState.curSldpID;
    const addingIsTemp = sldpID === '1' ? '1' : '0';
    const obj = {
      sldpID: curState.curSldpID,
      description: curState.addingDescription,
      formID: curState.addingFormType,
      isRequired: curState.addingRequired,
      points: curState.addingExtraCredit,
      isTemp: addingIsTemp,
    };
    if (obj.description === '-1' || obj.formID === -1 || obj.points === '-1') {
      console.log('ERROR!! Please check your input!');
      return;
    }
    axios
      .post('/sldpmanagement', qs.stringify(obj), config)
      .then(() => {
        this.setState({
          addingDescription: '-1',
          addingRequired: -1,
          addingFormType: -1,
          addingExtraCredit: '-1',
        });
        this.updateCurSldpID(sldpID);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  deleteSelectedRequirement = (reqID) => {
    const curState = this.state;
    axios
      .delete(`/sldpmanagement/${reqID}`, config)
      .then(() => {
        this.updateCurSldpID(curState.curSldpID);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  renderRequirementsTable = () => {
    const curState = this.state;
    const trs = curState.curSldpRequirementInfo.map((requirement) => {
      const { description } = requirement;
      const isRequired = requirement.isRequired === 1;
      const { formID } = requirement;
      const { points } = requirement;
      const { reqID } = requirement;
      if (reqID === curState.editingRequirementID) {
        return (
          <tr key={`editing-tr-${reqID}`}>
            <td key={`editing-name-${reqID}`}>
              <input
                key={`input-description-${reqID}`}
                defaultValue={description}
                onBlur={this.updateEditingDescrption}
              />
            </td>
            <td key={`editing-required-${reqID}`}>
              <input
                key={`input-required-${reqID}`}
                type="checkbox"
                onChange={this.updateEditingRequired}
                defaultChecked={isRequired}
              />
            </td>
            <td key={`editing-formType-${reqID}`}>
              <label htmlFor="editing-radio-benchA">
                <input
                  name={`input-formType-${reqID}`}
                  id="editing-radio-benchA"
                  type="radio"
                  onChange={this.updateEditingFormType}
                  value="0"
                  defaultChecked={formID === 0}
                />{' '}
                BenchA
              </label>
              <br />
              <label htmlFor="editing-radio-benchB">
                <input
                  name={`input-formType-${reqID}`}
                  id="editing-radio-benchB"
                  type="radio"
                  onChange={this.updateEditingFormType}
                  value="1"
                  defaultChecked={formID === 1}
                />{' '}
                BenchB
              </label>
              <br />
              <label htmlFor="editing-radio-comm">
                <input
                  name={`input-formType-${reqID}`}
                  id="editing-radio-comm"
                  type="radio"
                  onChange={this.updateEditingFormType}
                  value="2"
                  defaultChecked={formID === 2}
                />{' '}
                Comm
              </label>
              <br />
            </td>
            <td key={`editing-extracredit-${reqID}`}>
              <input
                key={`input-extracredit-${reqID}`}
                defaultValue={points}
                onBlur={this.updateEditingExtraCredit}
              />
            </td>
            <td key={`editing-action-${reqID}`} style={{ whiteSpace: 'nowrap' }}>
              <Button onClick={this.toggleEditingRequirement} variant="secondary">
                Cancel
              </Button>
              <Button onClick={() => this.submitEditedItem(reqID)} variant="success">
                Confirm
              </Button>
            </td>
          </tr>
        );
      }
      return (
        <tr key={requirement.reqID}>
          <td key={`description-${reqID}`}>{description}</td>
          <td key={`required-${reqID}`}>{isRequired ? 'Yes' : 'No'}</td>
          <td key={`formType-${reqID}`}>{formTypeDict[formID]}</td>
          <td key={`extracredit-${reqID}`}>{points}</td>
          <td style={{ whiteSpace: 'nowrap' }}>
            <Button onClick={() => this.toggleEditingRequirement(requirement)} variant="primary">
              Edit
            </Button>
            <Button
              onClick={() => this.deleteSelectedRequirement(requirement.reqID)}
              variant="primary"
            >
              Delete
            </Button>
          </td>
        </tr>
      );
    });
    trs.push(
      <tr key="adding-tr" id="adding-tr">
        <td key="adding-name">
          <input
            key="input-description"
            placeholder="Enter task description"
            onChange={this.updateAddingDescription}
            value={curState.addingDescription === '-1' ? '' : curState.addingDescription}
          />
        </td>
        <td key="adding-required">
          <input
            key="input-required"
            type="checkbox"
            onChange={this.updateAddingRequired}
            defaultChecked={curState.addingRequired !== -1}
          />
        </td>
        <td key="adding-formType">
          <label htmlFor="radio-benchA">
            <input
              name="input-formType"
              id="radio-benchA"
              type="radio"
              onChange={this.updateAddingFormType}
              defaultChecked={curState.addingFormType !== -1}
              value="0"
            />{' '}
            BenchA
          </label>
          <br />
          <label htmlFor="radio-benchB">
            <input
              name="input-formType"
              id="radio-benchB"
              type="radio"
              onChange={this.updateAddingFormType}
              defaultChecked={curState.addingFormType !== -1}
              value="1"
            />{' '}
            BenchB
          </label>
          <br />
          <label htmlFor="radio-comm">
            <input
              name="input-formType"
              id="radio-comm"
              type="radio"
              onChange={this.updateAddingFormType}
              defaultChecked={curState.addingFormType !== -1}
              value="2"
            />{' '}
            Comm
          </label>
          <br />
        </td>
        <td key="adding-extracredit">
          <input
            key="input-extracredit"
            placeholder="Enter extra credit points"
            onChange={this.updateAddingExtraCredit}
            value={curState.addingExtraCredit === '-1' ? '' : curState.addingExtraCredit}
          />
        </td>
        <td key="adding-action">
          <Button onClick={() => this.submitNewTask()} variant="primary">
            Add Task
          </Button>
        </td>
      </tr>,
    );
    return trs;
  };

  renderTableHeader = () => {
    const curState = this.state;
    const trs = this.renderRequirementsTable();
    return (
      <>
        <h4>{curState.curLongName}</h4>
        <p className="small text-muted">
          Note: Scroll all the way down to move to the right. <br />{' '}
        </p>
        <Table responsive striped bordered hover>
          <thead>
            <tr>
              <th>Description</th>
              <th>Required</th>
              <th>Form Type</th>
              <th>Extra Credit Points</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>{trs}</tbody>
        </Table>
      </>
    );
  };

  render() {
    const curState = this.state;
    if (curState.showOnlyRad === 0) {
      return (
        <div className="mt-3">
          <SldpDropdown default={curState.curSldpID} parentCallback={this.updateCurSldpID} />
          <br />
          {curState.curSldpID !== '-1' && curState.curSldpID !== '1' && this.renderTableHeader()}
          {curState.curSldpID === '1' && (
            <>
              <p>
                Please select a RAD group from the dropdown below. <br />{' '}
              </p>
              <RadDropdown default="-1" parentCallback={this.updateCurSldpID} />
            </>
          )}
          <br />
          <br />
        </div>
      );
    }
    if (curState.showOnlyRad === 1) {
      return (
        <div>
          <RadDropdown default="-1" parentCallback={this.updateCurSldpID} />
          <br />
          {curState.curSldpID !== '-1' && curState.curSldpID !== '1' && this.renderTableHeader()}
        </div>
      );
    }
    return <h4>You shouldn&apos;t be seeing this. Please contact WebDev!</h4>;
  }
}

SldpMangementTable.propTypes = {
  showOnlyRad: propTypes.number,
  curSldpID: propTypes.string,
  curLongName: propTypes.string,
  curSldpRequirementInfo: propTypes.arrayOf(
    propTypes.shape({
      description: propTypes.string,
      formID: propTypes.number,
      isRequired: propTypes.number,
      isTemp: propTypes.string,
      points: propTypes.string,
      reqID: propTypes.string,
      sldpID: propTypes.string,
    }),
  ),
  editingRequirementID: propTypes.string,
  editingDescription: propTypes.string,
  editingRequired: propTypes.number,
  editingFormType: propTypes.number,
  editingExtraCredit: propTypes.string,

  addingDescription: propTypes.string,
  addingRequired: propTypes.number,
  addingFormType: propTypes.number,
  addingExtraCredit: propTypes.string,
};

SldpMangementTable.defaultProps = {
  showOnlyRad: -1,
  curSldpID: '-1',
  curLongName: '-1',
  curSldpRequirementInfo: [
    {
      description: '-1',
      formID: -1,
      isRequired: -1,
      isTemp: '-1',
      points: '-1',
      reqID: '-1',
      sldpID: '-1',
    },
  ],
  editingRequirementID: '-1',
  editingDescription: '-1',
  editingRequired: -1,
  editingFormType: -1,
  editingExtraCredit: '-1',

  addingDescription: '-1',
  addingRequired: -1,
  addingFormType: -1,
  addingExtraCredit: '-1',
};
