import React, { Component } from 'react';
import propTypes from 'prop-types';
import { Tab, Tabs } from 'react-bootstrap';

import PinSubmission from '../../components/reportsAdministration/pinSubmission';
import ReportsOfStatus from '../../components/reportsAdministration/reportsOfStatus';
import ManagePayPeriod from '../../components/reportsAdministration/managePayPeriod';
import ManageRnDProjects from '../../components/reportsAdministration/manageRnDProjects';
import PayPeriodsDropdown from '../../components/convenientDropdowns/payPeriodsDropdown';

export default class ReportsAdministration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pinApproved: props.pinApproved,
      payPeriod: props.payPeriod,
    };
  }

  getPinApprovedStatus = (childData) => {
    this.setState({
      pinApproved: childData,
    });
  };

  updatePayPeriod = (id) => {
    this.setState({
      payPeriod: id.toString(),
    });

    console.log('Cur pay period: ', id);
  };

  render() {
    const curState = this.state;
    const ret = [];
    if (curState.pinApproved) {
      ret.push(
        <div>
          <span>Select pay period:</span>
          <PayPeriodsDropdown parentCallback={this.updatePayPeriod} />
        </div>,
      );
      ret.push(
        <Tabs defaultActiveKey="pending" id="report-status-organization">
          <Tab eventKey="pending" title="Pending">
            <ReportsOfStatus payPeriod={curState.payPeriod} status="pending" />
          </Tab>
          <Tab eventKey="approved" title="Approved">
            <ReportsOfStatus payPeriod={curState.payPeriod} status="approved" />
          </Tab>
          <Tab eventKey="denied" title="Denied">
            <ReportsOfStatus payPeriod={curState.payPeriod} status="denied" />
          </Tab>
          <Tab eventKey="manage-payperiods" title="Manage Pay Periods">
            <ManagePayPeriod />
          </Tab>
          <Tab eventKey="manage-rnd-projects" title="Manage R&D Projects">
            <ManageRnDProjects />
          </Tab>
        </Tabs>,
      );
    } else ret.push(<PinSubmission parentCallback={this.getPinApprovedStatus} />);
    return ret;
  }
}

ReportsAdministration.propTypes = {
  pinApproved: propTypes.bool,
  payPeriod: propTypes.number,
};

ReportsAdministration.defaultProps = {
  pinApproved: 0,
  payPeriod: '-1',
};
