import axios from 'axios';
import propTypes from 'prop-types';
import React, { Component } from 'react';
import { Form, Row, Col } from 'react-bootstrap';

const HOST_NAME = 'https://storage.googleapis.com';
const BUCKET_NAME = '/egsitenew-experimental';
const LECTURE_DIR = '/lectures/';

export default class DisplayLectures extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lectures: props.lectures,
    };
  }

  componentDidMount() {
    axios.get('/lectures').then((res) => {
      this.setState({
        lectures: res.data,
      });
    });
  }

  render() {
    const curState = this.state;
    const AllLectures = curState.lectures;
    const lecturesForm = AllLectures.map((lecture) => {
      const lecturePath = HOST_NAME + BUCKET_NAME + LECTURE_DIR + lecture.filepath;
      return (
        <Row style={{ fontSize: '18px' }}>
          <Col>
            <p>{lecture.name}</p>
          </Col>
          {lecture.filepath && (
            <Col>
              <a href={lecturePath}>View Lecture Slides</a>
            </Col>
          )}
          {!lecture.filepath && (
            <Col>
              <p>Lecture Sides Not Avaliable</p>
            </Col>
          )}
        </Row>
      );
    });
    console.log('lectures', curState);
    return (
      <>
        <h3 className="display-6 mb-3">Lectures</h3>
        <p className="text-muted">Lectures sides will be posted here after each lecture.</p>
        <Form>{lecturesForm}</Form>
      </>
    );
  }
}

DisplayLectures.propTypes = {
  lectures: propTypes.arrayOf(
    propTypes.shape({
      name: propTypes.string,
      id: propTypes.number,
      ordering: propTypes.number,
      date: propTypes.string,
      filepath: propTypes.string,
    }),
  ),
};

DisplayLectures.defaultProps = {
  lectures: [
    {
      name: '-1',
      id: -1,
      ordering: -1,
      date: '-1',
      filepath: '-1',
    },
  ],
};
