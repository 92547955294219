import React, { Component } from 'react';
import propTypes from 'prop-types';
import axios from 'axios';
import { Form } from 'react-bootstrap';

export default class ItemsDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: props.items,
    };
    this.updateCurSelection = this.updateCurSelection.bind(this);
  }

  componentDidMount() {
    axios.get('/inventory-item/').then((res) => {
      console.log('response for items dropdown', res);
      this.setState({
        items: res.data,
      });
    });
  }

  updateCurSelection(e) {
    const { props } = this;
    props.parentCallback(e.target.value);
  }

  render() {
    const { props } = this;
    const curState = this.state;
    const allItems = curState.items;
    const selectOptions = allItems.map((item) => (
      <option id={item.itemID} key={item.itemID} value={item.itemID}>
        {item.itemName}
      </option>
    ));

    return (
      <div>
        <Form.Control value={props.default} as="select" onChange={this.updateCurSelection}>
          <option value="-1">---Select Item---</option>
          {selectOptions}
        </Form.Control>
      </div>
    );
  }
}

ItemsDropdown.propTypes = {
  items: propTypes.arrayOf(
    propTypes.shape({
      itemID: propTypes.number,
      itemName: propTypes.string,
      cost: propTypes.number,
      overallStock: propTypes.number,
      inUse: propTypes.number,
      unit: propTypes.string,
    }),
  ),
  parentCallback: propTypes.func,
  default: propTypes.string,
};

ItemsDropdown.defaultProps = {
  items: [
    {
      itemID: -1,
      itemName: '-1',
      cost: -1,
      overallStock: -1,
      inUse: -1,
      unit: '-1',
    },
  ],
  default: '-1',
  parentCallback: () => {
    console.log('forgot parent callback for itemDropdown');
  },
};
