import React, { Component } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import SyllabusTable from '../../components/syllabus/syllabusTable';
import RadAndOLSchedule from '../../components/syllabus/radAndOlSchedule';

export default class Syllabus extends Component {
  render() {
    return (
      <>
        <Tabs defaultActiveKey="syllabusTable" id="syllabus-items-tabs">
          <Tab
            eventKey="syllabusTable"
            title="Syllabus &amp; Schedules by Section"
            style={{ 'margin-top': '70px' }}
          >
            <SyllabusTable />
          </Tab>
          <Tab
            eventKey="openLabSchedule"
            title="RAD &amp; Open Lab Schedules"
            style={{ 'margin-top': '70px' }}
          >
            <RadAndOLSchedule />
          </Tab>
        </Tabs>
      </>
    );
  }
}
