import React, { Component } from 'react';
import { Button, Form, Col, Row, Modal, Alert } from 'react-bootstrap';
import propTypes from 'prop-types';

import './newReportPinForm.scss';
import SubmitReportForm from './submitReportForm';

export default class NewReportPinForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isWritingNewReport: props.isWritingNewReport,
      pin: props.pin,
      numOfTas: props.numOfTas,
      showCancelModal: props.showCancelModal, // warning that pops up when user cancels writing a report

      successSubmittingReport: props.successSubmittingReport,
      errorSubmittingReport: props.errorSubmittingReport,
    };

    this.updatePin = this.updatePin.bind(this);
    this.updateNumOfTas = this.updateNumOfTas.bind(this);
    this.toggleIsWritingNewReport = this.toggleIsWritingNewReport.bind(this);
    this.toggleCancelModal = this.toggleCancelModal.bind(this);
    this.toggleSuccessSubmittingReport = this.toggleSuccessSubmittingReport.bind(this);
    this.toggleErrorSubmittingReport = this.toggleErrorSubmittingReport.bind(this);
  }

  toggleCancelModal() {
    this.setState((prevState) => ({
      showCancelModal: !prevState.showCancelModal,
    }));
  }

  toggleSuccessSubmittingReport() {
    this.setState((prevState) => ({
      successSubmittingReport: !prevState.successSubmittingReport,
    }));
  }

  toggleErrorSubmittingReport() {
    this.setState((prevState) => ({
      errorSubmittingReport: !prevState.errorSubmittingReport,
    }));
  }

  updatePin(e) {
    this.setState({
      pin: e.target.value,
    });
    const { pin } = this.state;
    console.log('pin: ', pin);
    // TODO: incorporate MD5 hash to check the pin in the DB. need to know how to get the current logged in use's ID before doing this.
  }

  updateNumOfTas(e) {
    this.setState({
      numOfTas: e.target.value,
    });
    console.log('num of tas: ', e.target.value);
  }

  toggleIsWritingNewReport() {
    const { props } = this;
    const { isWritingNewReport } = this.state;
    if (isWritingNewReport) {
      // need to split the setState calls like this so we can reset pin and num of tas
      this.setState({
        isWritingNewReport: false,
        numOfTas: props.numOfTas,
        pin: props.pin,
      });
    } else {
      this.setState({
        isWritingNewReport: true,
      });
    }
    props.parentCallback();
  }

  reportSubmitted(wasSubmissionSuccesful) {
    // called fom SubmitReportForm component after a report was created/updated

    if (wasSubmissionSuccesful) this.toggleSuccessSubmittingReport();
    else this.toggleErrorSubmittingReport();
    this.toggleIsWritingNewReport();
  }

  render() {
    const {
      isWritingNewReport,
      showCancelModal,
      numOfTas,
      successSubmittingReport,
      errorSubmittingReport,
    } = this.state;
    const { userInfo } = this.props;
    return (
      <div className="new-report">
        <Modal show={showCancelModal} onHide={this.toggleCancelModal} animation>
          <Modal.Header closeButton>
            <Modal.Title>Are you sure you want to cancel?</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>All information you entered for this report will be lost. This is irreversible.</p>
          </Modal.Body>

          <Modal.Footer>
            <Button onClick={this.toggleCancelModal} variant="primary">
              Keep Writing
            </Button>
            <Button
              onClick={() => {
                this.toggleIsWritingNewReport();
                this.toggleCancelModal();
              }}
              variant="secondary"
            >
              Delete{' '}
            </Button>
          </Modal.Footer>
        </Modal>
        {!isWritingNewReport && (
          <p>
            To start writing a new report, first insert your pin and the number of TAs below and
            then hit &quot;Begin&quot;.{' '}
          </p>
        )}
        <Form>
          {!isWritingNewReport && (
            <Row>
              <Col xs={2}>
                <Form.Label>
                  <b>Pin</b>
                </Form.Label>
                <Form.Control type="password" placeholder="Pin" onChange={this.updatePin} />
              </Col>
              <Col xs={2}>
                <Form.Label>
                  <b>Number of TAs</b>
                </Form.Label>
                <Form.Control defaultValue="1" onChange={this.updateNumOfTas} />
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <Button
                variant="primary"
                onClick={
                  isWritingNewReport ? this.toggleCancelModal : this.toggleIsWritingNewReport
                }
              >
                {isWritingNewReport ? 'Cancel' : 'Begin'}
              </Button>
              {successSubmittingReport && (
                <Alert variant="success" onClose={this.toggleSuccessSubmittingReport} dismissible>
                  Report was successfully submitted.
                </Alert>
              )}
              {errorSubmittingReport && (
                <Alert variant="danger" onClose={this.toggleErrorSubmittingReport} dismissible>
                  Error! Contact webdev.
                </Alert>
              )}
            </Col>
          </Row>
        </Form>
        {isWritingNewReport && (
          <SubmitReportForm
            userID={userInfo.userID}
            numOfTas={numOfTas >= 1 ? Math.trunc(numOfTas) : 1}
            parentCallback={(wasSubmissionSuccessful) =>
              this.reportSubmitted(wasSubmissionSuccessful)
            }
          />
        )}
      </div>
    );
  }
}

NewReportPinForm.propTypes = {
  userInfo: propTypes.shape({
    approved: propTypes.number,
    barcode: propTypes.string,
    firstName: propTypes.string,
    groupID: propTypes.number,
    idNum: propTypes.string,
    joinDate: propTypes.string,
    lastName: propTypes.string,
    quit: propTypes.number,
    report_pin: propTypes.string,
    sectionID: propTypes.number,
    userEmail: propTypes.string,
    userID: propTypes.number,
    worktypeID: propTypes.number,
  }),
  isWritingNewReport: propTypes.bool,
  pin: propTypes.number,
  numOfTas: propTypes.number,
  showCancelModal: propTypes.bool,
  parentCallback: propTypes.func,

  successSubmittingReport: false,
  errorSubmittingReport: false,
};

NewReportPinForm.defaultProps = {
  userInfo: {
    approved: -1,
    barcode: '-1',
    firstName: '-1',
    groupID: -1,
    idNum: '-1',
    joinDate: '-1',
    lastName: '-1',
    quit: -1,
    report_pin: '-1',
    sectionID: -1,
    userEmail: '-1',
    userID: -1,
    worktypeID: -1,
  },
  isWritingNewReport: false,
  pin: -1,
  numOfTas: 1,
  showCancelModal: false,
  parentCallback: () => {},

  successSubmittingReport: false,
  errorSubmittingReport: false,
};
