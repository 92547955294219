import axios from 'axios';
import propTypes from 'prop-types';
import React, { Component } from 'react';
import { Table } from 'react-bootstrap';

export default class DisplayStudentSubmission extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submissions: props.submissions, // All available submissions
      submissionTypes: props.submissionTypes, // All submission file types
    };
  }

  componentDidMount() {
    axios.get('/submission-info/').then((res) => {
      this.setState({
        submissions: res.data,
      });
    });
    axios.get('/submission-type').then((res) => {
      this.setState({
        submissionTypes: res.data,
      });
    });
  }

  renderSubmissionHead = (submissionTypes) => {
    const heads = submissionTypes.map((type) => <th> {type} </th>);
    return (
      <tr>
        <th> # </th>
        {heads}
        <th> TA </th>
        <th> WC </th>
      </tr>
    );
  };

  renderFileTypes = (subId, fileTypes, fileTypesDict) => {
    const tds = fileTypes.map((type) => {
      if (fileTypesDict[type].includes(subId)) {
        return <td key={`${subId}-${type}`} />;
      }
      return <td key={`${subId}-${type}`}> -- </td>;
    });
    return tds;
  };

  renderSubmissionRow = (submissions, fileTypes, fileTypesDict) => {
    let i = -1;
    const trs = submissions.map((submission) => {
      i += 1;
      return (
        <tr key={i}>
          <td key={`${submission.id}-name`}> {submission.name} </td>
          {this.renderFileTypes(submission.id, fileTypes, fileTypesDict)}
          <td key={`${submission.id}-TA`} />
          <td key={`${submission.id}-WC`} />
        </tr>
      );
    });
    return trs;
  };

  render() {
    const { props } = this;
    const curState = this.state;
    const allSubmissions = curState.submissions;
    const curFileTypes = curState.submissionTypes;
    const fileTypes = new Set();
    const fileTypesDict = {};
    let j;
    for (j = 0; j < curFileTypes.length; j += 1) {
      const { ext } = curFileTypes[j];
      const { subId } = curFileTypes[j];
      fileTypes.add(ext);
      if (!fileTypesDict[ext]) {
        fileTypesDict[ext] = [subId];
      } else {
        fileTypesDict[ext].push(subId);
      }
    }

    if (props.status === 'OPEN') {
      return (
        <>
          <p className="text-muted">All your submitted assignments and graded lab reports.</p>
          <Table responsive striped bordered hover>
            <thead>
              <tr className="header-row">
                <th colSpan="6">Submission</th>
                <th colSpan="2">Graded Reports</th>
              </tr>
            </thead>
            <tbody>
              {this.renderSubmissionHead(Array.from(fileTypes))}
              {this.renderSubmissionRow(allSubmissions, Array.from(fileTypes), fileTypesDict)}
            </tbody>
          </Table>
        </>
      );
    }

    if (props.status === 'WAITING') {
      return <p>This is an unfinished page, webdev needs to update it (status: waiting).</p>;
    }

    return <p>This is an unfinished page, webdev needs to update it.</p>;
  }
}

DisplayStudentSubmission.propTypes = {
  status: propTypes.string,
  submissions: propTypes.arrayOf(
    propTypes.shape({
      id: propTypes.number,
      shortName: propTypes.string,
      name: propTypes.string,
    }),
  ),
  submissionTypes: propTypes.arrayOf(
    propTypes.shape({
      uid: propTypes.number,
      subId: propTypes.number,
      ext: propTypes.string,
    }),
  ),
};

DisplayStudentSubmission.defaultProps = {
  status: '-1',
  submissions: [
    {
      id: -1,
      shortName: '-1',
      name: '-1',
    },
  ],
  submissionTypes: [
    {
      uid: -1,
      subId: -1,
      ext: '-1',
    },
  ],
};
