import React, { Component } from 'react';
import propTypes from 'prop-types';
import axios from 'axios';
import { Form } from 'react-bootstrap';

export default class WorktypeDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      worktypes: props.worktypes,
    };
    this.updateCurSelection = this.updateCurSelection.bind(this);
  }

  componentDidMount() {
    axios.get('/worktypes/').then((res) => {
      console.log('response', res);
      this.setState({
        worktypes: res.data,
      });
    });
  }

  updateCurSelection(e) {
    const { props } = this;
    props.parentCallback(e.target.value);
  }

  render() {
    const { props } = this;
    const curState = this.state;
    const allWorktypes = curState.worktypes;
    let selectOptions;
    if (props.limited) {
      // only labs, rec, open labs, lecture, and protolab. Used for replacement requests.
      selectOptions = allWorktypes.map((worktype) => {
        if (
          worktype.id === 4 ||
          worktype.id === 5 ||
          worktype.id === 9 ||
          worktype.id === 12 ||
          worktype.id === 22
        ) {
          return (
            <option id={worktype.id} key={worktype.id} value={worktype.id}>
              {worktype.workType}
            </option>
          );
        }
        return null;
      });
    } else {
      selectOptions = allWorktypes.map((worktype) => (
        <option id={worktype.id} key={worktype.id} value={worktype.id}>
          {worktype.workType}
        </option>
      ));
    }

    return (
      <div>
        <Form.Control
          value={props.default}
          as="select"
          onChange={this.updateCurSelection}
          disabled={props.isDisabled}
        >
          <option value="-1">---Select Worktype---</option>
          {selectOptions}
        </Form.Control>
      </div>
    );
  }
}

WorktypeDropdown.propTypes = {
  worktypes: propTypes.arrayOf(
    propTypes.shape({
      id: propTypes.number,
      workType: propTypes.string,
      isAdminType: propTypes.number,
    }),
  ),
  parentCallback: propTypes.func,
  limited: propTypes.bool,
  default: propTypes.string,
  isDisabled: propTypes.bool,
};

WorktypeDropdown.defaultProps = {
  worktypes: [
    {
      id: -1,
      workType: '-1',
      isAdminType: '-1',
    },
  ],
  parentCallback: () => {},
  limited: false,
  default: '-1',
  isDisabled: false,
};
