import React, { Component } from 'react';
import DisplayLectures from '../../components/lectures/displayLectures';

export default class Lectures extends Component {
  render() {
    return (
      <>
        <DisplayLectures />
      </>
    );
  }
}
