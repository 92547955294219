const moment = require('moment-timezone'); // used to convert between timezones

export default function datePickerToUtcISO(date, utc = true, time = false) {
  const dateMapper = {
    Jan: '01',
    Feb: '02',
    Mar: '03',
    Apr: '04',
    May: '05',
    Jun: '06',
    Jul: '07',
    Aug: '08',
    Sep: '09',
    Oct: '10',
    Nov: '11',
    Dec: '12',
  };
  // input example: Fri Jun 26 2020 12:51:27 GMT-0400 (Eastern Daylight Time)
  const dateStr = date.toString();

  const month = dateMapper[dateStr.slice(4, 7)];
  const day = dateStr.slice(8, 10);
  const year = dateStr.slice(11, 15);

  if (time) {
    const hour = dateStr.slice(16, 18);
    const minute = dateStr.slice(19, 21);
    const second = '00'; // default seconds hardcoded for now

    let ret = moment.tz(`${year}-${month}-${day}T${hour}:${minute}:${second}`, 'America/New_York');
    if (utc) {
      ret = ret.utc().format();
    }

    return ret;
  }
  return `${year}-${month}-${day}`;
}

// Jun 18 2020
