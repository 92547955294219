import React, { Component } from 'react';
import propTypes from 'prop-types';
import axios from 'axios';
import { Form } from 'react-bootstrap';

export default class SldpKitDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      kits: props.kits,
      curSelection: props.default,
    };
    this.updateCurSelection = this.updateCurSelection.bind(this);
  }

  componentDidMount() {
    const { props } = this;
    axios.get('/sldp-kits/'.concat(props.sldpID)).then((res) => {
      this.setState({
        kits: res.data,
      });
    });
  }

  updateCurSelection(e) {
    const { props } = this;
    this.setState({
      curSelection: e.target.value,
    });
    props.parentCallback(e.target.value);
  }

  render() {
    const curState = this.state;
    const { props } = this;
    const allKits = curState.kits;
    const selectOptions = allKits.map((kit) => {
      if ((props.availableOnly && !kit.isCheckedOut) || !props.availableOnly) {
        // if we only want available kits
        return (
          <option id={kit.id} key={kit.id} value={kit.id}>
            {kit.kitNumber}
          </option>
        );
      }
      return null;
    });

    return (
      <div>
        <Form.Control value={curState.curSelection} as="select" onChange={this.updateCurSelection}>
          <option value="-1">---Select Kit---</option>
          {selectOptions}
        </Form.Control>
      </div>
    );
  }
}

SldpKitDropdown.propTypes = {
  kits: propTypes.arrayOf(
    propTypes.shape({
      id: propTypes.number,
      sldpID: propTypes.number,
      kitNumber: propTypes.number,
      isCheckedOut: propTypes.number,
      groupID: propTypes.number,
    }),
  ),
  default: propTypes.string,
  availableOnly: propTypes.bool,
  parentCallback: propTypes.func,
  sldpID: propTypes.string,
};

SldpKitDropdown.defaultProps = {
  kits: [
    {
      id: -1,
      sldpID: -1,
      kitNumber: -1,
      isCheckedOut: -1,
      groupID: -1,
    },
  ],
  default: '-1',
  availableOnly: false,
  parentCallback: () => {
    console.log('forgot to give a parent callback for SldpKitDropdown');
  },
  sldpID: '-1',
};
