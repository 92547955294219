import React, { Component } from 'react';
import propTypes from 'prop-types';
import axios from 'axios';
import { Form } from 'react-bootstrap';

export default class SldpDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sldps: props.sldps,
    };
    this.updateCurSelection = this.updateCurSelection.bind(this);
  }

  componentDidMount() {
    axios.get('/sldp-active').then((res) => {
      this.setState({
        sldps: res.data,
      });
    });
  }

  updateCurSelection(e) {
    const { props } = this;
    props.parentCallback(e.target.value);
  }

  render() {
    const { props } = this;
    const curState = this.state;
    const allSldps = curState.sldps;
    const selectOptions = allSldps.map((sldp) => (
      <option id={sldp.sldpID} key={sldp.sldpID} value={sldp.sldpID}>
        {sldp.shortName}
      </option>
    ));

    return (
      <div>
        <Form.Control value={props.default} as="select" onChange={this.updateCurSelection}>
          <option value="-1">---Select SLDP---</option>
          {selectOptions}
        </Form.Control>
      </div>
    );
  }
}

SldpDropdown.propTypes = {
  sldps: propTypes.arrayOf(
    propTypes.shape({
      sldpID: propTypes.number,
      shortName: propTypes.string,
      longName: propTypes.string,
      isActive: propTypes.number,
    }),
  ),
  parentCallback: propTypes.func,
  default: propTypes.string,
};

SldpDropdown.defaultProps = {
  sldps: [],
  parentCallback: () => {},
  default: '-1',
};
