import React, { Component } from 'react';
import SldpMangementTable from '../../components/sldpManagement/sldpManagement';

export default class RadManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showOnlyRad: 1,
    };
  }

  render() {
    const curState = this.state;
    return (
      <div>
        <h3 className="display-6 mb-3">RAD Management</h3>
        <SldpMangementTable showOnlyRad={curState.showOnlyRad} />
      </div>
    );
  }
}
