import axios from 'axios';
import propTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Form, Row, Col } from 'react-bootstrap';
import sqlToReadable from '../../../utils/sqlToReadableDate';

const HOST_NAME = 'https://storage.googleapis.com';
const BUCKET_NAME = 'egsitenew-experimental';
const SYLLABI_DIR = 'syllabi';

export default class SyllabusTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      syllabi: props.syllabi,
    };
  }

  componentDidMount() {
    axios.get('/syllabus').then((res) => {
      console.log('Syllabus items:', res);
      this.setState({
        syllabi: res.data,
      });
    });
  }

  render() {
    const curState = this.state;
    const scheduleForm = curState.syllabi.map((syllabus) => {
      const syllabusPath = `${HOST_NAME}/${BUCKET_NAME}/${SYLLABI_DIR}/${syllabus.filepath}`;
      return (
        <Row style={{ fontSize: '18px' }}>
          <Col>
            <p>
              <b>{syllabus.name}</b>
            </p>
          </Col>
          <Col>
            <a href={syllabusPath}>View {syllabus.name} Schedule</a>
          </Col>
          <Col>
            <p className="small text-muted">Date Updated: {sqlToReadable(syllabus.updated)}</p>
          </Col>
        </Row>
      );
    });
    return (
      <>
        <h4 className="display-6 mb-3">Syllabus</h4>
        <div className="d-grid gap-2 mb-4" style={{ maxWidth: '340px', margin: '0 auto 10px' }}>
          <Button
            href="https://docs.google.com/document/d/1a1MSIiIpc8oDM_V0qH4M26D42yTh16s71tl1Gfc3d3g/export?format=pdf"
            variant="primary"
            block
          >
            Download Course Syllabus
          </Button>
        </div>
        <hr />
        <h4 className="display-6 mt-3 mb-3">Schedules</h4>
        <p className="small text-muted">
          Schedules are in Adobe Portable Document Format (PDF). To save to your computer,
          right-click and choose &quot;Save Link As...&quot; or &quot;Save Target As&quot;.
        </p>
        <Form>{scheduleForm}</Form>
      </>
    );
  }
}

SyllabusTable.propTypes = {
  syllabi: propTypes.arrayOf(
    propTypes.shape({
      id: propTypes.number,
      name: propTypes.string,
      type: propTypes.number,
      filepath: propTypes.string,
      active: propTypes.number,
      updated: propTypes.string,
    }),
  ),
};

SyllabusTable.defaultProps = {
  syllabi: [
    {
      id: -1,
      name: '-1',
      type: -1,
      filepath: '-1',
      active: -1,
      updated: '-1',
    },
  ],
};
