import React, { Component } from 'react';
import AttendanceLecture from '../../components/lectures/attendanceLectures';
import UploadLectureSlides from '../../components/lectures/uploadLectureSlides';
import LecturesManagement from '../../components/lectures/manageLectures';

export default class LecturesAdministration extends Component {
  render() {
    return (
      <>
        <h4 className="display-6 mb-3">Lectures Management</h4>
        <LecturesManagement />
        <h5 className="display-6 mb-3">Upload New Lecture Slide</h5>
        <UploadLectureSlides />
        <h4 className="display-6 mb-3 mt-3">Lectures Attendance</h4>
        <AttendanceLecture />
      </>
    );
  }
}
