// import axios from 'axios';
import propTypes from 'prop-types';
import React, { Component } from 'react';
import SectionsDropdown from '../convenientDropdowns/sectionsDropdown';

export default class DisplayLabDocuments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      curSection: props.curSection,
      // curLab: props.curLab,
    };
  }

  getCurSection = (childData) => {
    this.setState({
      curSection: childData,
    });
    console.log('current lab set to', childData);
  };

  render() {
    const curState = this.state;
    return (
      <>
        <SectionsDropdown
          default={curState.curSection}
          limited
          parentCallback={this.getCurSection}
        />
        <p>This is a list of lab Documents.</p>
      </>
    );
  }
}

DisplayLabDocuments.propTypes = {
  curSection: propTypes.string,
  // curLab: propTypes.string,
};

DisplayLabDocuments.defaultProps = {
  curSection: '-1',
  // curLab: '-1',
};
