import React, { Component } from 'react';
import propTypes from 'prop-types';
import axios from 'axios';
import { Form } from 'react-bootstrap';

export default class RnDProjectsDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      projects: props.projects,
    };
    this.updateCurSelection = this.updateCurSelection.bind(this);
  }

  componentDidMount() {
    axios.get('/rnd-projects/').then((res) => {
      console.log('response', res);
      this.setState({
        projects: res.data,
      });
    });
  }

  updateCurSelection(e) {
    const { props } = this;
    props.parentCallback(e.target.value);
  }

  render() {
    const { props } = this;
    const curState = this.state;
    const allProjects = curState.projects;
    const selectOptions = allProjects.map((project) => (
      <option id={project.proj_ID} key={project.proj_ID} value={project.proj_ID}>
        {project.Project_name}
      </option>
    ));

    return (
      <div>
        <Form.Control value={props.default} as="select" onChange={this.updateCurSelection}>
          <option value="-1">---Select Project---</option>
          {selectOptions}
          <option value={0}>N/A</option>
        </Form.Control>
      </div>
    );
  }
}

RnDProjectsDropdown.propTypes = {
  projects: propTypes.arrayOf(
    propTypes.shape({
      proj_ID: propTypes.number,
      Project_name: propTypes.string,
    }),
  ),
  parentCallback: propTypes.func,
  default: propTypes.string,
};

RnDProjectsDropdown.defaultProps = {
  projects: [],
  parentCallback: () => {},
  default: '-1',
};
