import React, { Component } from 'react';

import ReplacementRequest from '../../components/replacements/replacementRequest';
import ViewReplacementRequests from '../../components/replacements/viewReplacementRequests';
import ViewRequestsPending from '../../components/replacements/ViewRequestsPending';
import ViewRequestsToFillRequests from '../../components/replacements/viewRequestsToFillRequests';

export default class Replacements extends Component {
  render() {
    return (
      <div>
        <h4 className="display-6">Request a Replacement</h4>
        <ReplacementRequest />
        <hr />
        <h4 className="display-6">Active Replacement Requests</h4>
        <ViewReplacementRequests status="OPEN" />
        <hr />
        {/* This will only be shown to admin */}
        <h4 className="display-6">Pending Replacement Requests</h4>{' '}
        <ViewRequestsPending status="OPEN" />
        <hr />
        {/* This will only be shown to admin */}
        <h4 className="display-6">Filled Requests Waiting for Approval</h4>
        <ViewRequestsToFillRequests />
      </div>
    );
  }
}
