import React, { Component } from 'react';
import PayrollPage from '../../components/payroll/payrollPage';

export default class Payroll extends Component {
  render() {
    return (
      <>
        <PayrollPage />
      </>
    );
  }
}
