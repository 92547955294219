import React, { Component } from 'react';
import propTypes from 'prop-types';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export default class DateSelector extends Component {
  componentDidMount() {
    const { props } = this;
    const date = props.default;
    props.parentCallback(date);
  }

  handleChange = (date) => {
    const { props } = this;
    props.parentCallback(date);
  };

  render() {
    const { props } = this;
    if (props.showTimeSelect) {
      // used for pay period management
      return (
        <DatePicker
          selected={props.default}
          onChange={this.handleChange}
          timeInputLabel="Time:"
          dateFormat="MM/dd/yyyy hh:mm aa"
          showTimeInput
        />
      );
    }
    if (props.isDisabled) {
      return <p>{props.default.toString()}</p>;
    }
    return (
      <DatePicker selected={props.default} onChange={this.handleChange} dateFormat={props.format} />
    );
  }
}

DateSelector.propTypes = {
  default: propTypes.instanceOf(Date),
  parentCallback: propTypes.func,
  showTimeSelect: propTypes.bool,
  format: propTypes.string,
  isDisabled: propTypes.bool,
};

DateSelector.defaultProps = {
  default: new Date(),
  parentCallback: () => {},
  showTimeSelect: false,
  format: 'MM/dd/yyyy',
  isDisabled: false,
};
