/* eslint-disable react/no-unused-state */
// TODO: update this page so pin works and then remove the linter disabling above
import React, { Component } from 'react';
import { Card, Button, Form, Col, Row } from 'react-bootstrap';
import propTypes from 'prop-types';

export default class PinSubmission extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pin: props.pin,
    };

    this.updatePin = this.updatePin.bind(this);
    this.togglePinApproved = this.togglePinApproved.bind(this);
  }

  updatePin(e) {
    this.setState({
      pin: e.target.value,
    });
    // TODO: incorporate MD5 hash to check the pin in the DB. need to know how to get the current logged in use's ID before doing this.
  }

  togglePinApproved() {
    const { parentCallback } = this.props;
    parentCallback(1);
  }

  render() {
    return (
      <Card>
        <Card.Header>Approve Reports</Card.Header>
        <Card.Body>
          <Card.Text>
            To start approving reports, type in your pin and press &apos;begin&apos;.
          </Card.Text>
          <Form>
            <Row>
              <Col>
                <Form.Label>
                  <b>Pin</b>
                </Form.Label>
                <Form.Control placeholder="Pin" onChange={this.updatePin} />
              </Col>
            </Row>
            <Row>
              <Col>
                <Button variant="primary" onClick={this.togglePinApproved}>
                  Begin
                </Button>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    );
  }
}

PinSubmission.propTypes = {
  pin: propTypes.number,
  parentCallback: propTypes.func,
};

PinSubmission.defaultProps = {
  pin: 0,
  parentCallback: () => {},
};
