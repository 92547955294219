import React, { Component } from 'react';
import propTypes from 'prop-types';
import axios from 'axios';

import CommitteeCard from '../../components/committeeAdministration/committeeCard';

export default class CommitteeAdministration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      committees: props.committees, // list of all committees [ {committeeID, committeeName}]
      committeesMembers: props.committeesMembers, // maps committee IDs to array of members
    };
  }

  componentDidMount() {
    this.updateState();
  }

  updateState = () => {
    axios.get('/committees/').then((res) => {
      this.setState({
        committees: res.data,
      });
    });
    axios.get('committeesMembers').then((res) => {
      const committeesMembers = {};
      for (let i = 0; i < res.data.length; i += 1) {
        const curCommitteeID = res.data[i].committeeID;
        const memberName = `${res.data[i].firstName} ${res.data[i].lastName}`;
        if (curCommitteeID in committeesMembers) {
          committeesMembers[curCommitteeID].push({
            userID: res.data[i].userID,
            memberName,
            committeeID: curCommitteeID,
          });
        } else {
          committeesMembers[curCommitteeID] = [
            { userID: res.data[i].userID, memberName, committeeID: curCommitteeID },
          ];
        }
      }
      this.setState({
        committeesMembers,
      });
    });
  };

  removeMemberFromCommittee = (userID, committeeID) => {
    axios.delete(`/committeesMembers/${userID}/${committeeID}`).then(() => {
      this.updateState();
    });
  };

  addMemberToCommittee = (userID, committeeID) => {
    axios.post(`/committeesMembers/${userID}/${committeeID}`).then(() => {
      this.updateState();
    });
  };

  render() {
    const { committees, committeesMembers } = this.state;
    const committeeCards = committees.map((committee) => (
      <div>
        <CommitteeCard
          committeeID={committee.committeeID}
          committeeName={committee.committeeName}
          committeeMembers={committeesMembers[parseInt(committee.committeeID, 10)]}
          removeMemberFromCommitteeCallback={this.removeMemberFromCommittee}
          addMemberToCommitteeCallback={this.addMemberToCommittee}
        />
        <br />
      </div>
    ));
    return <div>{committeeCards}</div>;
  }
}

CommitteeAdministration.propTypes = {
  committees: propTypes.arrayOf(
    propTypes.shape({
      committeeID: propTypes.number,
      committeeName: propTypes.string,
      committeeMembers: propTypes.arrayOf(
        propTypes.shape({
          userID: propTypes.number,
          memberName: propTypes.string,
          committeeID: propTypes.number,
        }),
      ),
    }),
  ),
  committeesMembers: propTypes.shape({
    committeeID: propTypes.arrayOf(
      propTypes.shape({
        committeeID: -1,
        memberName: '-1',
        userID: -1,
      }),
    ),
  }),
};

CommitteeAdministration.defaultProps = {
  committees: [],
  committeesMembers: [],
};
