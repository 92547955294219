import axios from 'axios';
import { parseInt } from 'lodash';
import propTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Pagination, Table } from 'react-bootstrap';
import ReactExport from 'react-export-excel';
import PayPeriodsDropdown from '../convenientDropdowns/payPeriodsDropdown';

// Global Constants
const { ExcelFile } = ReactExport;
const { ExcelSheet } = ReactExport.ExcelFile.ExcelSheet;

export default class PayrollPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payPeriod: props.payPeriod,
      reports: props.reports,
      visibleReport: props.visibleReport,
      payPeriodReports: props.payPeriodReports,
      curPage: props.curPage,
      curTAs: props.curTAs,
      curWorktypes: props.curWorktypes,
      curStart: props.curStart,
      curEnd: props.curEnd,
    };
    this.updatePayPeriod = this.updatePayPeriod.bind(this);
    this.updateReportsByPage = this.updateReportsByPage.bind(this);
    this.generateUserHours = this.generateUserHours.bind(this);
    this.generateColumns = this.generateColumns.bind(this);
  }

  componentDidMount() {
    let payPeriodId;
    let start;
    let end;

    axios.get(`/payPeriod-active`).then((res) => {
      payPeriodId = res.data[0].id;
      start = res.data[0].start;
      end = res.data[0].end;
      this.setState({
        payPeriod: payPeriodId,
        curStart: start,
        curEnd: end,
      });
      axios.get(`/reportsByType/0/${payPeriodId}/1`).then((result1) => {
        this.setState({
          reports: result1.data,
        });
      });
      axios.get(`/report-form-data/${start}/${end}`).then((result2) => {
        this.setState({
          payPeriodReports: result2.data,
        });
      });
    });

    axios.get('/userGroup/2').then((res) => {
      this.setState({
        curTAs: res.data,
      });
    });

    axios.get('/worktypes/').then((res) => {
      this.setState({
        curWorktypes: res.data,
      });
    });
  }

  getHours = (timein, timeout) => {
    let curHours = 0;
    const timeinHours = parseInt(timein.slice(-5).slice(0, 2));
    const timeinMinutes = parseInt(timein.slice(-2));
    const timeoutHours = parseInt(timeout.slice(-5).slice(0, 2));
    const timeOutMinutes = parseInt(timeout.slice(-2));
    curHours = timeoutHours - timeinHours + (timeinMinutes - timeOutMinutes) / 30;
    return curHours;
  };

  updatePageTo = (page) => {
    this.setState({
      curPage: page,
    });
    this.updateReportsByPage(page.toString());
  };

  updateReportsByPage(page) {
    const curState = this.state;
    axios.get(`/reportsByType/0/${curState.payPeriod}/${page}`).then((res) => {
      this.setState({
        reports: res.data,
      });
    });
  }

  updatePayPeriod(id) {
    let start;
    let end;
    this.setState({
      payPeriod: id.toString(),
    });

    axios.get(`/reportsByType/0/${id}/1`).then((res) => {
      this.setState({
        reports: res.data,
        payPeriod: id,
        curPage: '1',
      });
    });

    axios.get(`/payPeriod/${id}`).then((res) => {
      start = res.data[0].start;
      end = res.data[0].end;
      this.setState({
        curStart: start,
        curEnd: end,
      });
      axios.get(`/report-form-data/${start}/${end}`).then((result) => {
        this.setState({
          payPeriodReports: result.data,
        });
      });
    });
  }

  generateUserHours() {
    const curState = this.state;
    const reports = curState.payPeriodReports;
    const usersDict = {};
    if (reports) {
      reports.forEach((report) => {
        const userId = report.userID;
        const workType = report.worktype;
        if (!usersDict[userId]) {
          usersDict[userId] = {};
        }
        if (!usersDict[userId][workType]) {
          usersDict[userId][workType] = this.getHours(report.timein, report.timeout);
        } else {
          usersDict[userId][workType] += this.getHours(report.timein, report.timeout);
        }
      });
    }
    return usersDict;
  }

  generateColumns() {
    const hoursDict = this.generateUserHours();
    const curState = this.state;
    const TAs = curState.curTAs;
    const workTypes = curState.curWorktypes;
    const columnsData = ['First Name', 'Last Name'];
    const formData = [];
    const userIDs = [];
    const workIDs = [];

    // 1. Put first and last names of the active TAs in the formData and their IDs in userIDs.
    TAs.forEach((element) => {
      const TA = [element.firstName, element.lastName];
      formData.push(TA);
      userIDs.push(element.userID);
    });

    // 2. Put all current worktypes in columnsData and their IDs in workIDs.
    workTypes.forEach((element) => {
      columnsData.push(element.workType);
      workIDs.push(element.id);
    });
    columnsData.push('Total');

    // 3. Calculate hours for each worktype and total hours for a pay period.
    Object.keys(userIDs).forEach((i) => {
      let totalHours = 0;
      Object.keys(workIDs).forEach((j) => {
        let curHours = 0;
        if (hoursDict[userIDs[i]]) {
          if (hoursDict[userIDs[i]][workIDs[j]]) {
            curHours = hoursDict[userIDs[i]][workIDs[j]];
          }
          if (curHours) {
            totalHours += curHours;
          }
        }
        if (curHours !== 0) {
          formData[i].push(curHours);
        } else {
          formData[i].push(undefined);
        }
      });
      if (totalHours !== 0) {
        formData[i].push(totalHours);
      }
    });

    const content = [
      {
        columns: columnsData,
        data: formData,
      },
    ];
    return content;
  }

  render() {
    const curState = this.state;
    const curPayPeriod = curState.payPeriod;
    const start = curState.curStart;
    const end = curState.curEnd;
    const content = this.generateColumns();
    const reportName = `EGReport_${curPayPeriod}_${start.slice(0, 10)}_${end.slice(0, 10)}`;
    const allReports = curState.reports;
    const trs = allReports.map((
      report, // TODO: Come back to this after pagination is fixed
    ) => (
      <tr key={report.id} id={report.id}>
        <td key={report.id + report.reporter} id={report.id + report.reporter}>
          {`${report.firstName} ${report.lastName}`}
        </td>
        <td key={report.id + report.workType} id={report.id + report.workType}>
          {report.workType}
        </td>
        <td key={report.id - report.submiteDate} id={report.id + report.submitDate}>
          {report.submitDate.slice(0, 10)}
        </td>
      </tr>
    ));

    const paginationComponent = [];

    const numOfReports = curState.reports.length;
    if (numOfReports > 10) {
      for (let i = 1; i <= Math.floor(numOfReports / 10) + 1; i += 1) {
        paginationComponent.push(
          <Pagination.Item
            active={curState.curPage === i}
            id={`pagcomponent ${i}`}
            key={`pagcomponent ${i}`}
            onClick={(() => () => {
              this.updatePageTo(i);
            })(i)}
          >
            {i}
          </Pagination.Item>,
        ); // the weird onclick function is needed to preserve the state of i upon Pagination.Item creation, otherwise i will always equal whatever it was last for every button.
      }
    }

    return (
      <>
        <PayPeriodsDropdown className="mb-3" parentCallback={this.updatePayPeriod} />
        <br />
        <ExcelFile
          element={
            <Button varient="primary" style={{ float: 'right' }}>
              Download Excel Sheet
            </Button>
          }
          filename={reportName}
        >
          <ExcelSheet dataSet={content} name={reportName} />
        </ExcelFile>
        <p className="text-muted">
          Download spreadsheet for all approved hours in <b>{curPayPeriod}</b> pay period.
        </p>

        <div>
          {curState.visibleReport.length === 0 && (
            <div>
              {numOfReports > 1 && numOfReports < 100 && (
                <p>
                  There are <b>{numOfReports.toString()}</b> pending reports for the selected pay
                  period.
                </p>
              )}
              {numOfReports > 100 && (
                <p>
                  There are <b>{numOfReports.toString()}</b> pending reports for the selected pay
                  period.
                </p>
              )}
              {numOfReports === 1 && (
                <p>
                  There is <b>{numOfReports.toString()}</b> pending report for the selected pay
                  period.
                </p>
              )}
              {numOfReports > 0 && (
                <div>
                  <Table bordered responsive hover>
                    <thead>
                      <tr>
                        <th>Reporter</th>
                        <th>Work Type</th>
                        <th>Date Submitted</th>
                      </tr>
                    </thead>
                    <tbody>{trs}</tbody>
                  </Table>
                  <div className="pagination-tabs">
                    <Pagination>{paginationComponent}</Pagination>
                  </div>
                </div>
              )}
            </div>
          )}
          {numOfReports > 0 && curState.visibleReport}
          {numOfReports === 0 && (
            <p>
              <b>There are no pending reports to show for this pay period.</b>
            </p>
          )}
        </div>
      </>
    );
  }
}

PayrollPage.propTypes = {
  curTAs: propTypes.arrayOf(
    propTypes.shape({
      userID: propTypes.number,
      groupID: propTypes.number,
      sectionID: propTypes.number,
      idNum: propTypes.string,
      barcode: propTypes.string,
      approved: propTypes.number,
      joinDate: propTypes.string,
      userEmail: propTypes.string,
      firstName: propTypes.string,
      lastName: propTypes.string,
      report_pin: propTypes.string,
      quit: propTypes.number,
    }),
  ),
  curWorktypes: propTypes.arrayOf(
    propTypes.shape({
      id: propTypes.number,
      workType: propTypes.string,
      isAdminType: propTypes.number,
    }),
  ),
  payPeriodReports: propTypes.arrayOf(
    propTypes.shape({
      userId: propTypes.number,
      worktype: propTypes.number,
      timein: propTypes.string,
      timeout: propTypes.string,
    }),
  ),
  reports: propTypes.arrayOf(
    propTypes.shape({
      body: propTypes.string,
      comment: propTypes.string,
      committeeWork: propTypes.number,
      date: propTypes.string,
      end: propTypes.string,
      expGradingID: propTypes.number,
      expLabID: propTypes.number,
      expRecitationID: propTypes.number,
      firstName: propTypes.string,
      id: propTypes.number,
      lastName: propTypes.string,
      numOfReports: propTypes.number,
      problem: propTypes.string,
      rAndDProj_ID: propTypes.number,
      radprojectID: propTypes.string,
      radprojectText: propTypes.string,
      report_payperiodID: propTypes.number,
      reporter: propTypes.number,
      secID: propTypes.number,
      start: propTypes.string,
      status: propTypes.number,
      submitDate: propTypes.string,
      workType: propTypes.string,
    }),
  ),
  payPeriod: propTypes.string,
  curPage: propTypes.number,
  // TODO: take react components out of state, so messy.
  // eslint-disable-next-line react/forbid-prop-types
  visibleReport: propTypes.arrayOf(),
  curStart: propTypes.string,
  curEnd: propTypes.string,
};

PayrollPage.defaultProps = {
  curTAs: [
    {
      userID: -1,
      groupID: -1,
      sectionID: -1,
      idNum: '-1',
      barcode: '-1',
      approved: -1,
      joinDate: '-1',
      userEmail: '-1',
      firstName: '-1',
      lastName: '-1',
      report_pin: '-1',
      quit: -1,
    },
  ],
  curWorktypes: [
    {
      id: -1,
      workType: '-1',
      isAdminType: '-1',
    },
  ],
  payPeriodReports: [
    {
      userID: -1,
      worktype: -1,
      timein: '-1',
      timeout: '-1',
    },
  ],
  reports: [
    {
      body: '-1',
      comment: '-1',
      committeeWork: -1,
      date: '-1',
      end: '-1',
      expGradingID: -1,
      expLabID: -1,
      expRecitationID: -1,
      firstName: '-1',
      id: -1,
      lastName: '-1',
      numOfReports: -1,
      problem: '-1',
      rAndDProj_ID: -1,
      radprojectID: '-1',
      radprojectText: '-1',
      report_payperiodID: -1,
      reporter: -1,
      secID: -1,
      start: '-1',
      status: -1,
      submitDate: '-1',
      workType: '-1',
    },
  ],
  payPeriod: '1',
  curPage: 1,
  visibleReport: [],
  curStart: '-1',
  curEnd: '-1',
};
