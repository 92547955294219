import React, { Component } from 'react';
import propTypes from 'prop-types';
import axios from 'axios';
import { Form } from 'react-bootstrap';

export default class LabsDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      labs: props.labs,
    };
    this.updateCurSelection = this.updateCurSelection.bind(this);
  }

  componentDidMount() {
    axios.get('/reports-lab-experiments/').then((res) => {
      this.setState({
        labs: res.data,
      });
    });
  }

  updateCurSelection(e) {
    const { props } = this;
    props.parentCallback(e.target.value);
  }

  render() {
    const { props } = this;
    const curState = this.state;
    const allLabs = curState.labs;
    const selectOptions = allLabs.map((lab) => (
      <option id={lab.expLabID} key={lab.expLabID} value={lab.expLabID}>
        {`${lab.expLabShortName}: ${lab.expLabName}`}
      </option>
    ));

    return (
      <div>
        <Form.Control value={props.default} as="select" onChange={this.updateCurSelection}>
          <option value="-1">---Select Lab---</option>
          {selectOptions}
        </Form.Control>
      </div>
    );
  }
}

LabsDropdown.propTypes = {
  labs: propTypes.arrayOf(
    propTypes.shape({
      expLabID: propTypes.number,
      expLabShortName: propTypes.string,
      expLabName: propTypes.string,
    }),
  ),
  parentCallback: propTypes.func,
  default: propTypes.string,
};

LabsDropdown.defaultProps = {
  labs: [
    {
      expLabID: -1,
      expLabShortName: '-1',
      expLabName: '-1',
    },
  ],
  default: '-1',
  parentCallback: () => {},
};
