import React from 'react';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';
import * as RiIcons from 'react-icons/ri';

const SidebarData = [
  {
    title: 'Course Information',
    path: '#',
    icon: <AiIcons.AiFillHome />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: 'Announcement',
        path: '/',
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: 'Add Announcement',
        path: '/addAnnouncement',
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: 'Lectures',
        path: '/lectures',
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: 'Lectures Admin',
        path: '/lectures-administration',
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: 'Sections',
        path: '/sections',
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: 'Syllabus & Schedule',
        path: '/syllabus',
        icon: <IoIcons.IoIosPaper />,
      },
    ],
  },
  {
    title: 'Labs',
    path: '#',
    icon: <AiIcons.AiFillBook />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: 'Lab Documents',
        path: '/labdocuments',
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: 'Student Submission',
        path: '/studentSubmission',
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: 'Makeup Labs',
        path: '/makeuplabs',
        icon: <IoIcons.IoIosPaper />,
      },
    ],
  },
  {
    title: 'SLDP Tools',
    path: '#',
    icon: <AiIcons.AiFillRobot />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: 'Contracts',
        path: '/contracts',
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: 'SLDP Actions',
        path: '/sldpActions',
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: 'RAD Management',
        path: '/radmanagement',
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: 'SLDP Management',
        path: '/sldpmanagement',
        icon: <IoIcons.IoIosPaper />,
      },
    ],
  },
  {
    title: 'Work Tools',
    path: '#',
    icon: <AiIcons.AiFillTool />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: 'Needs Replacements',
        path: '/replacements',
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: 'Work Reports',
        path: '/reports',
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: 'Payroll Management',
        path: '/payroll',
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: 'Reports Management',
        path: '/reports-administration',
        icon: <IoIcons.IoIosPaper />,
      },
    ],
  },
  {
    title: 'Misc',
    path: '#',
    icon: <AiIcons.AiFillDatabase />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: 'Gradebook',
        path: '/gradebook',
        icon: <IoIcons.IoIosPaper />,
      },
      {
        title: 'Inventory',
        path: '/inventory',
        icon: <IoIcons.IoIosPaper />,
      },
    ],
  },
  {
    title: 'Administrative',
    path: '#',
    icon: <AiIcons.AiFillQuestionCircle />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: 'Committee Administration',
        path: '/committee-administration',
        icon: <IoIcons.IoIosPaper />,
      },
    ],
  },
  {
    title: 'Help Center',
    path: '#',
    icon: <AiIcons.AiFillQuestionCircle />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    subNav: [
      {
        title: 'Student Manual',
        path: '/',
        icon: <IoIcons.IoIosPaper />,
      },
    ],
  },
];

export default SidebarData;
