import React, { Component } from 'react';
import propTypes from 'prop-types';
import { Button, Card, Table } from 'react-bootstrap';

import UsersDropdown from '../convenientDropdowns/usersDropdown';

export default class CommitteeCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      memberID: -1,
    };
  }

  addMemberToCommittee = () => {
    const { memberID } = this.state;
    const { committeeID, addMemberToCommitteeCallback } = this.props;
    addMemberToCommitteeCallback(memberID, committeeID);
  };

  getTADropdownInfo = (childData) => {
    this.setState({
      memberID: childData,
    });
  };

  render() {
    const {
      committeeName,
      committeeID,
      committeeMembers,
      removeMemberFromCommitteeCallback,
    } = this.props;
    console.log('id: ', committeeID, 'Committee Members: ', committeeMembers);

    const trs = committeeMembers.map((member) => (
      <tr>
        <td>{member.memberName}</td>
        <td>
          <Button
            variant="danger"
            size="sm"
            onClick={() => removeMemberFromCommitteeCallback(member.userID, member.committeeID)}
          >
            X
          </Button>
        </td>
      </tr>
    ));
    return (
      <Card>
        <Card.Header>{committeeName}</Card.Header>
        <Card.Body>
          {committeeMembers.length > 0 && (
            <Table responsive striped bordered hover>
              <thead>
                <th>
                  <b>Member Name</b>
                </th>
                <th>
                  <b>Remove</b>
                </th>
                {trs}
              </thead>
            </Table>
          )}
          <hr />
          <p>
            To add a member to {committeeName}, select their name from the dropdown below and hit
            the green button.
          </p>
          <UsersDropdown parentCallback={this.getTADropdownInfo} />
          <br />

          <Button variant="success" size="sm" onClick={() => this.addMemberToCommittee()}>
            Add to {committeeName}
          </Button>
        </Card.Body>
      </Card>
    );
  }
}

CommitteeCard.propTypes = {
  committeeID: propTypes.number,
  committeeName: propTypes.string,
  committeeMembers: propTypes.arrayOf(
    propTypes.shape({
      userID: propTypes.number,
      memberName: propTypes.string,
      committeeID: propTypes.number,
    }),
  ),
  removeMemberFromCommitteeCallback: propTypes.func,
  addMemberToCommitteeCallback: propTypes.func,
};

CommitteeCard.defaultProps = {
  committeeID: -1,
  committeeName: '-1',
  committeeMembers: [],
  removeMemberFromCommitteeCallback: () => {},
  addMemberToCommitteeCallback: () => {},
};
