import axios from 'axios';
import propTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Form, Col, Row } from 'react-bootstrap';
import LecturesDropdown from '../convenientDropdowns/lecturesDropdown';
import utcISOToSql from '../../../utils/utcISOToSql';

const config = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
};
const qs = require('querystring-es3');

const LECTURE_DIR = '/lectures';

export default class UploadLectureSlides extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lectureId: props.lectureId,
      lectures: props.lectures,
      fileName: props.fileName,
      file: props.file,
    };
  }

  componentDidMount() {
    axios.get('/lectures').then((res) => {
      this.setState({
        lectures: res.data,
      });
    });
  }

  updateCurLecture = (childData) => {
    this.setState({
      lectureId: childData,
    });
  };

  onFileChange = (e) => {
    console.log(e.target.files[0]);
    this.setState({
      fileName: e.target.files[0].name,
      file: e.target.files[0],
    });
  };

  onSubmit = () => {
    const curState = this.state;
    if (curState.lectureId === -1) {
      console.log('Please choose a lecture to upload the slides!');
      return;
    }
    if (curState.file === undefined) {
      console.log('Please choose a valid file to upload!');
      return;
    }
    const formDataConfig = { headers: { 'Content-Type': undefined } };
    const formData = new FormData();
    formData.append('file', curState.file);

    const lecture = curState.lectures.find((item) => item.id.toString() === curState.lectureId);
    if (lecture === undefined) {
      console.log('Selected lecture is not found in the list of the lecture!');
      return;
    }
    const obj = {
      id: curState.lectureId,
      ordering: lecture.ordering,
      name: lecture.name,
      date: utcISOToSql(lecture.date),
      filepath: curState.fileName,
    };

    // Upload the file to GCS.
    axios.post(`/upload-public/${LECTURE_DIR}`, formData, formDataConfig).then((res) => {
      console.log(res.data);
      // Change the file name stored in the lectures table.
      axios
        .put(`/lectures/${curState.lectureId}`, qs.stringify(obj), config)
        .then(() => {
          window.location.reload(false);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  render() {
    const curState = this.state;
    return (
      <>
        <p className="text-muted">
          Only one file should be uploaded per lecture. Multiple files may be uploaded for each
          lecture but uploading a file with the same name as an existing file will overwrite the
          existing file.
        </p>
        <Form>
          <Row>
            <Col>
              <p>
                <b>Section:</b>
              </p>
            </Col>
            <Col xs={10}>
              <LecturesDropdown
                default={curState.lectureId}
                limited
                parentCallback={this.updateCurLecture}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <p>
                <b>File:</b>
              </p>
            </Col>
            <Col xs={10}>
              <Form.File type="file" onChange={this.onFileChange} />
            </Col>
          </Row>
          <Row>
            <Col>
              <Button variant="primary" onClick={this.onSubmit} className="mb-3">
                Submit File
              </Button>
            </Col>
          </Row>
        </Form>
      </>
    );
  }
}

UploadLectureSlides.propTypes = {
  lectureId: propTypes.string,
  lectures: propTypes.arrayOf(
    propTypes.shape({
      name: propTypes.string,
      id: propTypes.number,
      ordering: propTypes.number,
      date: propTypes.string,
      filepath: propTypes.string,
    }),
  ),
  fileName: propTypes.string,
  file: propTypes.shape({}),
};

UploadLectureSlides.defaultProps = {
  lectureId: '-1',
  lectures: [
    {
      name: '-1',
      id: -1,
      ordering: -1,
      date: '-1',
      filepath: '-1',
    },
  ],
  fileName: '-1',
  file: undefined,
};
