import axios from 'axios';
import React, { Component } from 'react';
import { Alert, Button, Form, Col, Row, Spinner, Modal } from 'react-bootstrap';
import propTypes from 'prop-types';

import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import datePickerToUtcISO from '../../../utils/datePickerToUtcISO';

import WorktypeDropdown from '../convenientDropdowns/worktypeDropdown';
import TimeSelector from '../convenientDropdowns/timeSelector';
import DateSelector from '../dateSelector/dateSelector';
import LabDropdown from '../convenientDropdowns/labsDropdown';
import RecPresentationsDropdown from '../convenientDropdowns/recPresentationsDropdown';
import SectionsDropdown from '../convenientDropdowns/sectionsDropdown';

const config = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
};
const qs = require('querystring-es3');

export default class ReplacementRequest extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showSubmitModal: props.showSubmitModal,
      showIncompleteFormModal: props.showIncompleteFormModal,
      incompleteFormModalMessage: props.incompleteFormModalMessage,

      curWorktype: props.curWorktype,
      curDate: props.curDate,
      curTimeIn: props.timeIn,
      curTimeOut: props.timeOut,
      reasonBody: props.reasonBody,

      curSection: props.curSection, // used for lab/recitation

      curLab: props.curLab, // used for lab
      curRecPresentationInfo: props.curRecPresentationInfo, // used for recitation

      loadSpinner: props.loadSpinner,
      success: props.success,
      error: props.error,

      rerender: props.rerender, // needed to reset the content of time selector and ckeditor after submission
    };
    this.toggleSubmitModal = this.toggleSubmitModal.bind(this);
    this.toggleIncompleteFormModal = this.toggleIncompleteFormModal.bind(this);
  }

  getTimeInSelectInfo = (childData) => {
    this.setState({
      curTimeIn: childData,
    });
    console.log('state : ', this.state);
  };

  getTimeOutSelectInfo = (childData) => {
    this.setState({
      curTimeOut: childData,
    });
    console.log('time out: ', childData);
  };

  getDate = (date) => {
    this.setState({
      curDate: date,
    });
    console.log('Updated date to: ', date);
  };

  getWorktypeInfo = (childData) => {
    this.setState({
      curWorktype: childData,
    });
  };

  getCurSection = (childData) => {
    this.setState({
      curSection: childData,
    });
  };

  getCurLab = (childData) => {
    this.setState({
      curLab: childData,
    });
  };

  getCurRecPresentationInfo = (childData) => {
    this.setState({
      curRecPresentationInfo: childData,
    });
  };

  toggleSuccess = () => {
    this.setState((prevState) => ({
      success: !prevState.success,
    }));
  };

  toggleError = () => {
    this.setState((prevState) => ({
      error: !prevState.error,
    }));
  };

  resetFormValues = () => {
    this.setState((prevState) => ({
      curWorktype: '-1',
      curDate: '-1',
      curTimeIn: '-1',
      curTimeOut: '-1',
      reasonBody: '-1',
      curSection: '-1',
      curLab: '-1',
      curRecPresentationInfo: '-1',

      rerender: !prevState.rerender,
    }));
  };

  validateReplacementRequest = () => {
    const curState = this.state;
    const { props } = this;
    const worktype = parseInt(curState.curWorktype, 10);
    const timeIn = curState.curTimeIn;
    const timeOut = curState.curTimeOut;
    const date = datePickerToUtcISO(curState.curDate);
    const section = parseInt(curState.curSection, 10);
    const lab = parseInt(curState.curLab, 10);
    const recPres = parseInt(curState.curRecPresentationInfo, 10);
    const reasonBody = curState.reasonEditor.getData();

    if (!worktype || worktype === -1) {
      // user forgot to give a worktype
      this.toggleIncompleteFormModal('Forgot to enter worktype.');
      return;
    }
    if (worktype === 4) {
      // lab
      if (section === -1 || lab === -1) {
        this.toggleIncompleteFormModal('Make sure you include the section and lab info.');
        return;
      }
    }
    if (worktype === 12) {
      // Recitation
      if (section === -1 || recPres === -1) {
        this.toggleIncompleteFormModal(
          'Make sure you include the section and recitation presentation.',
        );
        return;
      }
    }
    if (reasonBody === '') {
      // user forgot to give a reason
      this.toggleIncompleteFormModal('Forgot to give a reason.');
      return;
    }
    if (!timeIn || timeIn === '-1') {
      // user forgot to enter a timeIn
      this.toggleIncompleteFormModal('Forgot to give a time in.');
      return;
    }
    if (!timeOut || timeOut === '-1') {
      // user forgot to enter a timeOut
      this.toggleIncompleteFormModal('Forgot to give a time out.');
      return;
    }

    const dateTimeIn = `${date} ${timeIn.slice(0, 2)}:${timeIn.slice(2, 4)}:00`; // formatting for DB
    const dateTimeOut = `${date} ${timeOut.slice(0, 2)}:${timeIn.slice(2, 4)}:00`; // formatting for DB

    const obj = {
      userID: 14790, // TODO: currently hardcoded to Dan
      worktype,
      dateTimeIn,
      dateTimeOut,
      reasonBody,
    };
    if (worktype === 4) {
      // lab
      obj.section = section;
      obj.lab = lab;
    }
    if (worktype === 12) {
      // Recitation
      obj.section = section;
      obj.recPres = recPres;
    }

    if (props.testSubmit()) {
      console.log('test submitting');
      props.testSubmit(obj);
    } else this.submit(obj);
  };

  submit = (submitObj) => {
    this.setState({
      loadSpinner: true,
      error: false,
      success: false,
    });
    axios
      .post('/replacements/', qs.stringify(submitObj), config)
      .then((response) => {
        console.log('POST response: ', response);
        this.setState({
          success: true,
          loadSpinner: false,
        });
        this.resetFormValues();
      })
      .catch(() => {
        this.setState({
          error: true,
          loadSpinner: false,
        });
        this.resetFormValues();
      });

    window.location.replace('/replacements'); // TODO: Eventually change to a non hard refresh
  };

  toggleSubmitModal() {
    this.setState((prevState) => ({
      showSubmitModal: !prevState.showSubmitModal,
    }));
  }

  toggleIncompleteFormModal(message) {
    this.setState((prevState) => ({
      showIncompleteFormModal: !prevState.showIncompleteFormModal,
      incompleteFormModalMessage: message,
    }));
  }

  render() {
    const curState = this.state;
    const { props } = this;
    return (
      <div>
        <p className="text-muted">Note: You must provide a valid reason.</p>
        <Modal
          show={curState.showSubmitModal}
          onHide={this.toggleSubmitModal}
          animation={props.modalAnimation}
        >
          <Modal.Header closeButton>
            <Modal.Title>Are you sure you want to submit?</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>Make sure all the information you entered is correct before proceeding.</p>
          </Modal.Body>

          <Modal.Footer>
            <Button onClick={this.toggleSubmitModal} variant="secondary">
              Keep Writing
            </Button>
            <Button
              onClick={() => {
                this.validateReplacementRequest();
                this.toggleSubmitModal();
              }}
              variant="primary"
            >
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={curState.showIncompleteFormModal}
          onHide={() => this.toggleIncompleteFormModal('Contact webdev')}
          animation={props.modalAnimation}
        >
          <Modal.Header closeButton>
            <Modal.Title>Form is incomplete!</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>Make sure you entered all the necessary information before pressing submit.</p>
            <p>
              <b>Error: </b>
              {curState.incompleteFormModalMessage}
            </p>
          </Modal.Body>

          <Modal.Footer>
            <Button
              onClick={() => this.toggleIncompleteFormModal('Contact webdev')}
              variant="primary"
            >
              Okay
            </Button>
          </Modal.Footer>
        </Modal>
        <Form>
          <Row>
            <Col>
              <p>
                <b>Worktype:</b>
              </p>
            </Col>
            <Col xs={10}>
              <WorktypeDropdown
                default={curState.curWorktype}
                limited
                parentCallback={this.getWorktypeInfo}
              />
            </Col>
          </Row>
          {curState.curWorktype === '4' && ( // lab
            <div>
              <Row>
                <Col>
                  <p>
                    <b>Section:</b>
                  </p>
                </Col>
                <Col xs={10}>
                  <SectionsDropdown
                    default={curState.curSection}
                    limited
                    parentCallback={this.getCurSection}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <p>
                    <b>Lab:</b>
                  </p>
                </Col>
                <Col xs={10}>
                  <LabDropdown default={curState.curLab} limited parentCallback={this.getCurLab} />
                </Col>
              </Row>
            </div>
          )}
          {curState.curWorktype === '12' && ( // recitation
            <div>
              <Row>
                <Col>
                  <p>
                    <b>Section:</b>
                  </p>
                </Col>
                <Col xs={10}>
                  <SectionsDropdown
                    default={curState.curSection}
                    limited
                    parentCallback={this.getCurSection}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <p>
                    <b>Presentation:</b>
                  </p>
                </Col>
                <Col xs={10}>
                  <RecPresentationsDropdown
                    default={curState.curRecPresentationInfo}
                    limited
                    parentCallback={this.getCurRecPresentationInfo}
                  />
                </Col>
              </Row>
            </div>
          )}
          <Row>
            <Col>
              <p>
                <b>Date:</b>
              </p>
            </Col>
            <Col xs={10}>
              {curState.rerender && <DateSelector parentCallback={this.getDate} />}
              {!curState.rerender && <DateSelector parentCallback={this.getDate} />}
            </Col>
          </Row>
          <Row>
            <Col>
              <p>
                <b>Time:</b>
              </p>
            </Col>
            <Col xs={5}>
              <TimeSelector
                default={curState.curTimeIn}
                parentCallback={this.getTimeInSelectInfo}
                label="Time In"
              />
            </Col>
            <Col xs={5}>
              <TimeSelector
                default={curState.curTimeOut}
                parentCallback={this.getTimeOutSelectInfo}
                label="Time Out"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <p>
                <b>Reason:</b>
              </p>
            </Col>
            <Col xs={10}>
              {curState.rerender && (
                <CKEditor
                  editor={ClassicEditor} // TODO: Adjust initial height so it looks better
                  data={curState.reasonBody === '-1' ? '' : curState.reasonBody}
                  onInit={(editor) => {
                    // You can store the "editor" and use when it is needed.
                    this.setState({
                      reasonEditor: editor,
                    });
                    console.log('Editor is ready to use!', editor);
                  }}
                />
              )}
              {!curState.rerender && (
                <CKEditor
                  editor={ClassicEditor} // TODO: Adjust initial height so it looks better
                  data={curState.reasonBody === '-1' ? '' : curState.reasonBody}
                  style={{ marginBottom: '10px' }}
                  onInit={(editor) => {
                    // You can store the "editor" and use when it is needed.
                    this.setState({
                      reasonEditor: editor,
                    });
                    console.log('Editor is ready to use!', editor);
                  }}
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col xs={10}>
              <Button variant="primary" onClick={this.toggleSubmitModal}>
                Submit Request
              </Button>
            </Col>
          </Row>
          <Row>
            {curState.loadSpinner && <Spinner variant="primary" animation="border" role="status" />}
            {curState.success && (
              <Alert variant="success" onClose={this.toggleSuccess} dismissible>
                Replacement request was successfully created.
              </Alert>
            )}
            {curState.error && (
              <Alert variant="danger" onClose={this.toggleError} dismissible>
                Error! Contact webdev.
              </Alert>
            )}
          </Row>
        </Form>
      </div>
    );
  }
}

ReplacementRequest.propTypes = {
  showSubmitModal: propTypes.bool,
  showIncompleteFormModal: propTypes.bool,
  incompleteFormModalMessage: propTypes.string,

  curWorktype: propTypes.string,
  curDate: propTypes.string,
  timeIn: propTypes.string,
  timeOut: propTypes.string,
  reasonBody: propTypes.string,
  curSection: propTypes.string,
  curLab: propTypes.string,
  curRecPresentationInfo: propTypes.string,

  loadSpinner: propTypes.bool,
  success: propTypes.bool,
  error: propTypes.bool,

  rerender: propTypes.bool,

  modalAnimation: propTypes.bool,
  testSubmit: propTypes.func,
};

ReplacementRequest.defaultProps = {
  showSubmitModal: false,
  showIncompleteFormModal: false,
  incompleteFormModalMessage: ":( You shouldn't be seeing this. Please contact WebDev!",

  curWorktype: '-1',
  curDate: '-1',
  timeIn: '-1',
  timeOut: '-1',
  reasonBody: '-1',
  curSection: '-1',
  curLab: '-1',
  curRecPresentationInfo: '-1',

  loadSpinner: false,
  success: false,
  error: false,

  rerender: true,

  modalAnimation: true, // we set this to false in our tests because animated modals cause tests to fail
  testSubmit: () => false, // we use this prop in testing to validate the data we're sending to the API
};
