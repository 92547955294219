import React, { Component } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import SldpMangementTable from '../../components/sldpManagement/sldpManagement';
import SldpMangement from '../../components/sldpManagement/createAndManageSldps';

export default class SldpManagementPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showOnlyRad: 0,
    };
  }

  render() {
    const curState = this.state;
    return (
      <div>
        <h3 className="display-6 mb-3">SLDP Management</h3>
        <Tabs defaultActiveKey="sldpManagementTable" id="inventory-items-tabs">
          <Tab
            eventKey="sldpManagementTable"
            title="Manage SLDPs Requirements"
            style={{ 'margin-top': '70px' }}
          >
            <SldpMangementTable showOnlyRad={curState.showOnlyRad} />
          </Tab>
          <Tab eventKey="manageSldps" title="Manage Current SLDPs" style={{ 'margin-top': '70px' }}>
            <SldpMangement />
          </Tab>
        </Tabs>
      </div>
    );
  }
}
