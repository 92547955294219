import React, { useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Sidebar from './views/components/sidebar/sidebar';
import Routes from './routes';
import './styles/main.scss';
import './styles/bootstrap-overrides.scss';

export default function App() {
  const [sideBarIsOpen, setSideBarIsOpen] = useState(true);
  const toggleSideBarIsOpen = () => {
    setSideBarIsOpen(!sideBarIsOpen);
  };

  return (
    <Router>
      <div className="body">
        <Sidebar parentCallback={toggleSideBarIsOpen} />
        <div className={sideBarIsOpen ? 'main-shifted' : 'main-unshifted'}>
          <Routes />
        </div>{' '}
      </div>
    </Router>
  );
}
