import React, { Component } from 'react';

import { Button } from 'react-bootstrap';

import propTypes from 'prop-types';

import SldpDropdown from '../convenientDropdowns/sldpDropdown';
import SldpGroupDropdown from '../convenientDropdowns/sldpGroupDropdown';

export default class SelectGroupAndSldp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sldpID: props.sldpID, // sldp ID, used for default item population depending on project
      groupID: props.groupID,
    };
    this.updateSldpID = this.updateSldpID.bind(this);
    this.updateGroupID = this.updateGroupID.bind(this);
  }

  updateSldpID(sldpID) {
    this.setState({
      sldpID,
    });
  }

  updateGroupID(groupID) {
    this.setState({
      groupID,
    });
  }

  render() {
    const { props } = this;
    const curState = this.state;
    return (
      <div>
        <p>Prepare to create a contract</p>
        <SldpDropdown default={curState.sldpID} parentCallback={this.updateSldpID} />
        {curState.sldpID !== '-1' && (
          <SldpGroupDropdown
            default={curState.groupID}
            projectType={curState.sldpID}
            parentCallback={this.updateGroupID}
          />
        )}
        {curState.sldp !== '-1' && curState.groupID !== '-1' && (
          <Button
            onClick={() => {
              props.startCreatingContract(curState.sldpID, curState.groupID);
            }}
            variant="primary"
          >
            Create Contract
          </Button>
        )}
      </div>
    );
  }
}

SelectGroupAndSldp.propTypes = {
  sldpID: propTypes.string,
  groupID: propTypes.string,
  startCreatingContract: propTypes.func,
};

SelectGroupAndSldp.defaultProps = {
  sldpID: '-1',
  groupID: '-1',
  startCreatingContract: () => {},
};
