import React, { Component } from 'react';
import propTypes from 'prop-types';
import axios from 'axios';
import { Form } from 'react-bootstrap';

export default class SubmissionsDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      submissions: props.submissions,
    };
    this.updateCurSelection = this.updateCurSelection.bind(this);
  }

  componentDidMount() {
    axios.get('/submission-info/').then((res) => {
      console.log('response', res);
      this.setState({
        submissions: res.data,
      });
    });
  }

  updateCurSelection(e) {
    const { props } = this;
    props.parentCallback(e.target.value);
  }

  render() {
    const { props } = this;
    const curState = this.state;
    const allSubmissions = curState.submissions;
    const selectOptions = allSubmissions.map((submission) => (
      <option id={submission.id} key={submission.id} value={submission.id}>
        {submission.name}
      </option>
    ));

    return (
      <div>
        <Form.Control value={props.default} as="select" onChange={this.updateCurSelection}>
          <option value="-1">---Select Submission---</option>
          {selectOptions}
        </Form.Control>
      </div>
    );
  }
}

SubmissionsDropdown.propTypes = {
  submissions: propTypes.arrayOf(
    propTypes.shape({
      id: propTypes.number,
      shortName: propTypes.string,
      name: propTypes.string,
    }),
  ),
  parentCallback: propTypes.func,
  default: propTypes.string,
};

SubmissionsDropdown.defaultProps = {
  submissions: [
    {
      id: -1,
      shortName: '-1',
      name: '-1',
    },
  ],
  parentCallback: () => {},
  default: '-1',
};
