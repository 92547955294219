import axios from 'axios';
import propTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Table, Modal } from 'react-bootstrap';

const colorDict = {
  REQUESTED: 'goldenrod',
  APPROVED: 'coral',
  DENIED: 'crimson',
  COMPLETED: 'limegreen',
};

class ViewMakeupLabModal extends Component {
  render() {
    const { props } = this;
    const { makeupLab } = this.props;
    console.log('Makeup lab in modal: ', makeupLab);
    return (
      <Modal show={props.show} onHide={props.onHide} animation>
        <Modal.Header closeButton>
          <Modal.Title>Viewing Request</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <b>Requester:</b> {`${makeupLab.firstName} ${makeupLab.lastName}`}
          </p>
          <p>
            <b>Section:</b> {makeupLab.secName}
          </p>
          <p>
            <b>Lab Name:</b> {`${makeupLab.expLabShortName} - ${makeupLab.expLabName}`}
          </p>
          <p>
            <b>Request Time:</b> {makeupLab.requestTime}
          </p>
          <p>
            <b>Approval Time:</b> {makeupLab.approvalTime}
          </p>
          <p>
            <b>Completion Time:</b> {makeupLab.completionTime}
          </p>
          <p>
            <b>TA Approved:</b> {makeupLab.approverID}
          </p>
          <p>
            <b>Reason:</b>
            {/* eslint-disable-next-line react/no-danger */}
            <span dangerouslySetInnerHTML={{ __html: makeupLab.comment }} />
          </p>
          <p>
            <b>Note:</b> Presentation and lab should be done by{' '}
            <b className="text-danger">{makeupLab.deadline}</b>.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide} variant="secondary" size="sm">
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default class ViewMakeupLabsCompleted extends Component {
  constructor(props) {
    super(props);
    this.state = {
      makeupLabRequests: props.makeupLabRequests,
      makeupLab: props.makeupLab,
      viewModal: props.viewModal,
    };
    this.updateCurrentSpecificLab = this.updateCurrentSpecificLab.bind(this);
  }

  componentDidMount() {
    const { props } = this;
    if (props.status === 'OPEN') {
      axios.get('/makeuplabs-completed').then((res) => {
        console.log('response', res);
        this.setState({
          makeupLabRequests: res.data,
        });
      });
    } else {
      axios.get('/makeuplabs-'.concat(props.status.toLowerCase())).then((res) => {
        console.log('response', res);
        this.setState({
          makeupLabRequests: res.data,
        });
      });
    }
  }

  toggleViewModal = () => {
    this.setState((prevState) => ({
      viewModal: !prevState.viewModal,
    }));
  };

  updateCurrentSpecificLab(makeupID, i) {
    this.toggleViewModal();
    axios.get('/makeuplabs/'.concat(makeupID)).then((res) => {
      console.log(res.data[0], i);
      this.setState({
        makeupLab: res.data[0],
      });
    });
  }

  render() {
    const { props } = this;
    const curState = this.state;
    if (props.status === 'OPEN') {
      const { makeupLabRequests } = this.state;
      let i = -1;
      const trs = makeupLabRequests.map((request) => {
        console.log('Request while generating TRS: ', request);
        i += 1; // This is used to update the requests state object. It matches with the keys of the objects in that object.
        const requester = `${request.firstName} ${request.lastName}`;
        const { completionTime } = request;
        const section = request.secName;
        const lab = `${request.expLabShortName} - ${request.expLabName}`;
        const { status } = request;

        return (
          <tr key={i}>
            <td key={`${request.makeupID}-requester`}>{requester}</td>
            <td key={`${request.makeupID}-section`}>{section}</td>
            <td key={`${request.makeupID}-lab`}>{lab}</td>
            <td key={`${request.makeupID}-time`}>{completionTime}</td>
            <td key={`${request.makeupID}-status`} style={{ color: colorDict[status] }}>
              <b>{status}</b>
            </td>
            <td key={`${request.makeupID}-viewButton`}>
              <Button onClick={() => this.updateCurrentSpecificLab(request.makeupID)} size="sm">
                View
              </Button>
            </td>
          </tr>
        );
      });
      if (curState.makeupLabRequests.length !== 0) {
        return (
          <div>
            <p className="text-muted">These are all completed makeup labs.</p>
            <Table responsive striped bordered hover>
              <thead>
                <tr className="header-row">
                  <th>Requester</th>
                  <th>Section</th>
                  <th>Lab Name</th>
                  <th>Completion Time</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>{trs}</tbody>
            </Table>
            {curState.viewModal && (
              <ViewMakeupLabModal
                show={curState.viewModal}
                onHide={this.toggleViewModal}
                makeupLab={curState.makeupLab}
              />
            )}
          </div>
        );
      }
      return <p>There is currently no completed makeup lab.</p>;
    }
    if (props.status === 'WAITING') {
      return <p>This is an unfinished page, webdev needs to update it (status: waiting).</p>;
    }
    return <p>This is an unfinished page, webdev needs to update it.</p>;
  }
}

ViewMakeupLabModal.propTypes = {
  makeupLab: propTypes.shape({
    approvalTime: propTypes.string,
    approverID: propTypes.number,
    comment: propTypes.string,
    completionTime: propTypes.string,
    deadline: propTypes.string,
    expLabID: propTypes.number,
    expLabName: propTypes.string,
    expLabShortName: propTypes.string,
    firstName: propTypes.string,
    lastName: propTypes.string,
    makeupID: propTypes.number,
    requestTime: propTypes.string,
    secName: propTypes.string,
    sectionID: propTypes.number,
    status: propTypes.string,
    userID: propTypes.number,
  }),
  show: propTypes.bool,
  onHide: propTypes.func,
};

ViewMakeupLabModal.defaultProps = {
  makeupLab: propTypes.shape({
    approvalTime: '-1',
    approverID: -1,
    comment: '-1',
    completionTime: '-1',
    deadline: '-1',
    expLabID: -1,
    expLabName: '-1',
    expLabShortName: '-1',
    firstName: '-1',
    lastName: '-1',
    makeupID: -1,
    requestTime: '-1',
    secName: '-1',
    sectionID: -1,
    status: '-1',
    userID: -1,
  }),
  show: true,
  onHide: () => {},
};

ViewMakeupLabsCompleted.propTypes = {
  status: propTypes.string,
  makeupLabRequests: propTypes.arrayOf(
    propTypes.shape({
      approvalTime: propTypes.string,
      approverID: propTypes.number,
      comment: propTypes.string,
      completionTime: propTypes.string,
      deadline: propTypes.string,
      expLabID: propTypes.number,
      expLabName: propTypes.string,
      expLabShortName: propTypes.string,
      firstName: propTypes.string,
      lastName: propTypes.string,
      makeupID: propTypes.number,
      requestTime: propTypes.string,
      secName: propTypes.string,
      sectionID: propTypes.number,
      status: propTypes.string,
      userID: propTypes.number,
    }),
  ),
  makeupLab: propTypes.shape({
    approvalTime: propTypes.string,
    approverID: propTypes.number,
    comment: propTypes.string,
    completionTime: propTypes.string,
    deadline: propTypes.string,
    expLabID: propTypes.number,
    expLabName: propTypes.string,
    expLabShortName: propTypes.string,
    firstName: propTypes.string,
    lastName: propTypes.string,
    makeupID: propTypes.number,
    requestTime: propTypes.string,
    secName: propTypes.string,
    sectionID: propTypes.number,
    status: propTypes.string,
    userID: propTypes.number,
  }),
  viewModal: propTypes.bool,
};

ViewMakeupLabsCompleted.defaultProps = {
  status: '-1',
  makeupLabRequests: [
    {
      approvalTime: '-1',
      approverID: -1,
      comment: '-1',
      completionTime: '-1',
      deadline: '-1',
      expLabID: -1,
      expLabName: '-1',
      expLabShortName: '-1',
      firstName: '-1',
      lastName: '-1',
      makeupID: -1,
      requestTime: '-1',
      secName: '-1',
      sectionID: -1,
      status: '-1',
      userID: -1,
    },
  ],
  makeupLab: {
    approvalTime: '-1',
    approverID: -1,
    comment: '-1',
    completionTime: '-1',
    deadline: '-1',
    expLabID: -1,
    expLabName: '-1',
    expLabShortName: '-1',
    firstName: '-1',
    lastName: '-1',
    makeupID: -1,
    requestTime: '-1',
    secName: '-1',
    sectionID: -1,
    status: '-1',
    userID: -1,
  },
  viewModal: false,
};
