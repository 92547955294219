import React, { Component } from 'react';
import axios from 'axios';
import { Table, Form, Row, Col, Button } from 'react-bootstrap';
import propTypes from 'prop-types';

import SldpDropdown from '../convenientDropdowns/sldpDropdown';
import ItemDropdown from '../convenientDropdowns/itemDropdown';

const config = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
};
const qs = require('querystring-es3');

class DefaultSldpKit extends Component {
  // This component gives the user a brief overview of what items come in each kit by default

  constructor(props) {
    super(props);

    this.state = {
      itemsInKit: props.itemsInKit, // all items in the default kit of a given SLDP
      editingItemID: props.editingItemID, // id of the item whose amout we're currently editing
      editing_amount: props.editing_amount, // updated amount of the item that we're currently editing

      addingItemID: props.addingItemID, // id of item that is (potentially) being added to the kit
      addingItemAmount: props.addingItemAmount, // amount of the item that will be used in the kit (1 by default)
    };
  }

  componentDidMount() {
    const { props } = this;
    axios.get(`/inventory-kits/${props.sldpID}`).then((res) => {
      this.setState({
        itemsInKit: res.data,
      });
    });
  }

  componentDidUpdate(prevProps) {
    const { props } = this;
    if (props.sldpID !== prevProps.sldpID) {
      this.updateItemsInKitState();
    }
  }

  deleteItemFromKit = (itemID, sldpID) => {
    axios.delete(`/inventory-item-defaultKit/${itemID}/${sldpID}`).then(() => {
      this.updateItemsInKitState();
    });
  };

  toggleEditingItemInKit = (item = {}) => {
    if (item === {}) {
      this.setState({
        editingItemID: -1,
      });
    } else {
      this.setState({
        editingItemID: item.itemID,
        editing_amount: item.amount,
      });
    }
  };

  updateEditingAmountInState = (e) => {
    this.setState({
      editing_amount: e.target.value,
    });
  };

  submitUpdatedAmount = (itemID, amount) => {
    axios
      .put(`/inventory-item-defaultKit/${itemID}`, qs.stringify({ amount }), config)
      .then(() => {
        this.updateItemsInKitState();
        this.toggleEditingItemInKit();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  updateAddingItemID = (itemID) => {
    this.setState({
      addingItemID: itemID,
    });
    console.log('Want to add item with ID: ', itemID);
  };

  updateAddingItemAmount = (e) => {
    this.setState({
      addingItemAmount: e.target.value,
    });
  };

  addItemToKit = () => {
    const { props } = this;
    const curState = this.state;

    const itemID = curState.addingItemID;
    let amount = curState.addingItemAmount;

    // basic form validation
    if (itemID === '-1') return; // button was clicked without selecting an item
    if (amount === '') amount = '1'; // user left text box empty
    if (amount < 0) amount = '1'; // user gave negative amount

    const obj = {
      itemID,
      amount,
    };

    console.log('Obj that will be pushed to API: ', obj);
    axios
      .post(`/inventory-item-defaultKit/${props.sldpID}`, qs.stringify(obj), config)
      .then((response) => {
        console.log(response);
        this.updateItemsInKitState(); // lets user see newly added item in default kit
      })
      .catch((err) => {
        console.log(err);
      });
  };

  updateItemsInKitState() {
    const { props } = this;
    axios.get(`/inventory-kits/${props.sldpID}`).then((res) => {
      this.setState({
        itemsInKit: res.data,
      });
    });
  }

  render() {
    const { props } = this;
    const curState = this.state;
    console.log('DefaultSldpKit State: ', curState);
    let trs = [];
    trs = curState.itemsInKit.map((item) => {
      const itemID = item.id; // this is the auto incremented ID in the 'inventory_items_defaultSLDPs' table.
      const { itemName } = item;
      const { amount } = item;
      if (itemID === curState.editingItemID) {
        return (
          <tr key={`defaultKit-editing-tr-${itemID}`}>
            <td key={`defaultKit-editing-name-${itemID}`}>{itemName}</td>
            <td key={`defaultKit-editing-amount-${itemID}`}>
              <input
                key={`input-amount-${itemID}`}
                defaultValue={amount}
                onBlur={this.updateEditingAmountInState}
                type="number"
              />
            </td>
            <td>
              <Button onClick={() => this.toggleEditingItemInKit()} variant="secondary">
                Cancel
              </Button>
              <Button
                onClick={() => this.submitUpdatedAmount(itemID, curState.editing_amount)}
                variant="primary"
              >
                Submit
              </Button>
            </td>
          </tr>
        );
      }
      return (
        <tr key={`defaultKit-tr-${itemID}`}>
          <td key={`defaultKit-name-${itemID}`}>{itemName}</td>
          <td key={`defaultKit-amount-${itemID}`}>{amount}</td>
          <td>
            <Button
              onClick={() => this.toggleEditingItemInKit({ amount, itemID })}
              variant="secondary"
            >
              Edit Amount
            </Button>
            <Button
              onClick={() => this.deleteItemFromKit(item.itemID, props.sldpID)}
              variant="danger"
            >
              Remove from Kit
            </Button>
          </td>
        </tr>
      );
    });
    return (
      <div>
        <h4>Default Kit</h4>
        {trs.length === 0 && (
          <p>
            This SLDP currently doesn&apos;t have any items in its kit by default, but you can add
            some below.
          </p>
        )}
        {trs.length !== 0 && (
          <div>
            <p>
              Below you&apos;ll find a table filled with the default items that can be found in the
              kit for your selected SLDP.
            </p>
            <Table responsive striped bordered hover>
              <thead>
                <tr>
                  <th>Item Name</th>
                  <th>Amount in Kit</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>{trs}</tbody>
            </Table>
          </div>
        )}
        <Row>
          <Col xs={2}>
            <p>
              <b>Add item:</b>
            </p>
          </Col>
          <Col xs={3}>
            <ItemDropdown
              parentCallback={this.updateAddingItemID}
              default={curState.addingItemID}
            />
            <Form.Text muted>
              Warning: please don&apos;t accidentally add items that are already in the kit.
              Instead, update the amount.
            </Form.Text>
          </Col>
          <Col xs={2}>
            <p>
              <b>Amount:</b>
            </p>
          </Col>
          <Col xs={3}>
            <input
              onChange={this.updateAddingItemAmount}
              value={curState.addingItemAmount}
              type="number"
            />
            <Form.Text muted>
              If you leave this empty or give a negative value, it will default to 1.
            </Form.Text>
          </Col>
          <Col xs={2}>
            <Button onClick={this.addItemToKit} variant="primary">
              Add to Kit
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default class SldpKits extends Component {
  constructor(props) {
    super(props);

    this.state = {
      kits: props.kits, // array of all kits currently on display
      sldpID: props.sldpID, // the SLDP that we want to look at the kits of
      kitNumber: props.kitNumber, // the kit number of the new kit being added to the DB
    };
  }

  getSldpKits = (sldpID) => {
    axios.get(`/sldp-kits/${sldpID}`).then((res) => {
      this.setState({
        kits: res.data,
        sldpID,
      });
    });
  };

  updateKitNumber = (e) => {
    this.setState({
      kitNumber: e.target.value,
    });
  };

  deleteKit = (kitID) => {
    const curState = this.state;
    axios.delete(`/sldp-kits/${kitID}`).then(() => {
      this.getSldpKits(curState.sldpID);
    });
  };

  addNewKit = (event) => {
    const curState = this.state;
    event.preventDefault(); // prevents reloading of the page
    const obj = {
      kitNumber: curState.kitNumber,
      sldpID: curState.sldpID,
    };
    axios
      .post('/sldp-kits/', qs.stringify(obj), config)
      .then((response) => {
        console.log(response);
        this.getSldpKits(curState.sldpID); // lets user see newly added kit on page
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const curState = this.state;
    console.log('SldpKits State: ', curState);
    const trs = curState.kits.map((kit) => {
      const kitID = kit.id;
      const { kitNumber } = kit;
      const isCheckedOut = kit.isCheckedOut ? 'yes' : 'no';
      const groupID = kit.isCheckedOut ? kit.groupID : 'n/a'; // TODO: eventaully want to make this link with the contract for this group
      return (
        <tr key={`tr-${kitID}`}>
          <td key={`kitNumber-${kitID}`}>{kitNumber}</td>
          <td key={`isCheckedOut-${kitID}`}>{isCheckedOut}</td>
          <td key={`groupID-${kitID}`}>{groupID}</td>
          <td key={`action-${kitID}`}>
            <Button onClick={() => this.deleteKit(kitID)} variant="danger">
              Delete
            </Button>
          </td>
        </tr>
      );
    });
    console.log('CUR SLDPID: ', curState.sldpID);
    if (curState.sldpID === -1 || curState.sldpID === '-1')
      return <SldpDropdown default={curState.sldpID} parentCallback={this.getSldpKits} />;
    return (
      <div>
        <SldpDropdown parentCallback={this.getSldpKits} default={curState.sldpID} />
        <br />
        <hr />
        <br />
        <DefaultSldpKit sldpID={curState.sldpID} />
        <br />
        <hr />
        <br />
        {trs.length === 0 && (
          <p>
            <b>No kits for this SLDP, but you can add some below:</b>
          </p>
        )}
        {trs.length !== 0 && (
          <div>
            <h4>Individual SLDP Kits</h4>
            <p>
              Below you&apos;ll find a table that contains information for each individual kit for
              the selected SLDP.
            </p>
            <Table responsive striped bordered hover>
              <thead>
                <tr>
                  <th>Kit Number</th>
                  <th>Checked Out</th>
                  <th>Group ID (if checked out)</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>{trs}</tbody>
            </Table>
          </div>
        )}
        <Form>
          <Row>
            <Col xs={2}>
              <b>New Kit:</b>
            </Col>
            <Col xs={6}>
              <Form.Control
                required
                onChange={this.updateKitNumber}
                type="number"
                placeholder="Enter kit Number"
              />
            </Col>
            <Col xs={4}>
              <Button onClick={this.addNewKit} variant="primary" type="submit">
                Add New Kit
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

SldpKits.propTypes = {
  sldpID: propTypes.number,
  kitNumber: propTypes.number,
  kits: propTypes.arrayOf(
    propTypes.shape({
      groupID: propTypes.number,
      id: propTypes.number,
      isCheckedOut: propTypes.bool,
      kitNumber: propTypes.number,
      sldpID: propTypes.number,
    }),
  ),
};

SldpKits.defaultProps = {
  sldpID: -1,
  kitNumber: -1,
  kits: [
    {
      groupID: -1,
      id: -1,
      isCheckedOut: false,
      kitNumber: -1,
      sldpID: -1,
    },
  ],
};

DefaultSldpKit.propTypes = {
  sldpID: propTypes.number,
  itemsInKit: propTypes.arrayOf(
    propTypes.shape({
      amount: propTypes.number,
      cost: propTypes.number,
      id: propTypes.number,
      itemID: propTypes.number,
      itemName: propTypes.string,
      sldpID: propTypes.number,
      unit: propTypes.string,
    }),
  ),
  editingItemID: propTypes.number,
  editing_amount: propTypes.number,
  addingItemID: propTypes.string,
  addingItemAmount: propTypes.number,
};

DefaultSldpKit.defaultProps = {
  sldpID: -1,
  itemsInKit: [
    {
      amount: -1,
      cost: -1,
      id: -1,
      itemID: -1,
      itemName: '-1',
      sldpID: -1,
      unit: '-1',
    },
  ],
  editingItemID: -1,
  editing_amount: 1,
  addingItemID: '-1',
  addingItemAmount: 1,
};
