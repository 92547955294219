export default function ymdToMonthName(date) {
  // input: yyyy-mm-dd
  // output: (MONTH NAME) dd, yyyy

  const dateMapper = {
    '01': 'January',
    '02': 'February',
    '03': 'March',
    '04': 'April',
    '05': 'May',
    '06': 'June',
    '07': 'July',
    '08': 'August',
    '09': 'September',
    10: 'October',
    11: 'November',
    12: 'December',
  };

  const month = dateMapper[date.slice(5, 7)];
  const day = date.slice(8, 10);
  const year = date.slice(0, 4);

  return `${month} ${day}, ${year}`;
}
