import React, { Component } from 'react';
import { Alert, Button, Table, Modal, Spinner } from 'react-bootstrap';
import propTypes from 'prop-types';
import axios from 'axios';

import ymdToMonthName from '../../../utils/ymdToMonthName';
import SqlUTCToNYTime from '../../../utils/sqlUTCToNYTime';

const colorDict = {
  REQUESTED: 'goldenrod',
  OPEN: 'limegreen',
  WAITING: 'crimson',
};

const config = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
};
const qs = require('querystring-es3');

class ViewRequestModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      replacementRequest: props.replacementRequest,
      requestsToFillRequest: props.requestsToFillRequest,

      loadSpinner: props.loadSpinner,
      success: props.success,
      error: props.error,
      successMsg: props.successMsg,
      errorMsg: props.errorMsg,
    };
  }

  componentDidMount() {
    const { props } = this;
    const queryString = `replacements/${props.replacementRequest.replacementID.toString()}/${props.replacementRequest.workTypeID.toString()}`;
    axios.get(queryString).then((res) => {
      console.log('Response for mounting modal:', res.data);
      this.setState({
        replacementRequest: res.data[0],
      });
    });
    axios
      .get('/requestToFillReplacementRequest/'.concat(props.replacementRequest.replacementID))
      .then((res) => {
        this.setState({
          requestsToFillRequest: res.data,
        });
      });
  }

  updateRequestsToFillRequests = () => {
    const { props } = this;
    axios
      .get('/requestToFillReplacementRequest/'.concat(props.replacementRequest.replacementID))
      .then((res) => {
        this.setState({
          requestsToFillRequest: res.data,
        });
      });
  };

  dismissAlert = () => {
    const { props } = this;
    this.setState({
      loadSpinner: props.loadSpinner,
      success: props.success,
      error: props.error,
      successMsg: props.successMsg,
      errorMsg: props.errorMsg,
    });
  };

  approveReplacementRequest = (replacementID, replacerID, replacerName) => {
    const { props } = this;
    this.setState({
      loadSpinner: true,
      success: false,
      error: false,
      successMsg: props.successMsg,
      errorMsg: props.errorMsg,
    });
    if (!props.approve()) {
      axios
        .put('/replacements/approved/'.concat(replacementID), qs.stringify({ replacerID }), config)
        .then(() => {
          this.setState({
            loadSpinner: false,
            success: true,
            error: false,
            successMsg: `Successfully approved ${replacerName} for the job.`,
          });
          props.toggleViewModalParentCallback();
          props.approvedRequestParentCallback(replacerName);
        })
        .catch(() => {
          this.setState({
            loadSpinner: false,
            success: false,
            error: true,
            errorMsg: 'Error! Contact webdev.',
          });
        });
    } else
      props.approve({
        replacementID,
        replacerID,
      });
  };

  denyReplacementRequest = (replacementID, replacerID, replacerName) => {
    const { props } = this;
    this.setState({
      loadSpinner: true,
      success: false,
      error: false,
      successMsg: props.successMsg,
      errorMsg: props.errorMsg,
    });
    if (!props.deny()) {
      axios
        .put('/replacements/denied/'.concat(replacementID), qs.stringify({ replacerID }))
        .then(() => {
          this.setState({
            loadSpinner: false,
            success: true,
            error: false,
            successMsg: `Successfully denied ${replacerName} for the job.`,
          });
          this.updateRequestsToFillRequests();
        })
        .catch(() => {
          this.setState({
            loadSpinner: false,
            success: false,
            error: true,
            errorMsg: 'Error! Contact webdev.',
          });
        });
    } else
      props.deny({
        replacementID,
        replacerID,
      });
  };

  render() {
    const { props } = this;
    const curState = this.state;
    console.log('ViewRequestModal props: ', props);
    console.log('ViewRequestModal state: ', curState);
    const { replacementRequest } = curState;
    const { requestsToFillRequest } = curState;
    let i = 0;

    // TODO: disabling the rule below, it looks like jsx is being evaluated as assignment for some reason...
    // eslint-disable-next-line no-return-assign
    const trs = requestsToFillRequest.map((req) => (
      <tr key={(i += 1)}>
        <td key={`${req.replacementID}-${req.firstName}${req.lastName}`}>
          {`${req.firstName} ${req.lastName}`}
        </td>
        <td key={`${req.replacementID}-${req.firstName}${req.lastName}-date`}>
          {SqlUTCToNYTime(req.date, true)}
        </td>
        <td key={`${req.replacementID}-${req.firstName}${req.lastName}-deny`}>
          <Button
            onClick={() =>
              this.denyReplacementRequest(
                req.replacementID,
                req.userID,
                `${req.firstName} ${req.lastName}`,
              )
            }
            variant="danger"
            size="sm"
          >
            Deny
          </Button>
          <Button
            onClick={() =>
              this.approveReplacementRequest(
                req.replacementID,
                req.userID,
                `${req.firstName} ${req.lastName}`,
              )
            }
            variant="primary"
            size="sm"
          >
            Approve
          </Button>
        </td>
      </tr>
    ));
    return (
      <Modal show={props.show} onHide={props.onHide} dialogClassName="modal-60w" animation>
        <Modal.Header closeButton>
          <Modal.Title>Viewing Request</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <b>Requester: </b> {`${replacementRequest.firstName} ${replacementRequest.lastName}`}
          </p>
          <p>
            <b>Date: </b>{' '}
            {ymdToMonthName(SqlUTCToNYTime(replacementRequest.date_start).slice(0, 10))}
          </p>
          <p>
            <b>Time: </b>{' '}
            {`${SqlUTCToNYTime(replacementRequest.date_start).slice(11, 17)} to ${SqlUTCToNYTime(
              replacementRequest.date_end,
            ).slice(11, 17)}`}
          </p>
          <p>
            <b>Reason: </b>
            {/* eslint-disable-next-line react/no-danger */}
            <span dangerouslySetInnerHTML={{ __html: replacementRequest.comment }} />
          </p>
          <p>
            <b>Worktype: </b> {replacementRequest.workType}
          </p>
          {replacementRequest.workTypeID === 4 && (
            <div>
              <p>
                <b>Section:</b> {replacementRequest.secName}
              </p>
              <p>
                <b>Lab:</b>{' '}
                {`${replacementRequest.expLabShortName} - ${replacementRequest.expLabName}`}
              </p>
            </div>
          )}
          {replacementRequest.workTypeID === 12 && (
            <div>
              <p>
                <b>Section:</b> {replacementRequest.secName}
              </p>
              <p>
                <b>Lab:</b>{' '}
                {`${replacementRequest.expRecitationShortName} - ${replacementRequest.expRecitationName}`}
              </p>
            </div>
          )}
          <p className="text-muted">
            Note: approving any one replacement candidate will automatically deny all the rest.
          </p>
          <Table responsive bordered hover>
            <thead>
              <tr className="header-row">
                <th>Replacement Candidate</th>
                <th>Date/Time Applied</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>{trs}</tbody>
          </Table>
          {curState.loadSpinner && <Spinner variant="primary" animation="border" role="status" />}
          {curState.success && (
            <Alert variant="success" onClose={this.dismissAlert} dismissible>
              {curState.successMsg}
            </Alert>
          )}
          {curState.error && (
            <Alert variant="danger" onClose={this.dismissAlert} dismissible>
              {curState.errorMsg}
            </Alert>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={props.onHide} variant="secondary" size="sm">
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default class ViewRequestsToFillRequests extends Component {
  constructor(props) {
    super(props);

    this.state = {
      requestsToFillRequests: props.requestsToFillRequests,
      specificRequest: props.specificRequest,
      viewModal: props.viewModal,

      showAlertSuccessfullyApprovedRequest: props.showAlertSuccessfullyApprovedRequest,
      successMsg: props.successMsg,
    };
    this.updateCurrentSpecificRequest = this.updateCurrentSpecificRequest.bind(this);
  }

  componentDidMount() {
    axios.get('/replacements-waiting').then((res) => {
      this.setState({
        requestsToFillRequests: res.data,
      });
    });
  }

  toggleViewModal = () => {
    this.setState((prevState) => ({
      viewModal: !prevState.viewModal,
    }));
  };

  toggleSuccessAlert = () => {
    const { props } = this;
    this.setState({
      showAlertSuccessfullyApprovedRequest: props.showAlertSuccessfullyApprovedRequest,
      successMsg: props.successMsg,
    });
  };

  updateAfterApprovingRequest = (replacerName) => {
    this.setState({
      showAlertSuccessfullyApprovedRequest: true,
      successMsg: `Successfully approved ${replacerName} for the job.`,
    });
    axios.get('/replacements-waiting').then((res) => {
      this.setState({
        requestsToFillRequests: res.data,
      });
    });
  };

  updateCurrentSpecificRequest(request) {
    this.toggleViewModal();
    this.setState({
      specificRequest: request,
    });
  }

  render() {
    const curState = this.state;
    console.log('ViewRequestsToFillRequests props: ', this.props);
    console.log('ViewRequestsToFillRequests state: ', curState);
    const requestsToFillRequest = curState.requestsToFillRequests;
    let i = -1;
    let trs = [];
    trs = requestsToFillRequest.map((request) => {
      i += 1;
      const date = ymdToMonthName(SqlUTCToNYTime(request.date_start).slice(0, 10));
      const timeIn = SqlUTCToNYTime(request.date_start).slice(11, 17);
      const timeOut = SqlUTCToNYTime(request.date_end).slice(11, 17);
      const jobType = request.workType;
      const requester = `${request.firstName} ${request.lastName}`;
      const { status } = request;
      return (
        <tr key={i}>
          <td key={`${request.replacementID}-requester`}>{requester}</td>
          <td key={`${request.replacementID}-jobType`}>{jobType}</td>
          <td key={`${request.replacementID}-date`}>{date}</td>
          <td key={`${request.replacementID}-time`}>{`${timeIn}-${timeOut}`}</td>
          <td key={`${request.replacementID}-status`} style={{ color: colorDict[status] }}>
            <b>{status}</b>
          </td>
          <td key={`${request.replacementID}-viewButton`}>
            <Button
              variant="primary"
              onClick={() => this.updateCurrentSpecificRequest(request, i)}
              size="sm"
            >
              View
            </Button>
          </td>
        </tr>
      );
    });
    if (curState.requestsToFillRequests.length !== 0) {
      return (
        <div>
          <p className="text-muted">All filled requests waiting for approval from admins: </p>
          <Table responsive bordered hover>
            <thead>
              <tr className="header-row">
                <th>Requester</th>
                <th>Job Type</th>
                <th>Date</th>
                <th>Time</th>
                <th>Replacement Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>{trs}</tbody>
          </Table>
          {curState.showAlertSuccessfullyApprovedRequest && (
            <Alert variant="success" onClose={this.toggleSuccessAlert} dismissible>
              {curState.successMsg}
            </Alert>
          )}
          {curState.viewModal && (
            <ViewRequestModal
              show={curState.viewModal}
              onHide={this.toggleViewModal}
              replacementRequest={curState.specificRequest}
              toggleViewModalParentCallback={this.toggleViewModal}
              approvedRequestParentCallback={this.updateAfterApprovingRequest}
            />
          )}
        </div>
      );
    }
    return (
      <div>
        <p className="text-muted">Currently is no waiting replacement request.</p>
      </div>
    );
  }
}

ViewRequestsToFillRequests.propTypes = {
  requestsToFillRequests: propTypes.arrayOf(
    propTypes.shape({
      comment: propTypes.string,
      date_end: propTypes.string,
      date_start: propTypes.string,
      expLabID: propTypes.number,
      expRecitationID: propTypes.number,
      firstName: propTypes.string,
      lastName: propTypes.string,
      num_cron_approved_reminders_sent: propTypes.number,
      num_cron_warnings_sent: propTypes.number,
      replacementID: propTypes.number,
      sectionID: propTypes.number,
      status: propTypes.string,
      userID: propTypes.number,
      workType: propTypes.string,
      workTypeID: propTypes.number,
    }),
  ),
  specificRequest: propTypes.shape({
    replacementID: propTypes.number,
    userID: propTypes.number,
    status: propTypes.string,
    workTypeID: propTypes.number,
    date_start: propTypes.string,
    date_end: propTypes.string,
    comment: propTypes.string,
    sectionID: propTypes.number,
    expLabID: propTypes.number,
    expLabName: propTypes.string,
    expLabShortName: propTypes.string,
    expRecitationID: propTypes.number,
    expRecitationName: propTypes.string,
    expRecitationShortName: propTypes.string,
    firstName: propTypes.string,
    lastName: propTypes.string,
    num_cron_warnings_sent: propTypes.number,
    num_cron_approved_reminders_sent: propTypes.number,
    workType: propTypes.string,
  }),
  viewModal: propTypes.bool,

  showAlertSuccessfullyApprovedRequest: propTypes.bool,
  successMsg: propTypes.string,
};

ViewRequestsToFillRequests.defaultProps = {
  requestsToFillRequests: [
    {
      comment: '-1',
      date_end: '-1',
      date_start: '-1',
      expLabID: -1,
      expRecitationID: -1,
      firstName: '-1',
      lastName: '-1',
      num_cron_approved_reminders_sent: -1,
      num_cron_warnings_sent: -1,
      replacementID: -1,
      sectionID: -1,
      status: '-1',
      userID: -1,
      workType: '-1',
      workTypeID: -1,
    },
  ],
  specificRequest: {
    replacementID: -1,
    userID: -1,
    status: '-1',
    workTypeID: -1,
    date_start: '-1',
    date_end: '-1',
    comment: '-1',
    sectionID: -1,
    expLabID: -1,
    expLabName: '-1',
    expLabShortName: '-1',
    expRecitationID: -1,
    expRecitationName: '-1',
    expRecitationShortName: '-1',
    firstName: '-1',
    lastName: '-1',
    num_cron_warnings_sent: -1,
    num_cron_approved_reminders_sent: -1,
    workType: '-1',
  },
  viewModal: false,

  showAlertSuccessfullyApprovedRequest: false,
  successMsg: "You shouldn't be seeing this. Contact webdev.",
};

ViewRequestModal.propTypes = {
  show: propTypes.bool,
  onHide: propTypes.func,
  replacementRequest: propTypes.shape({
    replacementID: propTypes.number,
    userID: propTypes.number,
    status: propTypes.string,
    workTypeID: propTypes.number,
    date_start: propTypes.string,
    date_end: propTypes.string,
    comment: propTypes.string,
    sectionID: propTypes.number,
    expLabID: propTypes.number,
    expLabName: propTypes.string,
    expLabShortName: propTypes.string,
    expRecitationID: propTypes.number,
    expRecitationName: propTypes.string,
    expRecitationShortName: propTypes.string,
    firstName: propTypes.string,
    lastName: propTypes.string,
    num_cron_warnings_sent: propTypes.number,
    num_cron_approved_reminders_sent: propTypes.number,
    workType: propTypes.string,
  }),
  requestsToFillRequest: propTypes.arrayOf(
    propTypes.shape({
      comment: propTypes.string,
      date_end: propTypes.string,
      date_start: propTypes.string,
      expLabID: propTypes.number,
      expRecitationID: propTypes.number,
      firstName: propTypes.string,
      lastName: propTypes.string,
      num_cron_approved_reminders_sent: propTypes.number,
      num_cron_warnings_sent: propTypes.number,
      replacementID: propTypes.number,
      sectionID: propTypes.number,
      status: propTypes.string,
      userID: propTypes.number,
      workType: propTypes.string,
      workTypeID: propTypes.number,
    }),
  ),
  toggleViewModalParentCallback: propTypes.func,
  approvedRequestParentCallback: propTypes.func,

  loadSpinner: propTypes.bool,
  success: propTypes.bool,
  error: propTypes.bool,
  successMsg: propTypes.string,
  errorMsg: propTypes.string,

  approve: propTypes.func,
  deny: propTypes.func,
};

ViewRequestModal.defaultProps = {
  show: true,
  onHide: () => {},
  replacementRequest: {
    replacementID: -1,
    userID: -1,
    status: '-1',
    workTypeID: -1,
    date_start: '-1',
    date_end: '-1',
    comment: '-1',
    sectionID: -1,
    expLabID: -1,
    expLabName: '-1',
    expLabShortName: '-1',
    expRecitationID: -1,
    expRecitationName: '-1',
    expRecitationShortName: '-1',
    firstName: '-1',
    lastName: '-1',
    num_cron_warnings_sent: -1,
    num_cron_approved_reminders_sent: -1,
    workType: '-1',
  },
  requestsToFillRequest: [],
  toggleViewModalParentCallback: () => {},
  approvedRequestParentCallback: () => {},

  loadSpinner: false,
  success: false,
  error: false,
  successMsg: 'Contact webdev, this is an error.',
  errorMsg: 'Contact webdev, this is an error.',

  approve: () => false, // used for testing
  deny: () => false, // used for testing
};
