import React, { Component } from 'react';
import propTypes from 'prop-types';

import SelectGroupAndSldp from '../../components/contracts/selectGroupAndSldp';
import CreateContractForm from '../../components/contracts/createContractForm';

export default class Contracts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isCreatingContract: props.isCreatingContract, // boolean that tells us if we're currently creating a contract
      sldpID: props.sldpID, // if isCreatingContract, let's us know for which SLDP
      groupID: props.groupID, // if isCreatingContract, let's us know for which group
    };

    this.toggleCreatingContract = this.toggleCreatingContract.bind(this);
  }

  toggleCreatingContract(sldpID, groupID) {
    this.setState((prevState) => ({
      isCreatingContract: !prevState.isCreatingContract,
      sldpID,
      groupID,
    }));
  }

  render() {
    const curState = this.state;
    return (
      <div>
        {!curState.isCreatingContract && (
          <SelectGroupAndSldp startCreatingContract={this.toggleCreatingContract} />
        )}
        {curState.isCreatingContract && (
          <CreateContractForm sldpID={curState.sldpID} groupID={curState.groupID} />
        )}
      </div>
    );
  }
}

Contracts.propTypes = {
  isCreatingContract: propTypes.bool,
  sldpID: propTypes.string,
  groupID: propTypes.string,
};

Contracts.defaultProps = {
  isCreatingContract: false,
  sldpID: '-1',
  groupID: '-1',
};
