const moment = require('moment-timezone'); // used to convert between timezones

export default function SqlUTCToNYTime(date, readable = true) {
  // input: 2020-06-27 20:02:00

  let ret = moment.utc(date, 'YYYY-MM-DD HH:mm:ss').tz('America/New_York');

  if (readable) {
    ret = ret.format('YYYY-MM-DD HH:mm');
  } else {
    ret = ret.format();
  }

  // console.log("STARTED WITH: ", date);
  // console.log("ENDED WITH: ", ret)

  // TODO: the DB is currently returning all dates assuming that they're in ny time and then converts them to UTC for us. this is throwing everything off.

  return ret;
}
