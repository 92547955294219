import React, { Component } from 'react';
import propTypes from 'prop-types';
import axios from 'axios';

import { Tab, Tabs } from 'react-bootstrap';

import GradebookForSection from '../../components/gradebook/gradebookForSection';

export default class Gradebook extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sections: props.sections,
      gradeCategories: props.gradeCategories,
      gradeItems: props.gradeItems,
      gradebook: props.gradebook,
    };
  }

  componentDidMount() {
    axios.get('/sections/').then((res) => {
      // console.log("response",res);
      this.setState({
        sections: res.data,
      });
    });
    axios.get('/gradeCategories/').then((res) => {
      // console.log("response",res);
      this.setState({
        gradeCategories: res.data,
      });
    });
    axios.get('/gradeItems/').then((res) => {
      // console.log("response",res);
      this.setState({
        gradeItems: res.data,
      });
    });
    axios.get('/gradebook/').then((res) => {
      // console.log("response",res);
      this.setState({
        gradebook: res.data,
      });
    });
  }

  render() {
    const curState = this.state;
    const allSections = curState.sections;
    const gradeCats = curState.gradeCategories;
    const { gradeItems } = curState;
    const { gradebook } = curState;
    console.log('GRADEBOOK STATE: ', curState);

    console.log('GRADEBOOK IN INDEX: ', gradebook);

    const sectionTabs = allSections.map((section) => (
      <Tab key={section.secID} eventKey={section.secID} title={section.secName}>
        <GradebookForSection
          gradebook={gradebook}
          gradeItems={gradeItems}
          gradeCategories={gradeCats}
          sectionID={section.secID}
          sectionName={section.secName}
        />
      </Tab>
    ));
    return (
      <Tabs className="section-tabs" id="gradebook-sections-tabs">
        {sectionTabs}
      </Tabs>
    );
  }
}

Gradebook.propTypes = {
  sections: propTypes.arrayOf(
    propTypes.shape({
      active: propTypes.number,
      isHSSec: propTypes.number,
      labRoom: propTypes.string,
      lecRoom: propTypes.string,
      recRoom: propTypes.string,
      secGrader: propTypes.number,
      secID: propTypes.number,
      secName: propTypes.string,
      secObserver: propTypes.number,
      secProf: propTypes.number,
      secRecTA: propTypes.number,
      secRoamer: propTypes.number,
      secTA1: propTypes.number,
      secTA2: propTypes.number,
      secWC: propTypes.number,
      secgroupID: propTypes.number,
      secgroupName: propTypes.string,
    }),
  ),
  gradeCategories: propTypes.arrayOf(
    propTypes.shape({
      id: propTypes.number,
      name: propTypes.string,
    }),
  ),
  gradeItems: propTypes.arrayOf(
    propTypes.shape({
      cat: propTypes.number,
      extID: propTypes.number,
      id: propTypes.number,
      isLetter: propTypes.number,
      max: propTypes.number,
      name: propTypes.string,
      seq: propTypes.number,
      submitID: propTypes.number,
      weight: propTypes.number,
    }),
  ),
  gradebook: propTypes.arrayOf(
    propTypes.shape({
      grade: propTypes.number,
      gradetext: propTypes.string,
      id: propTypes.number,
      itemID: propTypes.number,
      uid: propTypes.number,
    }),
  ),
};

Gradebook.defaultProps = {
  sections: [
    {
      active: -1,
      isHSSec: -1,
      labRoom: '-1',
      lecRoom: '-1',
      recRoom: '-1',
      secGrader: -1,
      secID: -1,
      secName: '-1',
      secObserver: -1,
      secProf: -1,
      secRecTA: -1,
      secRoamer: -1,
      secTA1: -1,
      secTA2: -1,
      secWC: -1,
      secgroupID: -1,
      secgroupName: '-1',
    },
  ],
  gradeCategories: [
    {
      id: -1,
      name: '-1',
    },
  ],
  gradeItems: [
    {
      cat: -1,
      extID: -1,
      id: -1,
      isLetter: -1,
      max: -1,
      name: '-1',
      seq: -1,
      submitID: -1,
      weight: -1,
    },
  ],
  gradebook: [
    {
      grade: -1,
      gradetext: '-1',
      id: -1,
      itemID: -1,
      uid: -1,
    },
  ],
};
