import React, { Component } from 'react';
import propTypes from 'prop-types';
import axios from 'axios';
import { Form } from 'react-bootstrap';

export default class RadDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      radGroups: props.radGroups,
    };
    this.updateCurSelection = this.updateCurSelection.bind(this);
  }

  componentDidMount() {
    axios.get('/sldpmanagement-rad').then((res) => {
      this.setState({
        radGroups: res.data,
      });
    });
  }

  updateCurSelection(e) {
    const { props } = this;
    props.parentCallback(e.target.value);
  }

  render() {
    const { props } = this;
    const curState = this.state;
    const allRadGroups = curState.radGroups;
    const selectOptions = allRadGroups.map((rad) => (
      <option id={rad.sldpID} key={rad.sldpID} value={rad.sldpID}>
        {rad.shortName}
      </option>
    ));

    return (
      <div>
        <Form.Control value={props.default} as="select" onChange={this.updateCurSelection}>
          <option value="-1">---Select RAD---</option>
          {selectOptions}
        </Form.Control>
      </div>
    );
  }
}

RadDropdown.propTypes = {
  radGroups: propTypes.arrayOf(
    propTypes.shape({
      sldpID: propTypes.number,
      shortName: propTypes.string,
      longName: propTypes.string,
      isActive: propTypes.number,
      isSubOf: propTypes.number,
    }),
  ),
  parentCallback: propTypes.func,
  default: propTypes.string,
};

RadDropdown.defaultProps = {
  radGroups: [
    {
      sldpID: -1,
      shortName: '-1',
      longName: '-1',
      isActive: -1,
      isSubOf: -1,
    },
  ],
  parentCallback: () => {},
  default: '-1',
};
