import React, { Component } from 'react';
import propTypes from 'prop-types';
import axios from 'axios';
import { Form } from 'react-bootstrap';

export default class RecPresentationsDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      presentations: props.presentations,
    };
    this.updateCurSelection = this.updateCurSelection.bind(this);
  }

  componentDidMount() {
    axios.get('/rec-presentations/').then((res) => {
      console.log('response', res);
      this.setState({
        presentations: res.data,
      });
    });
  }

  updateCurSelection(e) {
    const { props } = this;
    props.parentCallback(e.target.value);
  }

  render() {
    const { props } = this;
    const curState = this.state;
    const allPresentations = curState.presentations;
    const selectOptions = allPresentations.map((presentation) => (
      <option
        id={presentation.expRecitationID}
        key={presentation.expRecitationID}
        value={presentation.expRecitationID}
      >
        {`${presentation.expRecitationShortName}: ${presentation.expRecitationName}`}
      </option>
    ));

    return (
      <div>
        <Form.Control value={props.default} as="select" onChange={this.updateCurSelection}>
          <option value="-1">---Select Presentation---</option>
          {selectOptions}
        </Form.Control>
      </div>
    );
  }
}

RecPresentationsDropdown.propTypes = {
  presentations: propTypes.arrayOf(
    propTypes.shape({
      expRecitationID: propTypes.number,
      expRecitationShortName: propTypes.string,
      expRecitationName: propTypes.string,
    }),
  ),
  parentCallback: propTypes.func,
  default: propTypes.string,
};

RecPresentationsDropdown.defaultProps = {
  presentations: [
    {
      expRecitationID: -1,
      expRecitationShortName: '-1',
      expRecitationName: '-1',
    },
  ],
  default: '-1',
  parentCallback: () => {},
};
