import React, { Component } from 'react';
import propTypes from 'prop-types';

import axios from 'axios';

import { Form, Col, Button, Modal } from 'react-bootstrap';

import SectionsDropdown from '../../components/convenientDropdowns/sectionsDropdown';

const config = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
};
const qs = require('querystring-es3');

export default class Register extends Component {
  constructor(props) {
    super(props);

    this.state = {
      curEmail: props.curEmail,
      curFirstName: props.curFirstName,
      curLastName: props.curLastName,
      curNNumber: props.curNNumber,
      curBarcode: props.curBarcode,
      curConfirmedBarcode: props.curConfirmedBarcode,
      curSectionID: props.curSectionID,

      showSubmitModal: props.showSubmitModal,
      showIncompleteFormModal: props.showIncompleteFormModal,
      incompleteFormModalMessage: props.incompleteFormModalMessage,
    };
  }

  updateCurEmail = (e) => {
    this.setState({
      curEmail: e.target.value,
    });
  };

  updateCurFirstName = (e) => {
    this.setState({
      curFirstName: e.target.value,
    });
  };

  updateCurLastName = (e) => {
    this.setState({
      curLastName: e.target.value,
    });
  };

  updateCurNNumber = (e) => {
    this.setState({
      curNNumber: e.target.value,
    });
  };

  updateCurBarcode = (e) => {
    this.setState({
      curBarcode: e.target.value,
    });
  };

  updateCurConfirmedBarcode = (e) => {
    this.setState({
      curConfirmedBarcode: e.target.value,
    });
  };

  updateCurSectionID = (newSectionID) => {
    this.setState({
      curSectionID: newSectionID,
    });
  };

  toggleSubmitModal = () => {
    this.setState((prevState) => ({
      showSubmitModal: !prevState.showSubmitModal,
    }));
  };

  toggleIncompleteFormModal = ({ incompleteFormModalMessage } = this.props) => {
    this.setState((prevState) => ({
      showIncompleteFormModal: !prevState.showIncompleteFormModal,
      incompleteFormModalMessage,
    }));
  };

  validateInputs = (submissionObj) => {
    console.log('Validating ', submissionObj);
    const curEmail = submissionObj.userEmail;
    const curFirstName = submissionObj.firstName;
    const curLastName = submissionObj.lastName;
    const curNNumber = submissionObj.idNum;
    const curBarcode = submissionObj.barcode;
    const { curConfirmedBarcode } = submissionObj;
    const curSectionID = submissionObj.sectionID;
    if (curEmail === '-1') {
      this.toggleIncompleteFormModal('Forgot to fill out the email field.');
      return 0;
    }
    if (curFirstName === '-1') {
      this.toggleIncompleteFormModal('Forgot to fill out the first name field.');
      return 0;
    }
    if (curLastName === '-1') {
      this.toggleIncompleteFormModal('Forgot to fill out the last name field.');
      return 0;
    }
    if (curNNumber === '-1') {
      this.toggleIncompleteFormModal('Forgot to fill out the NNumber field.');
      return 0;
    }
    if (curBarcode === '-1') {
      this.toggleIncompleteFormModal('Forgot to fill out the barcode field.');
      return 0;
    }
    if (curConfirmedBarcode === '-1') {
      this.toggleIncompleteFormModal('Forgot to fill out the confirmed barcode field.');
      return 0;
    }
    if (curSectionID === '-1') {
      this.toggleIncompleteFormModal('Forgot to select a section.');
      return 0;
    }
    if (curBarcode !== curConfirmedBarcode) {
      this.toggleIncompleteFormModal('Your barcode values do not match.');
      return 0;
    }
    if (curNNumber[0] !== 'N') {
      this.toggleIncompleteFormModal("Invalid N Number. Make sure you start it with 'N'.");
      return 0;
    }
    if (curNNumber.length !== 9) {
      this.toggleIncompleteFormModal(
        "Invalid N Number. Make sure you start it with 'N' and have the proper number of digits.",
      );
      return 0;
    }
    if (curBarcode.length !== 14) {
      this.toggleIncompleteFormModal('Invalid barcode. Please try again.');
      return 0;
    }
    return 1;
  };

  submitRegistration = () => {
    const curState = this.state;
    const submissionObj = {
      userEmail: curState.curEmail,
      firstName: curState.curFirstName,
      lastName: curState.curLastName,
      idNum: curState.curNNumber,
      barcode: curState.curBarcode,
      curConfirmedBarcode: curState.curConfirmedBarcode,
      sectionID: curState.curSectionID,
      groupID: 5, // default group ID for students
      report_pin: 0, // only needed for TAs
      quit: 0, // if student dropped out of the course
    };
    if (this.validateInputs(submissionObj)) {
      console.log('Form validated. Posting to API.');
      axios
        .post('/users/', qs.stringify(submissionObj), config)
        .then((response) => {
          console.log(response);
          window.location.replace('/'); // TODO: Eventually change to a non hard refresh
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  render() {
    const curState = this.state;
    return (
      <div>
        <h1>User Registration</h1>
        <p>Enter your information below to register for an account on the EG-1003 site.</p>
        <Modal show={curState.showSubmitModal} onHide={this.toggleSubmitModal} animation>
          <Modal.Header closeButton>
            <Modal.Title>Are you sure you want to submit?</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>
              Make sure all the information you entered is correct before proceeding (double-check
              the barcode).
            </p>
          </Modal.Body>

          <Modal.Footer>
            <Button onClick={this.toggleSubmitModal} variant="secondary">
              Keep Writing
            </Button>
            <Button
              onClick={() => {
                this.submitRegistration();
                this.toggleSubmitModal();
              }}
              variant="primary"
            >
              Submit{' '}
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={curState.showIncompleteFormModal}
          onHide={() => this.toggleIncompleteFormModal('Contact webdev')}
          animation
        >
          <Modal.Header closeButton>
            <Modal.Title>Form is incomplete!</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>Make sure you entered all the necessary information before pressing submit.</p>
            <p>
              <b>Error: </b>
              {curState.incompleteFormModalMessage}
            </p>
          </Modal.Body>

          <Modal.Footer>
            <Button
              onClick={() => this.toggleIncompleteFormModal('Contact webdev')}
              variant="primary"
            >
              Okay
            </Button>
          </Modal.Footer>
        </Modal>
        <Form>
          <Form.Row>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" placeholder="Enter email" onBlur={this.updateCurEmail} />
            </Form.Group>
            <Form.Group as={Col} controlId="formNNumber">
              <Form.Label>N Number</Form.Label>
              <Form.Control placeholder="ex: N12345678" onBlur={this.updateCurNNumber} />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} controlId="formFirstName">
              <Form.Label>First Name</Form.Label>
              <Form.Control placeholder="Enter first name" onBlur={this.updateCurFirstName} />
            </Form.Group>

            <Form.Group as={Col} controlId="formLastName">
              <Form.Label>Last Name</Form.Label>
              <Form.Control placeholder="Enter last name" onBlur={this.updateCurLastName} />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId="formBarcode">
              <Form.Label>NYU ID Barcode</Form.Label>
              <Form.Control placeholder="ex: 12345678901234" onBlur={this.updateCurBarcode} />
            </Form.Group>
            <Form.Group as={Col} controlId="formBarcode">
              <Form.Label>Confirm NYU ID Barcode</Form.Label>
              <Form.Control
                placeholder="please make sure it's correct!"
                onBlur={this.updateCurConfirmedBarcode}
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlID="formSection">
              <Form.Label>Section</Form.Label>
              <SectionsDropdown
                default={curState.curSectionID}
                parentCallback={this.updateCurSectionID}
              />
            </Form.Group>
          </Form.Row>

          <Button variant="primary" onClick={this.toggleSubmitModal}>
            Submit
          </Button>
        </Form>
      </div>
    );
  }
}

Register.propTypes = {
  curEmail: propTypes.string,
  curFirstName: propTypes.string,
  curLastName: propTypes.string,
  curNNumber: propTypes.string,
  curBarcode: propTypes.string,
  curConfirmedBarcode: propTypes.string,
  curSectionID: propTypes.string,

  showSubmitModal: propTypes.bool,
  showIncompleteFormModal: propTypes.bool,

  incompleteFormModalMessage: propTypes.string,
};

Register.defaultProps = {
  curEmail: '-1',
  curFirstName: '-1',
  curLastName: '-1',
  curNNumber: '-1',
  curBarcode: '-1',
  curConfirmedBarcode: '-1',
  curSectionID: '-1',

  showSubmitModal: false,
  showIncompleteFormModal: false,
  incompleteFormModalMessage: "You shouldn't be seeing this. Contact webdev.",
};
