export default function sqlToReadable(date) {
  const dateStr = date.toString();
  const dateMapper = {
    '01': 'January',
    '02': 'February',
    '03': 'March',
    '04': 'April',
    '05': 'May',
    '06': 'June',
    '07': 'July',
    '08': 'August',
    '09': 'September',
    10: 'October',
    11: 'November',
    12: 'December',
  };

  // default input: 9999-01-02T04:23:23.000Z

  const year = dateStr.slice(0, 4);
  const month = dateMapper[dateStr.slice(5, 7)];
  const day = dateStr.slice(8, 10);

  const hour = dateStr.slice(11, 13);
  const minute = dateStr.slice(14, 16);
  const second = dateStr.slice(17, 19);

  const ret = `${month} ${day}, ${year} ${hour}:${minute}:${second}`;

  return ret;
}
