import React from 'react';
import Spin from 'react-bootstrap/Spinner';
import styled from 'styled-components';

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 10px;
`;

export const Spinner = () => (
  <SpinnerWrapper>
    <Spin animation="border" />
  </SpinnerWrapper>
);

export default Spinner;
