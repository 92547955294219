import axios from 'axios';
import propTypes from 'prop-types';
import React, { Component } from 'react';
import { Table, Button } from 'react-bootstrap';
import DateSelector from '../dateSelector/dateSelector';
import utcISOToSql from '../../../utils/utcISOToSql';

const config = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
};
const qs = require('querystring-es3');

const HOST_NAME = 'https://storage.googleapis.com';
const BUCKET_NAME = '/egsitenew-experimental';
const LECTURE_DIR = '/lectures';

export default class LecturesManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lectures: props.lectures,
      newOrder: props.newOrder,

      editingLectureId: props.editingLectureId,
      editingOrder: props.editingOrder,
      editingName: props.editingName,
      editingDate: props.editingDate,
      editingFilePath: props.editingFilePath,

      addingName: props.addingName,
      addingDate: props.addingDate,
    };
  }

  componentDidMount() {
    axios.get('/lectures').then((res) => {
      this.setState({
        lectures: res.data,
        newOrder: res.data.length + 1,
      });
    });
  }

  toggleEditingLecture = (lecture = {}) => {
    if (lecture === {}) {
      this.setState({
        editingLectureId: -1,
      });
    } else {
      this.setState({
        editingLectureId: lecture.id,
        editingOrder: lecture.ordering,
        editingName: lecture.name,
        editingDate: lecture.date,
        editingFilePath: lecture.filepath,
      });
    }
  };

  updateEditingName = (e) => {
    this.setState({
      editingName: e.target.value,
    });
  };

  updateEditingDate = (e) => {
    console.log(e.target.value, new Date(e.target.value));
    this.setState({
      editingDate: new Date(e.target.value),
    });
  };

  updateAddingName = (e) => {
    this.setState({
      addingName: e.target.value,
    });
  };

  updateAddingDate = (date) => {
    this.setState({
      addingDate: date,
    });
  };

  submitEdittedLecture = (lectureID) => {
    const curState = this.state;
    const dateTime = utcISOToSql(curState.editingDate);
    console.log(dateTime);
    const lecture = {
      id: lectureID,
      ordering: curState.editingOrder,
      name: curState.editingName,
      date: dateTime,
      filepath: curState.editingFilePath,
    };
    console.log('Edited lecture:', lecture);

    axios
      .put(`/lectures/${lectureID}`, qs.stringify(lecture), config)
      .then(() => {
        this.componentDidMount();
        this.toggleEditingLecture();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  submitAddedLecture = () => {
    const curState = this.state;
    const dateTime = utcISOToSql(curState.addingDate.toISOString());
    console.log('Added date:', dateTime);
    console.log('Added name:', curState.addingName);
    const lecture = {
      ordering: curState.newOrder,
      name: curState.addingName,
      date: dateTime,
    };
    console.log('Added lecture:', lecture);

    axios
      .post('/lectures', qs.stringify(lecture), config)
      .then(() => {
        this.componentDidMount();
        this.setState({
          addingName: '-1',
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  deleteLastLecture = () => {
    const curState = this.state;
    const lastOrdering = curState.newOrder - 1;
    axios
      .delete(`/lectures-order/${lastOrdering}`, config)
      .then(() => {
        this.componentDidMount();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  deleteLectureSlides = (lecture) => {
    const lectureDeleted = {
      directory: LECTURE_DIR,
      file: lecture.filepath,
    };
    const lectureObj = {
      id: lecture.id,
      ordering: lecture.ordering,
      name: lecture.name,
      date: utcISOToSql(lecture.date),
      filepath: null,
    };
    axios
      .post('/delete-file', qs.stringify(lectureDeleted), config)
      .then((res) => {
        console.log(res.data);
        axios
          .put(`/lectures/${lecture.id}`, qs.stringify(lectureObj), config)
          .then(() => {
            window.location.reload(false);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const curState = this.state;
    const AllLectures = curState.lectures;
    const trs = AllLectures.map((lecture) => {
      const { ordering } = lecture;
      const { name } = lecture;
      // Keep the time in `yyyy-MM-dd` format.
      const date = lecture.date.split('T')[0];
      const lecturePath = `${HOST_NAME + BUCKET_NAME + LECTURE_DIR}/${lecture.filepath}`;
      if (lecture.id === curState.editingLectureId) {
        return (
          <tr key={`editing-tr-${lecture.id}`}>
            <td key={`editing-ordering-${lecture.id}`}>{ordering}</td>
            <td key={`editing-name-${lecture.id}`}>
              <input key="editing-input-name" defaultValue={name} onBlur={this.updateEditingName} />
            </td>
            <td key={`date-${lecture.id}`}>
              <input key="editing-input-date" defaultValue={date} onBlur={this.updateEditingDate} />
            </td>
            {lecture.filepath && (
              <td key={`filepath-${lecture.id}`}>
                <Button
                  varient="primary"
                  className="btn-sm"
                  onClick={() => this.deleteLectureSlides(lecture)}
                >
                  Delete Slides
                </Button>
              </td>
            )}
            {!lecture.filepath && <td key={`filepath-${lecture.id}`}>Not Avaliable</td>}
            <td style={{ whiteSpace: 'nowrap' }}>
              <Button onClick={this.toggleEditingLecture} variant="secondary">
                Cancel
              </Button>
              <Button onClick={() => this.submitEdittedLecture(lecture.id)} variant="success">
                Confirm
              </Button>
            </td>
          </tr>
        );
      }
      return (
        <tr key={lecture.id}>
          <td key={`ordering-${lecture.id}`}>{ordering}</td>
          <td key={`name-${lecture.id}`}>{name}</td>
          <td key={`date-${lecture.id}`}>{date}</td>
          {lecture.filepath && (
            <td key={`filepath-${lecture.id}`}>
              <a href={lecturePath}>View Lecture Slides</a>
            </td>
          )}
          {!lecture.filepath && <td key={`filepath-${lecture.id}`}>Not Avaliable</td>}
          <td style={{ whiteSpace: 'nowrap' }}>
            <Button onClick={() => this.toggleEditingLecture(lecture)} variant="primary">
              Edit
            </Button>
          </td>
        </tr>
      );
    });
    trs.push(
      <tr key="adding-tr">
        <td key="adding-ordering">{curState.newOrder}</td>
        <td key="adding-name">
          <input
            key="adding-input-namd"
            placeholder="Enter name for new lecture"
            onChange={this.updateAddingName}
            value={curState.addingName === '-1' ? '' : curState.addingName}
          />
        </td>
        <td key="adding-date">
          <DateSelector parentCallback={this.updateAddingDate} />
        </td>
        <td>NULL</td>
        <td>
          <Button className="btn-sm" onClick={() => this.submitAddedLecture()} variant="primary">
            Add Lecture
          </Button>
        </td>
      </tr>,
    );
    return (
      <>
        <div className="mb-3">
          <p className="text-muted">
            Refresh after adding new lecture to view the dropdown update.
          </p>
          <Table responsive striped bordered hover>
            <thead>
              <tr>
                <th>Order</th>
                <th>Lecture</th>
                <th>Date</th>
                <th>File</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>{trs}</tbody>
          </Table>
          <Button className="mt-1" onClick={() => this.deleteLastLecture()} variant="danger">
            Delete Last Lecture
          </Button>
        </div>
      </>
    );
  }
}

LecturesManagement.propTypes = {
  lectures: propTypes.arrayOf(
    propTypes.shape({
      name: propTypes.string,
      id: propTypes.number,
      ordering: propTypes.number,
      date: propTypes.string,
      filepath: propTypes.string,
    }),
  ),
  newOrder: propTypes.number,
  editingLectureId: propTypes.number,
  editingOrder: propTypes.number,
  editingName: propTypes.string,
  editingDate: propTypes.string,
  editingFilePath: propTypes.string,
  addingName: propTypes.string,
  addingDate: propTypes.string,
};

LecturesManagement.defaultProps = {
  lectures: [
    {
      name: '-1',
      id: -1,
      ordering: -1,
      date: '-1',
      filepath: '-1',
    },
  ],
  newOrder: -1,
  editingLectureId: -1,
  editingOrder: -1,
  editingName: '-1',
  editingDate: '-1',
  editingFilePath: '-1',
  addingName: '-1',
  addingDate: '-1',
};
