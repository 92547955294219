import React, { Component } from 'react';
import { Card, Button, Modal } from 'react-bootstrap';
import propTypes from 'prop-types';
import axios from 'axios';

import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import sqlToJsDate from '../../../utils/date';

import './announcements.scss';

const config = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
};
const qs = require('querystring-es3');

export default class AnnouncementCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isEditing: !!props.isNew,
      title: props.title,
      body: props.body,
      showDeleteModal: false,
    };

    this.toggleEdit = this.toggleEdit.bind(this);
    this.postClicked = this.postClicked.bind(this);
    this.updateTitle = this.updateTitle.bind(this);
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.deleteAnnouncement = this.deleteAnnouncement.bind(this);
  }

  toggleEdit() {
    this.setState((prevState) => ({
      isEditing: !prevState.isEditing,
    }));
  }

  toggleDeleteModal() {
    this.setState((prevState) => ({
      showDeleteModal: !prevState.showDeleteModal,
    }));
  }

  deleteAnnouncement() {
    const { id } = this.props;

    console.log('Deleting announcement with id', id);

    axios
      .delete(`/announcements/${id}`, config)
      .then((response) => {
        console.log(response);
        window.location.replace('/'); // TODO: Eventually change to a non hard refresh to homepage
      })
      .catch((err) => {
        console.log(err);
      });
    this.toggleDeleteModal();
  }

  postClicked() {
    const curState = this.state;
    const { id } = this.props;
    const { title } = this.state;
    const body = curState.editor.getData();

    console.log('Clicked', body);

    if (id !== -1) {
      axios
        .put(`/announcements/${id}`, qs.stringify({ title, body }), config)
        .then((response) => {
          console.log(response);
          this.setState({
            title,
            body,
          });
          this.toggleEdit();
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      axios
        .post('/announcements/', qs.stringify({ title, body }), config)
        .then((response) => {
          console.log(response);
          window.location.replace('/'); // TODO: Eventually change to a non hard refresh to homepage
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  updateTitle(e) {
    this.setState({
      title: e.target.value,
    });
  }

  render() {
    const curState = this.state;
    let { posted, edited } = this.props;
    const { title, body } = this.state;
    if (edited) {
      edited = edited.slice(0, 19).replace('T', ' ');
    }

    console.log('curState.editorState', curState.editorState);
    posted = posted.slice(0, 19).replace('T', ' ');
    // console.log("posted ", posted);
    // const title = get(this.props,"title","");
    // const body = get(this.props,"body","");
    // const posted = get(this.props,"posted","");
    if (curState.isEditing) {
      return (
        <div className="announcement">
          <Card>
            <Card.Header>
              <input
                className="announcement__title-input"
                defaultValue={title}
                placeholder="Enter title here..."
                style={{ color: '#57068c', 'font-weight': '500' }}
                onChange={this.updateTitle}
              />
            </Card.Header>
            <Card.Body>
              <Card.Text>
                <CKEditor
                  editor={ClassicEditor}
                  data={body}
                  onInit={(editor) => {
                    // You can store the "editor" and use when it is needed.
                    this.setState({
                      editor,
                    });
                    console.log('Editor is ready to use!', editor);
                  }}
                />
              </Card.Text>
            </Card.Body>
          </Card>
          <br />
          <Button variant="primary" size="sm" onClick={this.postClicked}>
            Post
          </Button>
        </div>
      );
    }
    return (
      <div className="announcement">
        <Modal show={curState.showDeleteModal} onHide={this.toggleDeleteModal} animation>
          <Modal.Header closeButton>
            <Modal.Title>Are you sure you want to delete?</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>This will be irreversable...</p>
          </Modal.Body>

          <Modal.Footer>
            <Button onClick={this.toggleDeleteModal} variant="primary" size="sm">
              Close
            </Button>
            <Button onClick={this.deleteAnnouncement} variant="secondary" size="sm">
              Delete{' '}
            </Button>
          </Modal.Footer>
        </Modal>
        <Card>
          <Card.Header>{title}</Card.Header>
          <Card.Body>
            <Card.Text>
              {/* eslint-disable-next-line react/no-danger */}
              <span dangerouslySetInnerHTML={{ __html: body }} />
            </Card.Text>
          </Card.Body>
          <Card.Footer>
            {edited ? sqlToJsDate(edited).toString() : sqlToJsDate(posted).toString()}
          </Card.Footer>
        </Card>
        <br />
        <Button variant="primary" size="sm" onClick={this.toggleEdit} style={{ marginLeft: '70%' }}>
          Edit
        </Button>
        <Button variant="primary" size="sm" onClick={this.toggleDeleteModal}>
          Delete
        </Button>
      </div>
    );
  }
}

AnnouncementCard.propTypes = {
  title: propTypes.string,
  body: propTypes.string,
  edited: propTypes.string,
  posted: propTypes.string,
  id: propTypes.number,
  isNew: propTypes.bool,
};

AnnouncementCard.defaultProps = {
  title: '',
  body: '',
  edited: '',
  posted: '',
  id: -1,
  isNew: false,
};
