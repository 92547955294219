import React from 'react';
import axios from 'axios';
import propTypes from 'prop-types';
import {
  Button,
  Form,
  Col,
  Row,
  ToggleButton,
  InputGroup,
  FormControl,
  Table,
  ButtonGroup,
} from 'react-bootstrap';
import UserPermissionsComponent from '../../../UserPermissionsComponent';
import './sldp.scss';
import { Spinner } from '../sharedComponents/spinner';

const config = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
};
const qs = require('querystring-es3');

const formMapping = {
  0: 'Benchmark A',
  1: 'Benchmark B',
  2: 'Commissioning',
};

const filterReq = (req, formtype) => {
  const filtered = [];
  const filteredExtraCred = [];
  for (let i = 0; i < req.length; i += 1) {
    if (parseInt(req[i].formID, 10) === parseInt(formtype, 10)) {
      if (req[i].isRequired === 0) {
        filteredExtraCred.push(req[i]);
      } else {
        filtered.push(req[i]);
      }
    }
  }
  return filtered.concat(filteredExtraCred);
};

export default class SldpForm extends UserPermissionsComponent {
  constructor(props) {
    super(props);
    this.state = {
      userInfo: props.userInfo,
      formName: formMapping[props.form],
      dueDate: '',
      pin: '',
      isLoading: true,
      secID: props.groupInfo[Object.keys(props.groupInfo)[0]].secID,
      requirements: filterReq(
        props.groupInfo[Object.keys(props.groupInfo)[0]].requirements,
        props.form,
      ),
      groupInfo: props.groupInfo[Object.keys(props.groupInfo)[0]],
    };
  }

  componentDidMount() {
    const curState = this.state;
    const { props } = this;
    this.setState({ isLoading: true });
    const urlAdd = `${String(curState.formName.split(' ').join('')).trim()}/${String(
      curState.secID,
    ).trim()}`;
    axios
      .get('/sldp-forms-due-date/'.concat(urlAdd))
      .then((res) => {
        if (res.data.length === 1) {
          const temp = String(new Date(res.data[0].dueDate)).trim().split(' ').slice(0, 5);
          temp[4] = String(temp[4]).slice(0, 5);
          const [week, mon, day, year, time] = temp;
          this.setState({ dueDate: `${week} ${mon} ${day}/${year} ${time}` });
        } else {
          props.alert({
            type: 1,
            title: `Due date for ${curState.formName} could not be retrieved.`,
            text: 'Incorrect number of return dates was returned',
          });
          this.setState({ dueDate: 'Could Not Retrieve' });
        }
        this.setState({ isLoading: false });
      })
      .catch((err) => {
        props.alert({
          type: 1,
          title: `Due date for ${curState.formName} could not be retrieved.`,
          text: JSON.stringify(err),
        });
        this.setState({ dueDate: 'Could Not Retrieve', isLoading: false });
      });
  }

  Submit = () => {
    if (!(this.isTA() || this.isProf())) {
      return;
    }
    this.setState({ isLoading: true });
    const { props } = this;
    const curState = this.state;
    const urlAdd = `${String(curState.userInfo.userID)}/${String(curState.pin)}`;
    let error = false;
    let changeMade = false;
    axios
      .get('/usersPinVerify/'.concat(urlAdd.trim()))
      .then((res) => {
        if (parseInt(res.data[0].count, 10) === 1) {
          const req = curState.requirements;
          for (let i = 0; i < req.length; i += 1) {
            if (req[i].new === 1) {
              changeMade = true;
              let errorMade = error;
              axios.post('/sldp-submit-new', qs.stringify(req[i]), config).catch(() => {
                props.alert({ type: 1, title: 'Update SLDP Grade Error', text: 'Submit New' });
                errorMade = true;
              });
              error = errorMade;
            } else if (req[i].percentCompleteEdit !== req[i].percentComplete) {
              changeMade = true;
              let errorMade = error;
              axios.post('/sldp-submit-update', qs.stringify(req[i]), config).catch(() => {
                props.alert({
                  type: 1,
                  title: 'Update SLDP Grade Error',
                  text: 'Update Existing',
                });
                errorMade = true;
              });
              error = errorMade;
            }
          }
        } else {
          console.log('Incorrect Password');
          props.alert({ type: 1, title: 'Password Incorrect' });
          error = true;
        }
      })
      .catch(() => {
        props.alert({ type: 1, title: 'Password Could Not Be Validated' });
        error = true;
      })
      .then(() => {
        this.setState({ isLoading: false });
        if (!error && !changeMade) {
          props.alert({ type: 1, title: 'No Changes Made' });
        } else if (!error) {
          props.alert({ type: 3, title: 'Form Sucessfully Updated' });
        }
      });
  };

  render() {
    const curState = this.state;

    if (curState.isLoading || !(this.isTA() || this.isProf())) {
      return <Spinner />;
    }

    return (
      <div sid="paddingTop">
        <Row>
          <Col>
            <b>Project Type:</b> {curState.groupInfo.sldpType}
          </Col>
          <Col>
            <b>Section :</b> {curState.groupInfo.secName}
          </Col>
        </Row>
        <Row>
          <Col>
            <b>Members:</b> {curState.groupInfo.memberName}
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <b>Current Date: </b>
            {new Date().toLocaleDateString()}
          </Col>
          <Col>
            <b>Current Time: </b>
            {new Date().toLocaleTimeString()}
          </Col>
        </Row>
        <Row>
          <Col>
            <b>
              {curState.formName} Form is due by: {curState.dueDate}
            </b>
          </Col>
        </Row>
        <Row id="paddingTop">
          <Col>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Requirements</th>
                  <th>Pass/Fail</th>
                </tr>
              </thead>
              <tbody>
                {curState.requirements.map((item, key) => (
                  <tr key={item.reqID}>
                    {item.isRequired === 1 ? (
                      <>
                        <td>
                          {item.description}
                          <span className="star">{' *'}</span>
                        </td>
                        <td>
                          <ButtonGroup>
                            <ToggleButton
                              type="radio"
                              variant="outline-success"
                              value="Pass"
                              checked={item.percentCompleteEdit === 100}
                              onChange={() => {
                                const updatedreq = [...curState.requirements];
                                updatedreq[key].percentCompleteEdit = 100;
                                this.setState({
                                  requirements: updatedreq,
                                });
                              }}
                            >
                              Pass
                            </ToggleButton>
                            <ToggleButton
                              type="radio"
                              variant="outline-danger"
                              value="Fail"
                              checked={item.percentCompleteEdit !== 100}
                              onChange={() => {
                                const updatedreq = [...curState.requirements];
                                updatedreq[key].percentCompleteEdit = 0;
                                this.setState({
                                  requirements: updatedreq,
                                });
                              }}
                            >
                              Fail
                            </ToggleButton>
                          </ButtonGroup>
                        </td>
                      </>
                    ) : (
                      <>
                        <td>
                          <span className="gray">{'Extra Credit: '}</span>
                          {item.description}
                        </td>
                        <td>
                          <div className="extraCredContainer">
                            <div id="extraCreditInput">
                              <Form.Control
                                onChange={(e) => {
                                  let val = parseInt(e.target.value, 10);
                                  if (e.target.value === '') val = '';
                                  if (
                                    ((val || val === 0) && val <= item.points) ||
                                    e.target.value === ''
                                  ) {
                                    const updatedreq = [...curState.requirements];
                                    updatedreq[key].percentCompleteEdit = val;
                                    this.setState({
                                      requirements: updatedreq,
                                    });
                                  }
                                }}
                                value={item.percentCompleteEdit}
                              />
                            </div>
                            <div id="bottomtext">{` out of ${item.points} point(s)`}</div>
                          </div>
                        </td>
                      </>
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col xs={9}>
            <p className="font20">
              <b>
                This form is currently being submitted by:{' '}
                {`${curState.userInfo.firstName} ${curState.userInfo.lastName}`}
              </b>
            </p>
          </Col>
          <Col>
            <div id="pinHolder">
              <p className="pinText">
                <b>Pin: </b>
              </p>
              <InputGroup className="mb-3" id="pinInput">
                <FormControl
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  type="password"
                  maxLength="4"
                  onChange={(e) => {
                    this.setState({ pin: e.target.value });
                  }}
                />
              </InputGroup>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Button
            variant="primary"
            disabled={
              curState.pin === '' || curState.requirements.length <= 0 || curState.isLoading
            }
            onClick={() => this.Submit()}
          >
            Submit Form
          </Button>
        </Row>
      </div>
    );
  }
}

SldpForm.propTypes = {
  form: propTypes.number,
  requirements: propTypes.shape({
    [propTypes.string]: propTypes.bool,
  }),
  groupInfo: propTypes.shape({
    [propTypes.number]: propTypes.shape({
      memberName: propTypes.string,
      sldpType: propTypes.string,
      secName: propTypes.string,
      secID: propTypes.number,
    }),
  }),
  userInfo: propTypes.shape({
    approved: propTypes.number,
    barcode: propTypes.string,
    firstName: propTypes.string,
    groupID: propTypes.number,
    idNum: propTypes.string,
    joinDate: propTypes.string,
    lastName: propTypes.string,
    quit: propTypes.number,
    report_pin: propTypes.string,
    sectionID: propTypes.number,
    userEmail: propTypes.string,
    userID: propTypes.number,
  }),
  alert: propTypes.func,
};

SldpForm.defaultProps = {
  form: -1,
  requirements: { '-1': false },
  groupInfo: { 0: { memberName: '-1', sldpType: '-1', secName: '-1', secID: -1 } },
  userInfo: {
    approved: -1,
    barcode: '-1',
    firstName: '-1',
    groupID: -1,
    idNum: '-1',
    joinDate: '-1',
    lastName: '-1',
    quit: -1,
    report_pin: '-1',
    sectionID: -1,
    userEmail: '-1',
    userID: -1,
  },
  alert: () => {},
};
