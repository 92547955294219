import React, { Component } from 'react';
import propTypes from 'prop-types';
import axios from 'axios';
import { Form } from 'react-bootstrap';

import SqlUTCToNYTime from '../../../utils/sqlUTCToNYTime';

export default class PayPeriodsDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      payPeriods: props.payPeriods,
    };
    this.updateCurSelection = this.updateCurSelection.bind(this);
  }

  componentDidMount() {
    const { props } = this;
    axios.get('/payPeriods/1').then((res) => {
      console.log('response', res);
      this.setState({
        payPeriods: res.data,
      });
      props.parentCallback(res.data[0].id.toString()); // update parent's state upon initialization
    });
  }

  updateCurSelection(e) {
    const { props } = this;
    props.parentCallback(e.target.value.toString());
  }

  render() {
    const curState = this.state;
    const allPayPeriods = curState.payPeriods;
    let firstTime = true;
    const selectOptions = allPayPeriods.map((payPeriod) => {
      const start = SqlUTCToNYTime(payPeriod.start);
      const end = SqlUTCToNYTime(payPeriod.end);
      if (firstTime) {
        firstTime = false;
        return (
          <option defaultValue id={payPeriod.id} key={payPeriod.id} value={payPeriod.id}>
            {`${start} to ${end}`}
          </option>
        );
      }
      return (
        <option id={payPeriod.id} key={payPeriod.id} value={payPeriod.id}>
          {`${start} to ${end}`}
        </option>
      );
    });

    return (
      <div>
        <Form.Control as="select" onChange={this.updateCurSelection}>
          {selectOptions}
        </Form.Control>
      </div>
    );
  }
}

PayPeriodsDropdown.propTypes = {
  payPeriods: propTypes.arrayOf(
    propTypes.shape({
      numOfPayPeriods: propTypes.number,
      id: propTypes.number,
      start: propTypes.string,
      end: propTypes.string,
      currentPeriod: propTypes.number,
    }),
  ),
  parentCallback: propTypes.func,
};

PayPeriodsDropdown.defaultProps = {
  payPeriods: [
    {
      numOfPayPeriods: -1,
      id: -1,
      start: '-1',
      end: '-1',
      currentPeriod: -1,
    },
  ],
  parentCallback: () => {},
};
