import React, { Component } from 'react';
import axios from 'axios';

import AnnouncementCard from '../../components/announcements/announcementCard';

import '../../components/announcements/announcements.scss';

export default class Announcements extends Component {
  constructor(props) {
    super(props);

    this.state = {
      announcements: [],
    };
    this.generateAnnouncementCards = this.generateAnnouncementCards.bind(this);
  }

  componentDidMount() {
    axios.get('/announcements').then((res) => {
      console.log('response', res);
      this.setState({
        announcements: res.data,
      });
    });
  }

  generateAnnouncementCards() {
    console.log('In card');
    const { announcements } = this.state;
    return announcements.map((ann) => (
      <AnnouncementCard
        title={ann.title}
        body={ann.body}
        id={ann.id}
        posted={ann.posted}
        edited={ann.edited}
        key={`announcement-${ann.id}`}
      />
    ));
  }

  render() {
    const curState = this.state;
    if (curState.announcements) {
      return this.generateAnnouncementCards();
    }
    return <p>Loading announcements...</p>;
  }
}
