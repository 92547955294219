import React, { Component } from 'react';
import propTypes from 'prop-types';
import axios from 'axios';
import { Form } from 'react-bootstrap';

export default class UsersDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      users: props.users,
      default: props.default, // TODO: shouldn't need to store this in state
    };
    this.updateCurSelection = this.updateCurSelection.bind(this);
  }

  componentDidMount() {
    const { props } = this;
    if (props.groupID === 'all') {
      axios.get(`/users-active/`).then((res) => {
        console.log('response', res);
        this.setState({
          users: res.data,
        });
      });
    } else {
      axios.get(`/userGroup/${props.groupID}`).then((res) => {
        console.log('response', res);
        this.setState({
          users: res.data,
        });
      });
    }
  }

  updateCurSelection(e) {
    const { props } = this;
    this.setState({
      default: e.target.value,
    });
    props.parentCallback(e.target.value, props.rowId);
  }

  render() {
    const { props } = this;
    const curState = this.state;
    const allUsers = curState.users;
    let selectOptions;
    if (props.groupID === 'all') {
      selectOptions = allUsers.map((user) => (
        <option id={user.userID} key={user.userID} value={user.userID}>
          {`(${props.idToRoleMap[user.groupID]}) ${user.firstName} ${user.lastName}`}
        </option>
      ));
    } else {
      selectOptions = allUsers.map((user) => (
        <option id={user.userID} key={user.userID} value={user.userID}>
          {`${user.firstName} ${user.lastName}`}
        </option>
      ));
    }

    return (
      <div>
        <Form.Control
          value={curState.default}
          required
          as="select"
          onChange={this.updateCurSelection}
          disabled={props.isDisabled}
        >
          <option value="-1">
            {props.groupID === 'all'
              ? `---Select User---`
              : `---Select ${props.idToRoleMap[props.groupID]}---`}
          </option>
          {selectOptions}
        </Form.Control>
      </div>
    );
  }
}

UsersDropdown.propTypes = {
  groupID: propTypes.number,
  users: propTypes.arrayOf(
    propTypes.shape({
      userID: propTypes.number,
      groupID: propTypes.number,
      sectionID: propTypes.number,
      idNum: propTypes.string,
      barcode: propTypes.string,
      approved: propTypes.number,
      joinDate: propTypes.string,
      userEmail: propTypes.string,
      firstName: propTypes.string,
      lastName: propTypes.string,
      report_pin: propTypes.string,
      quit: propTypes.number,
    }),
  ),
  parentCallback: propTypes.func,
  rowId: propTypes.string, // Used in submitReportForm to get row information.
  default: propTypes.string,
  idToRoleMap: propTypes.shape({
    2: propTypes.string,
    3: propTypes.string,
    5: propTypes.string,
  }),
  isDisabled: propTypes.bool,
};

UsersDropdown.defaultProps = {
  groupID: 2, // default dropdown is for TAs
  users: [
    {
      userID: -1,
      groupID: -1,
      sectionID: -1,
      idNum: '-1',
      barcode: '-1',
      approved: -1,
      joinDate: '-1',
      userEmail: '-1',
      firstName: '-1',
      lastName: '-1',
      report_pin: '-1',
      quit: -1,
    },
  ],
  parentCallback: () => {},
  rowId: '-1',
  default: '-1',
  idToRoleMap: {
    2: 'TA',
    3: 'Professor',
    5: 'Student',
  },
  isDisabled: false,
};
