import axios from 'axios';
import propTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Form, Col, Row } from 'react-bootstrap';
import LecturesDropdown from '../convenientDropdowns/lecturesDropdown';
import SectionDropdown from '../convenientDropdowns/sectionsDropdown';

export default class AttendanceLecture extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sectionId: props.sectionId,
      studentId: props.studentId,
      lectureId: props.lectureId,
    };
  }

  updateSectionId = (childData) => {
    this.setState({
      sectionId: childData,
    });
  };

  updateLectureId = (childData) => {
    this.setState({
      lectureId: childData,
    });
  };

  updateStudentId = (e) => {
    this.setState({
      studentId: e.target.value,
    });
  };

  submitAttendance = () => {
    const curState = this.state;
    const curStudentId = curState.studentId;
    if (curStudentId === '-1' || curState.lectureId === -1) {
      console.log('Error! Please check your input for attendance submission!');
      return;
    }
    // Student N number was entered.
    if (curStudentId.length === 9) {
      axios
        .post(`/lectures-nnum/${curStudentId}/${curState.lectureId}`)
        .then(() => {})
        .catch((err) => {
          console.log(err);
        });
      return;
    }
    // Student barcode was entered.
    if (curStudentId.length === 14) {
      axios
        .post(`/lectures-barcode/${curStudentId}/${curState.lectureId}`)
        .then(() => {})
        .catch((err) => {
          console.log(err);
        });
      return;
    }
    console.log('Error! Please enter valid student information!');
  };

  render() {
    const curState = this.state;
    return (
      <>
        <div className="mb-3">
          <p className="text-muted">Select a section to view lecture attendance.</p>
          <SectionDropdown
            default={curState.sectionId}
            limited
            parentCallback={this.updateSectionId}
          />
        </div>
        <h5 className="display-6 mb-3">Take Individual Attendace</h5>
        <p className="text-muted">If you are entering N number, please include N in the input.</p>
        <Form className="mb-3">
          <Row>
            <Col>
              <p style={{ fontSize: '18px' }}>Student N Number or Barcode</p>
            </Col>
            <Col xs={8}>
              <input
                key="adding-student-name"
                placeholder="N Number or Barcode"
                onChange={this.updateStudentId}
                value={curState.studentId === '-1' ? '' : curState.studentId}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <p style={{ fontSize: '18px' }}>Lecture</p>
            </Col>
            <Col xs={8}>
              <LecturesDropdown
                default={curState.lectureId}
                limited
                parentCallback={this.updateLectureId}
              />
            </Col>
          </Row>
          <Row>
            <Button variant="primary" onClick={this.submitAttendance}>
              Submit Attendance
            </Button>
          </Row>
        </Form>
      </>
    );
  }
}

AttendanceLecture.propTypes = {
  sectionId: propTypes.number,
  studentId: propTypes.string,
  lectureId: propTypes.number,
};

AttendanceLecture.defaultProps = {
  sectionId: -1,
  studentId: '-1',
  lectureId: -1,
};
