import React, { Component } from 'react';
import { Button, Form, Col, Row } from 'react-bootstrap';
import axios from 'axios';

const config = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
};
const qs = require('querystring-es3');
const md5 = require('md5');

const EGEMAIL = 'gecentral@eg.poly.edu';

export default class SetPin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userInfo: {},
      isLoggedIn: false,
      oldPin: '',
      newPin: '',
      reenteredPin: '',
    };
  }

  componentDidMount() {
    axios.get('/getUserInfo/').then((res) => {
      this.setState({
        userInfo: res.data ? res.data : {},
        isLoggedIn: !!res.data,
      });
    });
  }

  handleSubmit = () => {
    const curState = this.state;
    if (
      md5(curState.oldPin) !== curState.userInfo.report_pin &&
      curState.userInfo.report_pin !== '0'
    ) {
      // TODO: Change this to an alert after creating a public alert component.
      console.log('Please double check your current password');
      return;
    }
    if (curState.oldPin === curState.newPin) {
      // TODO: Change this to an alert after creating a public alert component.
      console.log('Please choose a new password!');
      return;
    }
    if (curState.newPin !== curState.reenteredPin) {
      // TODO: Change this to an alert after creating a public alert component.
      console.log('Please check your entered password!');
      return;
    }
    if (Number.isNaN(curState.newPin) || curState.newPin.length !== 4) {
      // TODO: Change this to an alert after creating a public alert component.
      console.log('Please enter 4-digit number as password!');
      return;
    }
    const newUserInfo = curState.userInfo;
    newUserInfo.report_pin = md5(curState.newPin);
    axios.put(`/users/${newUserInfo.userID}`, qs.stringify(newUserInfo), config).catch((err) => {
      console.log(err);
    });
  };

  render() {
    const curState = this.state;
    const curUserInfo = curState.userInfo;
    if (!curState.isLoggedIn) {
      return <p>You are not currently signed in.</p>;
    }
    if (curUserInfo.report_pin === '0') {
      return (
        <>
          <h4 className="display-6">Set New PIN</h4>
          <p className="text-muted">
            It seems like you never set a <b>4 digit</b> pin number before. <br />
            Please set a pin which will be used to submit your work report and view the gradebook.
          </p>
          <Form>
            <Form.Group as={Row} className="mb-3" controlId="enteredPassword">
              <Col sm={{ span: 5, offset: 3 }}>
                <Form.Label>Enter PIN</Form.Label>
                <Form.Control
                  aria-describedby="passwordHelpBlock"
                  type="password"
                  placeholder="Enter PIN"
                  value={curState.newPin}
                  onChange={(e) => this.setState({ newPin: e.target.value })}
                />
                <Form.Text id="passwordHelpBlock" muted>
                  Your password must be 4-digit numbers.
                </Form.Text>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="reenteredPassword">
              <Col sm={{ span: 5, offset: 3 }}>
                <Form.Label>Re-enter PIN</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Re-enter PIN"
                  value={curState.reenteredPin}
                  onChange={(e) => this.setState({ reenteredPin: e.target.value })}
                />
              </Col>
            </Form.Group>
            <Row>
              <Col sm="12" md={{ offset: 4 }}>
                <Button variant="primary" onClick={this.handleSubmit}>
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        </>
      );
    }
    return (
      <>
        <h4 className="display-6">Reset Your PIN</h4>
        <p className="text-muted">
          If you forgot your PIN, please email <a href={`mailto:${EGEMAIL}`}>{EGEMAIL}</a>
        </p>
        <Form>
          <Form.Group as={Row} className="mb-3" controlId="enteredOldPassword">
            <Col sm={{ span: 5, offset: 4 }}>
              <Form.Label>Enter Current PIN</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter Current PIN"
                value={curState.oldPin}
                onChange={(e) => this.setState({ oldPin: e.target.value })}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="enteredNewPassword">
            <Col sm={{ span: 5, offset: 4 }}>
              <Form.Label>Enter New PIN</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter New PIN"
                aria-describedby="passwordHelpBlock"
                value={curState.newPin}
                onChange={(e) => this.setState({ newPin: e.target.value })}
              />
              <Form.Text id="passwordHelpBlock" muted>
                Your password must be 4-digit numbers.
              </Form.Text>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="reenteredNewPassword">
            <Col sm={{ span: 5, offset: 4 }}>
              <Form.Label>Re-enter New PIN</Form.Label>
              <Form.Control
                type="password"
                placeholder="Re-enter New PIN"
                value={curState.reenteredPin}
                onChange={(e) => this.setState({ reenteredPin: e.target.value })}
              />
            </Col>
          </Form.Group>
          <Row>
            <Col sm="12" md={{ offset: 4 }}>
              <Button variant="primary" onClick={this.handleSubmit}>
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </>
    );
  }
}
