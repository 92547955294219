import React, { Component } from 'react';
import axios from 'axios';
import { Table, Button, Modal } from 'react-bootstrap';
import propTypes from 'prop-types';

import AddItem from './addItem';

const config = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
};
const qs = require('querystring-es3');

export default class AllItems extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: props.items, // array of all items

      editingItemID: props.editingItemID, // id of item that is currently being edited (if any)

      editing_itemName: props.editing_itemName, // holds the updated name of an item that is being edited
      editing_cost: props.editing_cost, // holds the updated cost of an item that is being edited
      editing_overallStock: props.editing_overallStock, // holds the updated stock of an item that is being edited
      editing_unit: props.editing_unit, // holds the updated unit of an item that is being edited

      modalErrorMessage: props.modalErrorMessage,
      viewErrorModal: props.viewErrorModal,
    };
  }

  componentDidMount() {
    axios.get('/inventory-item/').then((res) => {
      this.setState({
        items: res.data,
      });
    });
  }

  updateItemsInState = () => {
    axios.get('/inventory-item/').then((res) => {
      this.setState({
        items: res.data,
      });
    });
  };

  deleteItem = (itemID) => {
    axios.delete(`inventory-item/${itemID}`).then(() => {
      this.updateItemsInState();
    });
  };

  updateEditingItemName = (e) => {
    this.setState({
      editing_itemName: e.target.value,
    });
  };

  toggleEditingItem = (item = {}) => {
    console.log("Okay we're editing the item that looks like this: ", item);
    if (item === {}) {
      this.setState({
        editingItemID: -1,
      });
    } else {
      this.setState({
        editingItemID: item.itemID,
        editing_itemName: item.itemName,
        editing_cost: item.cost,
        editing_overallStock: item.stock,
        editing_unit: item.unit === 'n/a' ? null : item.unit,
      });
    }
  };

  updateEditingCost = (e) => {
    this.setState({
      editing_cost: e.target.value,
    });
  };

  updateEditingOverallStock = (e) => {
    this.setState({
      editing_overallStock: e.target.value,
    });
  };

  updateEditingUnit = (e) => {
    this.setState({
      editing_unit: e.target.value,
    });
  };

  toggleErrorModal = () => {
    this.setState((prevState) => ({
      viewErrorModal: !prevState.viewErrorModal,
    }));
  };

  submitEditedItem = (itemID) => {
    const curState = this.state;
    const name = curState.editing_itemName;
    const cost = curState.editing_cost;
    const stock = curState.editing_overallStock;
    const unit = curState.editing_unit;

    // form validation
    if (name === '') {
      this.setState({
        viewErrorModal: true,
        modalErrorMessage: 'The item name cannot be empty. Please fix and resubmit.',
      });
      return;
    }
    if (!cost) {
      this.setState({
        viewErrorModal: true,
        modalErrorMessage: 'The item cost cannot be empty. Please fix and resubmit.',
      });
      return;
    }
    if (cost < 0 || cost >= 10000) {
      this.setState({
        viewErrorModal: true,
        modalErrorMessage:
          'Your cost is oustide of range. An item can cost anywhere from $0 to $9999.99. Please fix and resubmit.',
      });
      return;
    }
    if (stock === '') {
      this.setState({
        viewErrorModal: true,
        modalErrorMessage: 'The stock cannot be empty. Please fix and resubmit.',
      });
      return;
    }
    if (stock.toString().indexOf('.') !== -1) {
      this.setState({
        viewErrorModal: true,
        modalErrorMessage:
          'Please make the starting stock a whole number. If you really want fractional stock numbers contact webdev and we can try our best.',
      });
      return;
    }

    // submitting to API:
    const obj = {
      itemName: name,
      cost,
      stock,
      unit,
    };
    console.log('Data to send to API for ID ', itemID, ': ', obj);
    axios
      .put(`/inventory-item/${itemID}`, qs.stringify(obj), config)
      .then(() => {
        this.updateItemsInState();
        this.toggleEditingItem();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const curState = this.state;
    console.log('all items state: ', curState);
    const trs = curState.items.map((item) => {
      const { itemID } = item;
      const { itemName } = item;
      const { cost } = item;
      const { overallStock } = item;
      const { inUse } = item;
      const notInUse = overallStock - inUse;
      const unit = item.unit ? item.unit : 'n/a';
      if (itemID === curState.editingItemID) {
        return (
          <tr key={`editing-tr-${itemID}`}>
            <td key={`editing-name-${itemID}`}>
              <input
                key={`input-name-${itemID}`}
                defaultValue={itemName}
                onBlur={this.updateEditingItemName}
              />
            </td>
            <td key={`editing-cost-${itemID}`}>
              <input
                key={`input-cost-${itemID}`}
                defaultValue={cost}
                onBlur={this.updateEditingCost}
              />
            </td>
            <td key={`editing-overallStock-${itemID}`}>
              <input
                key={`input-overallStock-${itemID}`}
                defaultValue={overallStock}
                onBlur={this.updateEditingOverallStock}
              />
            </td>
            <td key={`editing-inUse-${itemID}`}>{inUse}</td>
            <td key={`editing-notInUse-${itemID}`}>{notInUse}</td>
            <td key={`editing-unit-${itemID}`}>
              <input
                key={`input-unit-${itemID}`}
                defaultValue={unit === 'n/a' ? null : unit}
                onBlur={this.updateEditingUnit}
              />
            </td>
            <td key={`editing-action-${itemID}`}>
              <Button onClick={() => this.setState({ editingItemID: -1 })} variant="secondary">
                Cancel
              </Button>
              <Button onClick={() => this.submitEditedItem(itemID)} variant="primary">
                Confirm
              </Button>
            </td>
          </tr>
        );
      }
      return (
        <tr key={`tr-${itemID}`}>
          <td key={`name-${itemID}`}>{itemName}</td>
          <td key={`cost-${itemID}`}>{`$${cost}`}</td>
          <td key={`overallStock-${itemID}`}>{overallStock}</td>
          <td key={`inUse-${itemID}`}>{inUse}</td>
          <td key={`notInUse-${itemID}`}>{notInUse}</td>
          <td key={`unit-${itemID}`}>{unit}</td>
          <td key={`action-${itemID}`}>
            <Button
              onClick={() =>
                this.toggleEditingItem({
                  itemID,
                  itemName,
                  cost,
                  stock: overallStock,
                  unit,
                })
              }
              variant="secondary"
            >
              Edit
            </Button>
            <Button onClick={() => this.deleteItem(itemID)} variant="danger">
              Delete
            </Button>
          </td>
        </tr>
      );
    });
    if (trs.length === 0)
      return (
        <div>
          <h2>Add an Item</h2>
          <AddItem />
        </div>
      );
    return (
      <div>
        <Table responsive striped bordered hover>
          <thead>
            <tr>
              <th>Item Name</th>
              <th>Cost</th>
              <th>Total Stock</th>
              <th>In Use</th>
              <th>Not in Use</th>
              <th>Unit</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>{trs}</tbody>
        </Table>
        <Modal show={curState.viewErrorModal} onHide={this.toggleErrorModal} animation>
          <Modal.Header closeButton>
            <Modal.Title>There is an error with your submission.</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>{curState.modalErrorMessage}</p>
          </Modal.Body>

          <Modal.Footer>
            <Button
              onClick={() => {
                this.toggleErrorModal();
              }}
              variant="primary"
            >
              Okay
            </Button>
          </Modal.Footer>
        </Modal>
        <br />
        <hr />
        <br />
        <h2>Add an Item</h2>
        <AddItem />
      </div>
    );
  }
}

AllItems.propTypes = {
  items: propTypes.arrayOf(
    propTypes.shape({
      cost: propTypes.number,
      inUse: propTypes.number,
      itemID: propTypes.number,
      itemName: propTypes.string,
      overallStock: propTypes.number,
      unit: propTypes.string,
    }),
  ),
  editingItemID: propTypes.number,

  editing_itemName: propTypes.string,
  editing_cost: propTypes.number,
  editing_overallStock: propTypes.number,
  editing_unit: propTypes.string,

  modalErrorMessage: propTypes.string,
  viewErrorModal: propTypes.bool,
};

AllItems.defaultProps = {
  items: [
    {
      cost: -1,
      inUse: -1,
      itemID: -1,
      itemName: '-1',
      overallStock: -1,
      unit: '-1',
    },
  ],
  editingItemID: -1,

  editing_itemName: '',
  editing_cost: -1,
  editing_overallStock: -1,
  editing_unit: '',

  modalErrorMessage: "Contact Webdev. You shouldn't be seeing this.",
  viewErrorModal: false,
};
