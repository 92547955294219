import React, { Component } from 'react';
import propTypes from 'prop-types';
import axios from 'axios';
import { Form } from 'react-bootstrap';

export default class LecturesDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lectures: props.lectures,
    };
    this.updateCurSelection = this.updateCurSelection.bind(this);
  }

  componentDidMount() {
    axios.get('/lectures').then((res) => {
      this.setState({
        lectures: res.data,
      });
    });
  }

  updateCurSelection(e) {
    const { props } = this;
    props.parentCallback(e.target.value);
  }

  render() {
    const { props } = this;
    const curState = this.state;
    const allLectures = curState.lectures;
    const selectOptions = allLectures.map((lecture) => (
      <option id={lecture.id} key={lecture.id} value={lecture.id}>
        {lecture.name}
      </option>
    ));

    return (
      <div>
        <Form.Control value={props.default} as="select" onChange={this.updateCurSelection}>
          <option value="-1">---Select Lecture---</option>
          {selectOptions}
        </Form.Control>
      </div>
    );
  }
}

LecturesDropdown.propTypes = {
  lectures: propTypes.arrayOf(
    propTypes.shape({
      id: propTypes.number,
      ordering: propTypes.number,
      name: propTypes.string,
      date: propTypes.string,
      filepath: propTypes.string,
    }),
  ),
  parentCallback: propTypes.func,
  default: propTypes.string,
};

LecturesDropdown.defaultProps = {
  lectures: [
    {
      id: -1,
      ordering: -1,
      name: '-1',
      date: '-1',
      filepath: '-1',
    },
  ],
  default: '-1',
  parentCallback: () => {},
};
