import React, { Component } from 'react';
import axios from 'axios';
import { Button, Pagination, Table } from 'react-bootstrap';
import propTypes from 'prop-types';
import SubmitReportForm from '../reports/submitReportForm';

import './reportsOfStatus.scss';

export default class ReportsOfStatus extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reports: props.reports,
      visibleReport: props.visibleReport,
      curPage: props.curPage,
      status: props.status === 'pending' ? '0' : props.status === 'approved' ? '1' : '2',
    };
    this.toggleShowReport = this.toggleShowReport.bind(this);
    this.updateReportsByPage = this.updateReportsByPage.bind(this);
  }

  componentDidMount() {
    const { props } = this;
    const curState = this.state;
    axios.get(`/reportsByType/${curState.status}/${props.payPeriod}/1`).then((res) => {
      console.log('response', res);
      this.setState({
        reports: res.data,
      });
    });
  }

  componentDidUpdate(prevProps) {
    const { props } = this;
    const curState = this.state;
    if (prevProps.payPeriod !== props.payPeriod) {
      this.updateReportsByPage(curState.curPage);
    }
  }

  nextPage = () => {
    const curState = this.state;
    this.setState((prevState) => ({
      curPage: prevState.curPage + 1,
    }));
    this.updateReportsByPage((curState.curPage + 1).toString());
  };

  prevPage = () => {
    const curState = this.state;
    if (curState.curPage > 1) {
      this.setState({
        curPage: curState.curPage - 1,
      });
      this.updateReportsByPage((curState.curPage - 1).toString());
    }
  };

  updatePageTo = (page) => {
    this.setState({
      curPage: page,
    });
    this.updateReportsByPage(page.toString());
  };

  toggleShowReport(reportID) {
    const curState = this.state;
    const report = [];

    if (curState.visibleReport.length === 0) {
      report.push(<SubmitReportForm isEditing isAdmin reportID={reportID} />);
      report.push(
        <Button onClick={() => this.toggleShowReport(reportID)} variant="secondary">
          Cancel
        </Button>,
      );
    }

    this.setState({
      visibleReport: report,
    });
  }

  updateReportsByPage(page, { payPeriod } = this.props) {
    const curState = this.state;
    // need the option to pass in pay period for when we are in componentDidUpdate
    console.log(
      'SENDING GET REQUEST TO: ',
      `/reportsByType/${curState.status}/${payPeriod}/${page}`,
    );
    axios.get(`/reportsByType/${curState.status}/${payPeriod}/${page}`).then((res) => {
      console.log('response', res);
      this.setState({
        reports: res.data,
      });
    });
  }

  render() {
    const curState = this.state;
    const { props } = this;
    console.log('State for reports of status: ', curState);
    const allReports = curState.reports;
    // console.log("REPORTS: ", allReports)
    const trs = allReports.map((
      report, // TODO: Come back to this after pagination is fixed
    ) => (
      <tr key={report.id} id={report.id}>
        <td key={report.id + report.reporter} id={report.id + report.reporter}>
          {`${report.firstName} ${report.lastName}`}
        </td>
        <td key={report.id + report.workType} id={report.id + report.workType}>
          {report.workType}
        </td>
        <td key={report.id - report.submiteDate} id={report.id + report.submitDate}>
          {report.submitDate.slice(0, 10)}
        </td>
        <td key={`${report.id}edit`} id={`${report.id}edit`}>
          <Button
            onClick={() =>
              this.toggleShowReport(report.id, report.firstName, report.lastName, report.workType)
            }
            variant="primary"
          >
            Manage
          </Button>
        </td>
      </tr>
    ));

    const paginationComponent = [];

    const numOfReports = allReports.length;
    if (numOfReports > 10) {
      for (let i = 1; i <= Math.floor(numOfReports / 10) + 1; i += 1) {
        paginationComponent.push(
          <Pagination.Item
            active={curState.curPage === i}
            id={`pagcomponent ${i}`}
            key={`pagcomponent ${i}`}
            onClick={(() => () => {
              this.updatePageTo(i);
            })(i)}
          >
            {i}
          </Pagination.Item>,
        ); // the weird onclick function is needed to preserve the state of i upon Pagination.Item creation, otherwise i will always equal whatever it was last for every button.
      }
    }

    return (
      <div>
        {curState.visibleReport.length === 0 && (
          <div>
            {numOfReports > 1 && numOfReports < 100 && (
              <p>
                There are <b>{numOfReports.toString()}</b> {props.status} reports
              </p>
            )}
            {numOfReports > 100 && (
              <p>
                There are <b>{numOfReports.toString()}</b> (oof) {props.status} reports
              </p>
            )}
            {numOfReports === 1 && (
              <p>
                There is <b>{numOfReports.toString()}</b> {props.status} report
              </p>
            )}
            {numOfReports > 0 && (
              <div>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Reporter</th>
                      <th>Work Type</th>
                      <th>Date Submitted</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>{trs}</tbody>
                </Table>
                <div className="pagination-tabs">
                  <Pagination>{paginationComponent}</Pagination>
                </div>
              </div>
            )}
          </div>
        )}
        {numOfReports > 0 && curState.visibleReport}
        {numOfReports === 0 && (
          <p>
            <b>There are no {props.status} reports to show for this pay period.</b>
          </p>
        )}
      </div>
    );
  }
}

ReportsOfStatus.propTypes = {
  reports: propTypes.arrayOf(
    propTypes.shape({
      body: propTypes.string,
      comment: propTypes.string,
      committeeWork: propTypes.number,
      date: propTypes.string,
      end: propTypes.string,
      expGradingID: propTypes.number,
      expLabID: propTypes.number,
      expRecitationID: propTypes.number,
      firstName: propTypes.string,
      id: propTypes.number,
      lastName: propTypes.string,
      numOfReports: propTypes.number,
      problem: propTypes.string,
      rAndDProj_ID: propTypes.number,
      radprojectID: propTypes.string,
      radprojectText: propTypes.string,
      report_payperiodID: propTypes.number,
      reporter: propTypes.number,
      secID: propTypes.number,
      start: propTypes.string,
      status: propTypes.number,
      submitDate: propTypes.string,
      workType: propTypes.string,
    }),
  ),
  // TODO: take react components out of state, so messy.
  // eslint-disable-next-line react/forbid-prop-types
  visibleReport: propTypes.array,
  curPage: propTypes.number,
  status: propTypes.string,
  payPeriod: propTypes.string,
};

ReportsOfStatus.defaultProps = {
  reports: [
    {
      body: '-1',
      comment: '-1',
      committeeWork: -1,
      date: '-1',
      end: '-1',
      expGradingID: -1,
      expLabID: -1,
      expRecitationID: -1,
      firstName: '-1',
      id: -1,
      lastName: '-1',
      numOfReports: -1,
      problem: '-1',
      rAndDProj_ID: -1,
      radprojectID: '-1',
      radprojectText: '-1',
      report_payperiodID: -1,
      reporter: -1,
      secID: -1,
      start: '-1',
      status: -1,
      submitDate: '-1',
      workType: '-1',
    },
  ],
  visibleReport: [],
  curPage: 1,
  status: 0,
  payPeriod: '10', // random pay period from 2010
};
