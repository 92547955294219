import React, { Component } from 'react';
import axios from 'axios';
import { Button, Pagination, Table } from 'react-bootstrap';
import propTypes from 'prop-types';

import sqlUTCToNYTime from '../../../utils/sqlUTCToNYTime';
import datePickerToUtcISO from '../../../utils/datePickerToUtcISO';
import utcISOToSql from '../../../utils/utcISOToSql';

import DateSelector from '../dateSelector/dateSelector';

const config = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
};
const qs = require('querystring-es3');

export default class ManagePayPeriodTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      payPeriods: props.payPeriods,
      curPage: props.curPage,
      curPayPeriod: props.curPayPeriod,
      curEditing: props.curEditing,

      curNewStartInput: props.curNewStartInput,
      curNewEndInput: props.curNewEndInput,

      curEditingStartInput: props.curEditingStartInput,
      curEditingEndInput: props.curEditingEndInput,
    };

    this.makePayPeriod = this.makePayPeriod.bind(this);
    this.setCurEditing = this.setCurEditing.bind(this);
    this.submitEdit = this.submitEdit.bind(this);
    this.submitNewPayPeriod = this.submitNewPayPeriod.bind(this);
    this.deletePayPeriod = this.deletePayPeriod.bind(this);
  }

  componentDidMount() {
    axios.get('/payPeriods/1').then((res) => {
      console.log('response', res);
      this.setState({
        payPeriods: res.data,
      });
    });
  }

  setCurEditing(id) {
    this.setState({
      curEditing: id,
    });
  }

  nextPage = () => {
    const curState = this.state;
    this.setState((prevState) => ({
      curPage: prevState.curPage + 1,
    }));
    this.updatePayPeriodsByPage((curState.curPage + 1).toString());
  };

  prevPage = () => {
    const curState = this.state;
    if (curState.curPage > 1) {
      this.setState((prevState) => ({
        curPage: prevState.curPage - 1,
      }));
      this.updatePayPeriodsByPage((curState.curPage - 1).toString());
    }
  };

  updatePageTo = (page) => {
    console.log('UPDATING TO: ', page);
    this.setState({
      curPage: page,
    });
    this.updatePayPeriodsByPage(page.toString());
  };

  updateCurNewStartInput = (updatedValue) => {
    console.log('UPDATED VALUE: ', updatedValue);
    this.setState({
      curNewStartInput: updatedValue,
    });
  };

  updateCurNewEndInput = (updatedValue) => {
    this.setState({
      curNewEndInput: updatedValue,
    });
  };

  updateCurEditingStartInput = (updatedValue) => {
    this.setState({
      curEditingStartInput: updatedValue,
    });
  };

  updateCurEditingEndInput = (updatedValue) => {
    this.setState({
      curEditingEndInput: updatedValue,
    });
  };

  reloadPayPeriods() {
    const curState = this.state;
    axios.get(`/payPeriods/${curState.curPage}`).then((res) => {
      console.log('response', res);
      this.setState({
        payPeriods: res.data,
      });
    });
  }

  updatePayPeriodsByPage(page) {
    axios.get(`/payPeriods/${page}`).then((res) => {
      console.log('response', res);
      this.setState({
        payPeriods: res.data,
      });
    });
  }

  makePayPeriod(id) {
    axios.put(`/setCurPayPeriod/${id}`).then((res) => {
      console.log('response', res);
    });
    this.setState({
      curPayPeriod: id,
    });
  }

  submitEdit(id) {
    const curState = this.state;
    const start = utcISOToSql(datePickerToUtcISO(curState.curEditingStartInput, true, true));
    const end = utcISOToSql(datePickerToUtcISO(curState.curEditingEndInput, true, true));

    const obj = { 'start: ': start, 'end: ': end };

    console.log('TO PUT: ', obj);

    axios
      .put(`/payPeriod/${id}`, qs.stringify({ start, end }), config)
      .then((response) => {
        console.log('response', response);
        this.reloadPayPeriods();
      })
      .catch((err) => {
        console.log(err);
      });

    this.setCurEditing(-1); // we're no longer editing any sections
  }

  submitNewPayPeriod() {
    const curState = this.state;
    const start = utcISOToSql(datePickerToUtcISO(curState.curNewStartInput, true, true));
    const end = utcISOToSql(datePickerToUtcISO(curState.curNewEndInput, true, true));

    axios
      .post('/payPeriod/', qs.stringify({ start, end, currentPeriod: 0 }), config)
      .then((response) => {
        console.log('response', response);
        this.reloadPayPeriods();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  deletePayPeriod(id) {
    axios
      .delete(`/payPeriod/${id}`)
      .then((response) => {
        console.log('response', response);
        this.reloadPayPeriods();
      })
      .catch((err) => {
        console.log(err);
      });
    this.setCurEditing(-1); // we're no longer editing any pay periods
  }

  render() {
    const curState = this.state;
    console.log('pay period state: ', curState);
    const { curPayPeriod } = curState; // we will use this if we updated the pay period on the page already and want to rerender the page without another API call.
    const { curEditing } = curState;
    const { payPeriods } = curState;
    let trs;
    if (curEditing === -1) {
      // nothing is being edited
      trs = payPeriods.map((payPeriod) => (
        <tr key={`tr ${payPeriod.id}`} id={`tr ${payPeriod.id}`}>
          <td key={`start ${payPeriod.id}`} id={`start ${payPeriod.id}`}>
            {sqlUTCToNYTime(payPeriod.start)}
          </td>
          <td key={`end ${payPeriod.id}`} id={`end ${payPeriod.id}`}>
            {sqlUTCToNYTime(payPeriod.end)}
          </td>
          <td key={`isCurPayPeriod${payPeriod.id}`} id={`isCurPayPeriod${payPeriod.id}`}>
            {(payPeriod.currentPeriod !== 1 || curPayPeriod !== -1) &&
              payPeriod.id !== curPayPeriod && (
                <Button
                  variant="primary"
                  key={`makepayperiod-button ${payPeriod.id}`}
                  onClick={() => this.makePayPeriod(payPeriod.id)}
                >
                  Set as Current Pay Period
                </Button>
              )}
            <Button
              variant="primary"
              key={`edit-button ${payPeriod.id}`}
              onClick={() => this.setCurEditing(payPeriod.id)}
            >
              Edit
            </Button>
          </td>
        </tr>
      ));
    } else {
      // something is being edited
      trs = payPeriods.map((payPeriod) => {
        if (curEditing !== payPeriod.id) {
          // this specific row is not the one being edited
          return (
            <tr key={`tr ${payPeriod.id}`} id={`tr ${payPeriod.id}`}>
              <td key={`start ${payPeriod.id}`} id={`start ${payPeriod.id}`}>
                {sqlUTCToNYTime(payPeriod.start)}
              </td>
              <td key={`end ${payPeriod.id}`} id={`end ${payPeriod.id}`}>
                {sqlUTCToNYTime(payPeriod.end)}
              </td>
              <td key={`isCurPayPeriod${payPeriod.id}`} id={`isCurPayPeriod${payPeriod.id}`}>
                {(payPeriod.currentPeriod !== 1 || curPayPeriod !== -1) &&
                  payPeriod.id !== curPayPeriod && (
                    <Button
                      variant="primary"
                      key={`makepayperiod-button ${payPeriod.id}`}
                      onClick={() => this.makePayPeriod(payPeriod.id)}
                    >
                      Make Pay Period
                    </Button>
                  )}
              </td>
            </tr>
          );
        }
        // this row is being edited
        return (
          <tr key={`tr ${payPeriod.id}`} id={`tr ${payPeriod.id}`}>
            <td key={`start-editing${payPeriod.id}`} id={`start-editing ${payPeriod.id}`}>
              <DateSelector
                parentCallback={this.updateCurEditingStartInput}
                showTimeSelect
                startDate={sqlUTCToNYTime(payPeriod.start)}
              />
            </td>
            <td key={`end-edit ${payPeriod.id}`} id={`end-edit ${payPeriod.id}`}>
              <DateSelector
                parentCallback={this.updateCurEditingEndInput}
                showTimeSelect
                startDate={sqlUTCToNYTime(payPeriod.end)}
              />
            </td>
            <td key={`edit-cancel${payPeriod.id}`} id={`edit-cancel${payPeriod.id}`}>
              <Button
                variant="primary"
                key={`submit-edit-button ${payPeriod.id}`}
                onClick={() => this.submitEdit(payPeriod.id)}
              >
                Submit
              </Button>
              <Button
                variant="secondary"
                key={`cancel-button ${payPeriod.id}`}
                onClick={() => this.setCurEditing(-1)}
              >
                Cancel
              </Button>
              <Button
                variant="danger"
                key={`delete-button ${payPeriod.id}`}
                onClick={() => this.deletePayPeriod(payPeriod.id)}
              >
                Delete
              </Button>
            </td>
          </tr>
        );
      });
    }

    const paginationComponent = [];

    let numOfPayPeriods = 0;
    payPeriods.forEach((payPeriod) => {
      // I couldn't index the first payPeriod object using allPayPeriods[0], not sure why. But this for loop works, so I'm keeping it.
      numOfPayPeriods = payPeriod.numOfPayPeriods;
    });
    if (numOfPayPeriods > 10) {
      for (let i = 1; i <= Math.floor(numOfPayPeriods / 10) + 1; i += 1) {
        paginationComponent.push(
          <Pagination.Item
            active={curState.curPage === i}
            id={`pagcomponent ${i}`}
            key={`pagcomponent ${i}`}
            onClick={(() => () => {
              this.updatePageTo(i);
            })(i)}
          >
            {i}
          </Pagination.Item>,
        ); // the weird onclick function is needed to preserve the state of i upon Pagination.Item creation, otherwise i will always equal whatever it was last for every button.
      }
    }

    return (
      <div>
        {numOfPayPeriods !== 1 && (
          <p>
            There are <b>{numOfPayPeriods.toString()}</b> pay periods
          </p>
        )}
        {numOfPayPeriods === 1 && (
          <p>
            There is <b>{numOfPayPeriods.toString()}</b> pay period
          </p>
        )}
        {numOfPayPeriods > 0 && (
          <div>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Start</th>
                  <th>End</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>{trs}</tbody>
            </Table>
            <div className="pagination-tabs">
              <Pagination>{paginationComponent}</Pagination>
            </div>
          </div>
        )}
        <hr />
        <h2>Create Pay Period</h2>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Start</th>
              <th>End</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <DateSelector
                  key="new-start-input"
                  parentCallback={this.updateCurNewStartInput}
                  showTimeSelect
                />
              </td>
              <td>
                <DateSelector
                  key="new-end-input"
                  parentCallback={this.updateCurNewEndInput}
                  showTimeSelect
                />
              </td>
              <td>
                <Button key="make-new-payperiod-button" onClick={this.submitNewPayPeriod}>
                  Submit
                </Button>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    );
  }
}

ManagePayPeriodTable.propTypes = {
  payPeriods: propTypes.arrayOf(
    propTypes.shape({
      currentPeriod: propTypes.number,
      end: propTypes.string,
      id: propTypes.number,
      numOfPayPeriods: propTypes.number,
      start: propTypes.string,
    }),
  ),
  curPage: propTypes.number,
  curPayPeriod: propTypes.number,
  curEditing: propTypes.number,

  curNewStartInput: propTypes.instanceOf(Date),
  curNewEndInput: propTypes.instanceOf(Date),

  curEditingStartInput: propTypes.instanceOf(Date),
  curEditingEndInput: propTypes.instanceOf(Date),
};

ManagePayPeriodTable.defaultProps = {
  payPeriods: [
    {
      currentPeriod: -1,
      end: '-1',
      id: -1,
      numOfPayPeriods: -1,
      start: '-1',
    },
  ],
  curPage: 1,
  curPayPeriod: -1,
  curEditing: -1,

  curNewStartInput: new Date(),
  curNewEndInput: new Date(),

  curEditingStartInput: new Date(),
  curEditingEndInput: new Date(),
};
