import axios from 'axios';
import propTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Form, Col, Row, Modal } from 'react-bootstrap';
import SubmissionDropdown from '../convenientDropdowns/submissionsDropdown';

export default class SubmitAssignment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSubmitModal: props.showSubmitModal,
      showIncompleteFormModal: props.showIncompleteFormModal,
      incompleteFormModalMessage: props.incompleteFormModalMessage,

      submissionTypes: props.submissionTypes, // All submission file types
      curSubmission: props.curSubmission, // Submission ID of the current submission
      curFileType: props.curFileType, // File type of the  current submission
    };
    this.toggleSubmitModal = this.toggleSubmitModal.bind(this);
    this.toggleIncompleteFormModal = this.toggleIncompleteFormModal.bind(this);
  }

  componentDidMount() {
    axios.get('/submission-type').then((res) => {
      this.setState({
        submissionTypes: res.data,
      });
    });
  }

  getFileTypes = (id, submissionTypes) => {
    const n = parseInt(id, 10);
    const extTypes = submissionTypes[n];
    let result;
    if (extTypes) {
      result = extTypes.map((type, idx) => (
        <option id={idx} key={type} value={type}>
          {type}
        </option>
      ));
    }
    return result;
  };

  getCurSubmission = (childData) => {
    this.setState({
      curSubmission: childData,
    });
  };

  getCurFileType = (childData) => {
    console.log('Current file STATE: ', childData);
    this.setState({
      curFileType: childData,
    });
  };

  submitAssignmentForStudent = () => {
    const curState = this.state;
    const lab = parseInt(curState.curSubmission, 10);
    const fileType = parseInt(curState.curFileType, 10);
    console.log(lab, fileType);

    // User didn't provide a submission or a file type.
    if (lab === -1) {
      this.toggleIncompleteFormModal('Please select the task that you want to upload.');
      return;
    }
    if (fileType === -1) {
      this.toggleIncompleteFormModal('Please select the file type that you want to upload.');
    }
  };

  toggleSubmitModal() {
    this.setState((prevState) => ({
      showSubmitModal: !prevState.showSubmitModal,
    }));
  }

  toggleIncompleteFormModal(message) {
    this.setState((prevState) => ({
      showIncompleteFormModal: !prevState.showIncompleteFormModal,
      incompleteFormModalMessage: message,
    }));
  }

  render() {
    const curState = this.state;
    const curFileTypes = curState.submissionTypes;
    const fileTypes = {};
    let i;
    for (i = 0; i < curFileTypes.length; i += 1) {
      const { ext } = curFileTypes[i];
      const { subId } = curFileTypes[i];
      if (!fileTypes[subId]) {
        fileTypes[subId] = [ext];
      } else {
        fileTypes[subId].push(ext);
      }
    }

    return (
      <div>
        <Modal show={curState.showSubmitModal} onHide={this.toggleSubmitModal} animation>
          <Modal.Header closeButton>
            <Modal.Title>Are you sure you want to submit?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Make sure you selected lab and file type before proceeding.</p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.toggleSubmitModal} variant="secondary">
              Cancel
            </Button>
            <Button
              onClick={() => {
                this.submitAssignmentForStudent();
                this.toggleSubmitModal();
              }}
              variant="primary"
            >
              Submit{' '}
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={curState.showIncompleteFormModal}
          onHide={() => this.toggleIncompleteFormModal('Contact webdev')}
          animation
        >
          <Modal.Header closeButton>
            <Modal.Title>Form is incomplete!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Make sure you entered all the necessary information before pressing submit.</p>
            <p>
              <b>Error: </b>
              {curState.incompleteFormModalMessage}
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => this.toggleIncompleteFormModal('Contact webdev')}
              variant="primary"
            >
              Okay
            </Button>
          </Modal.Footer>
        </Modal>
        <Form>
          <Row>
            <Col>
              <p>
                <b>Lab:</b>
              </p>
            </Col>
            <Col xs={10}>
              <SubmissionDropdown
                default={curState.curSubmission}
                limited
                parentCallback={this.getCurSubmission}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <p>
                <b>File type:</b>
              </p>
            </Col>
            <Col xs={10}>
              <Form.Control as="select" onChange={this.getCurFileType}>
                <option value="-1">---Select File Type---</option>
                {this.getFileTypes(curState.curSubmission, fileTypes)}
              </Form.Control>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form>
                <Form.Group>
                  <Form.File id="formControlFile" label="File" />
                </Form.Group>
              </Form>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button variant="primary" onClick={this.toggleSubmitModal}>
                Submit Request
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

SubmitAssignment.propTypes = {
  showSubmitModal: propTypes.bool,
  showIncompleteFormModal: propTypes.bool,
  incompleteFormModalMessage: propTypes.string,
  curSubmission: propTypes.string,
  curFileType: propTypes.string,
  submissionTypes: propTypes.arrayOf(
    propTypes.shape({
      uid: propTypes.number,
      subId: propTypes.number,
      ext: propTypes.string,
    }),
  ),
};

SubmitAssignment.defaultProps = {
  showSubmitModal: false,
  showIncompleteFormModal: false,
  incompleteFormModalMessage: ":( You shouldn't be seeing this. Please contact WebDev!",
  curFileType: '-1',
  curSubmission: '-1',
  submissionTypes: [
    {
      uid: -1,
      subId: -1,
      ext: '-1',
    },
  ],
};
