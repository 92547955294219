import axios from 'axios';
import propTypes from 'prop-types';
import React, { Component } from 'react';

const HOST_NAME = 'https://storage.googleapis.com';
const BUCKET_NAME = 'egsitenew-experimental';
const SYLLABI_DIR = 'syllabi';

export default class RadAndOLSchedule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      radSyllabi: props.radSyllabi,
    };
  }

  componentDidMount() {
    axios.get('/syllabus-rad').then((res) => {
      console.log('RAD syllabus:', res);
      this.setState({
        radSyllabi: res.data[0],
      });
    });
  }

  render() {
    const curState = this.state;
    const syllabusPath = `${HOST_NAME}/${BUCKET_NAME}/${SYLLABI_DIR}/${curState.radSyllabi.filepath}`;
    return (
      <>
        <h4 className="display-6 mb-3">{curState.radSyllabi.name}</h4>
        <p className="small text-muted">
          Schedules are in Adobe Portable Document Format (PDF). To save to your computer,
          right-click and choose &quot;Save Link As...&quot; or &quot;Save Target As&quot;.
        </p>
        <div className="mb-4">
          <a href={syllabusPath}>View {curState.radSyllabi.name}</a>
        </div>
        <h4 className="display-6 mb-3">Open Lab Schedule</h4>
        <div className="embed-responsive embed-responsive-4by3">
          &gt;
          <iframe
            title="openLabCalendar"
            className="embed-responsive-item"
            src="https://www.google.com/calendar/embed?title=&nbsp;&amp;showDate=0&amp;showPrint=0&amp;showTabs=0&amp;showCalendars=0&amp;showTz=0&amp;mode=WEEK&amp;wkst=1&amp;bgcolor=%23FFFFFF&amp;src=eg.poly.edu_35393838313832302d373236%40resource.calendar.google.com&amp;color=%235229A3&amp;ctz=America%2FNew_York"
          />
        </div>
      </>
    );
  }
}

RadAndOLSchedule.propTypes = {
  radSyllabi: propTypes.shape({
    id: propTypes.number,
    name: propTypes.string,
    type: propTypes.number,
    filepath: propTypes.string,
    active: propTypes.number,
    updated: propTypes.string,
  }),
};

RadAndOLSchedule.defaultProps = {
  radSyllabi: {
    id: -1,
    name: '-1',
    type: -1,
    filepath: '-1',
    active: -1,
    updated: '-1',
  },
};
