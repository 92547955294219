import React, { Component } from 'react';
import axios from 'axios';
import SetPin from '../../components/pinAdministration/setPin';

export default class UsersTesting extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userInfo: {},
      isLoggedIn: false,
    };
  }

  componentDidMount() {
    axios.get('/getUserInfo/').then((res) => {
      console.log('response', res);
      this.setState({
        userInfo: res.data ? res.data : {},
        isLoggedIn: !!res.data,
      });
    });
  }

  render() {
    console.log('STATE: ', this.state);
    const { userInfo } = this.state;
    const { isLoggedIn } = this.state;
    return (
      <div>
        <h1>Welcome to the users testing page!</h1>
        {isLoggedIn ? (
          <div>
            <p>You are currently signed in as {`${userInfo.firstName} ${userInfo.lastName}`}</p>
            <p>
              Here&apos;s all the info we have on you:{' '}
              {Object.keys(userInfo).map((info) => (
                <li key={info}>
                  {info} : {userInfo[info]}
                </li>
              ))}
            </p>
            <SetPin />
          </div>
        ) : (
          <p>You are not currently signed in.</p>
        )}
      </div>
    );
  }
}
