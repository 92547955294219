import React, { Component } from 'react';
import axios from 'axios';
import { Button, Form, Row, Col, Modal } from 'react-bootstrap';
import propTypes from 'prop-types';

const config = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
};
const qs = require('querystring-es3');

export default class AddItem extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      itemName: props.itemName, // name of the item to be added
      itemCost: props.itemCost, // cost of the item (dddd.cc)
      sldpDefaults: props.sldpDefaults, // selected default SLDPs that this item is used for
      sldpOptions: props.sldpOptions, // all current active SLDPs (see componentDidMount())
      startingStock: props.startingStock, // stock of item when it's first added to the DB
      unit: props.unit, // unit of measurement (ft, lb, etc.)

      modalErrorMessage: props.modalErrorMessage, // error message that will be displayed on the error modal
      viewErrorModal: props.viewErrorModal, // boolean that triggers the error modal
    };
  }

  componentDidMount() {
    this._isMounted = true;
    axios.get('/sldp-active/').then((res) => {
      console.log('response', res);
      if (this._isMounted) {
        this.setState({
          sldpOptions: res.data,
        });
      }
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  toggleDefaultSLDP = (sldpID) => {
    const { sldpDefaults } = this.state;
    if (sldpDefaults.includes(sldpID)) {
      // this sldp is already a default; we must remove it
      const index = sldpDefaults.indexOf(sldpID);
      sldpDefaults.splice(index, 1);
    } else {
      // this sldp is not a default; we must add it
      sldpDefaults.push(sldpID);
    }
    this.setState({
      sldpDefaults,
    });
    console.log('Current defaults: ', sldpDefaults);
  };

  updateItemName = (e) => {
    this.setState({
      itemName: e.target.value,
    });
  };

  updateCost = (e) => {
    this.setState({
      itemCost: e.target.value,
    });
  };

  updateStartingStock = (e) => {
    this.setState({
      startingStock: e.target.value,
    });
  };

  updateUnit = (e) => {
    this.setState({
      unit: e.target.value,
    });
  };

  toggleErrorModal = () => {
    this.setState((prevState) => ({
      viewErrorModal: !prevState.viewErrorModal,
    }));
  };

  submit = () => {
    const curState = this.state;
    const name = curState.itemName;
    const cost = curState.itemCost;
    let { sldpDefaults } = this.state;
    const { startingStock } = this.state;
    const { unit } = this.state;

    // form validation
    if (sldpDefaults === []) {
      sldpDefaults = [-1];
    }
    if (name === '') {
      this.setState({
        viewErrorModal: true,
        modalErrorMessage: 'The item name cannot be empty. Please fix and resubmit.',
      });
      return;
    }
    if (cost === -1) {
      this.setState({
        viewErrorModal: true,
        modalErrorMessage: 'The item cost cannot be empty. Please fix and resubmit.',
      });
      return;
    }
    if (cost < 0 || cost >= 10000) {
      this.setState({
        viewErrorModal: true,
        modalErrorMessage:
          'Your cost is oustide of range. An item can cost anywhere from $0 to $9999.99. Please fix and resubmit.',
      });
      return;
    }
    if (startingStock === '') {
      this.setState({
        viewErrorModal: true,
        modalErrorMessage: 'The starting stock cannot be empty. Please fix and resubmit.',
      });
      return;
    }
    if (startingStock.toString().indexOf('.') !== -1) {
      this.setState({
        viewErrorModal: true,
        modalErrorMessage:
          'Please make the starting stock a whole number. If you really want fractional stock numbers contact webdev and we can try our best.',
      });
      return;
    }

    // submitting to API:
    const obj = {
      itemName: name,
      cost,
      sldps: sldpDefaults,
      startingStock,
      unit,
    };
    console.log('Data to send to API: ', obj);
    axios
      .post('/inventory-item/', qs.stringify(obj), config)
      .then((response) => {
        console.log(response);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const curState = this.state;
    const { sldpOptions } = curState;
    console.log('add item state: ', curState);
    const sldpCheckboxes = sldpOptions.map((sldp) => (
      <Form.Check
        key={`${sldp.shortName}-checkbox`}
        type="checkbox"
        label={sldp.shortName}
        onChange={() => this.toggleDefaultSLDP(sldp.sldpID)}
      />
    ));
    return (
      <div>
        <Form>
          <Row>
            <Col xs={2}>
              <b>Item Name: </b>
            </Col>
            <Col>
              <Form.Control required onBlur={this.updateItemName} placeholder="Enter item name" />
            </Col>
          </Row>

          <Row>
            <Col xs={2}>
              <b>Item Cost: </b>
            </Col>
            <Col>
              <Form.Control
                required
                onBlur={this.updateCost}
                type="number"
                step="0.01"
                placeholder="Enter cost of item"
              />
              <Form.Text muted>
                Note: prices can range from $0.00 - $9999.99. Please do not try to add a cost
                outside of this range.
              </Form.Text>
              <Form.Text muted>
                Also: Please include cents! Even if it&apos;s a whole dollar amount, please append a
                &quot;.00&quot; to your cost.
              </Form.Text>
            </Col>
          </Row>

          <Row>
            <Col xs={2}>
              <b>Stock to Start: </b>
            </Col>
            <Col>
              <Form.Control
                required
                onBlur={this.updateStartingStock}
                type="number"
                placeholder="Enter stock"
              />
            </Col>
          </Row>

          <Row>
            <Col xs={2}>
              <b>Unit: </b>
            </Col>
            <Col>
              <Form.Control onBlur={this.updateUnit} placeholder="Enter unit" />
              <Form.Text muted>
                Leave blank if units are irrelevant (ex: Raspberry Pi, Arduino, EV3 brick, etc).
                This is more intended for materials (acryllic, wood, etc).
              </Form.Text>
            </Col>
          </Row>

          <Row>
            <Col xs={2}>
              <b>Default SLDPs:</b>
            </Col>
            <Col>
              <Form.Group controlId="formBasicCheckbox">{sldpCheckboxes}</Form.Group>
              <Form.Text muted>
                This will tell us in which kit(s) this item belongs by default, if any.
              </Form.Text>
            </Col>
          </Row>

          <Button onClick={this.submit} variant="primary" type="submit">
            Add Item
          </Button>
        </Form>
        <Modal show={curState.viewErrorModal} onHide={this.toggleErrorModal} animation>
          <Modal.Header closeButton>
            <Modal.Title>There is an error with your submission.</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>{curState.modalErrorMessage}</p>
          </Modal.Body>

          <Modal.Footer>
            <Button
              onClick={() => {
                this.toggleErrorModal();
              }}
              variant="primary"
            >
              Okay
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

AddItem.propTypes = {
  itemName: propTypes.string,
  itemCost: propTypes.number,
  sldpDefaults: propTypes.arrayOf(propTypes.number),
  sldpOptions: propTypes.arrayOf(
    propTypes.shape({
      isActive: propTypes.number,
      longName: propTypes.string,
      shortName: propTypes.string,
      sldpID: propTypes.number,
    }),
  ),
  startingStock: propTypes.number,
  unit: propTypes.string,
  modalErrorMessage: propTypes.string,
  viewErrorModal: propTypes.bool,
};

AddItem.defaultProps = {
  itemName: '',
  itemCost: -1,
  sldpDefaults: [],
  sldpOptions: [
    {
      isActive: -1,
      longName: '-1',
      shortName: '-1',
      sldpID: -1,
    },
  ],
  startingStock: 0,
  unit: null,
  modalErrorMessage: "Contact Webdev. You shouldn't be seeing this.",
  viewErrorModal: false,
};
