import React, { Component } from 'react';
import DisplayStudentSubmission from '../../components/studentSubmission/studentSubmission';
import SubmitAssignment from '../../components/studentSubmission/submitAssignment';

export default class StudentSubmission extends Component {
  render() {
    return (
      <>
        <h3 className="display-6 mb-3">Submit Assignement</h3>
        <SubmitAssignment />
        <hr />
        <h3 className="display-6">Assignments Submission</h3>
        <DisplayStudentSubmission status="OPEN" />
      </>
    );
  }
}
