import React, { Component } from 'react';
import propTypes from 'prop-types';

import NewReportPinForm from '../../components/reports/newReportPinForm';
import ViewReportsByUser from '../../components/reports/viewReportsByUser';
import PayPeriodsDropdown from '../../components/convenientDropdowns/payPeriodsDropdown';

export default class Reports extends Component {
  constructor(props) {
    super(props);

    this.state = {
      payPeriodID: props.payPeriodID,
      isWritingReport: false,
    };

    this.updatePayPeriod = this.updatePayPeriod.bind(this);
    this.toggleIsWritingReport = this.toggleIsWritingReport.bind(this);
  }

  updatePayPeriod(newID) {
    this.setState({ payPeriodID: newID });
  }

  toggleIsWritingReport() {
    this.setState((prevState) => ({
      isWritingReport: !prevState.isWritingReport,
    }));
  }

  render() {
    const { props } = this;
    const curState = this.state;
    console.log('Pay period ID in index: ', curState.payPeriodID);
    return (
      <div>
        <NewReportPinForm parentCallback={this.toggleIsWritingReport} userInfo={props.userInfo} />
        <hr />
        {!curState.isWritingReport && (
          <div>
            <h1>View Reports by Pay Period</h1>
            <p>
              Select pay period:
              <PayPeriodsDropdown parentCallback={this.updatePayPeriod} />
            </p>
            <ViewReportsByUser payPeriodID={curState.payPeriodID} userID={props.userInfo.userID} />
          </div>
        )}
      </div>
    );
  }
}

Reports.propTypes = {
  payPeriodID: propTypes.string,
  userInfo: propTypes.shape({
    approved: propTypes.number,
    barcode: propTypes.string,
    firstName: propTypes.string,
    groupID: propTypes.number,
    idNum: propTypes.string,
    joinDate: propTypes.string,
    lastName: propTypes.string,
    quit: propTypes.number,
    report_pin: propTypes.string,
    sectionID: propTypes.number,
    userEmail: propTypes.string,
    userID: propTypes.number,
    worktypeID: propTypes.number,
  }),
};

Reports.defaultProps = {
  payPeriodID: '-1',
  userInfo: {
    approved: -1,
    barcode: '-1',
    firstName: '-1',
    groupID: -1,
    idNum: '-1',
    joinDate: '-1',
    lastName: '-1',
    quit: -1,
    report_pin: '-1',
    sectionID: -1,
    userEmail: '-1',
    userID: -1,
    worktypeID: -1,
  },
};
