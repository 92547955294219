import React, { useState } from 'react';
import propTypes from 'prop-types';
import axios from 'axios';
// import { Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import * as AiIcons from 'react-icons/ai';
import { IconContext } from 'react-icons/lib';
import SidebarData from './sidebarData';
import LoginButton from '../loginButton/loginButton';
import UsersDropdown from '../convenientDropdowns/usersDropdown';
import logo from '../../../images/logo.png';
import SubBar from './subBar';
import './sidebar.scss';

const Nav = styled.div`
  background: white;
  top: 0;
  height: 60px;
  position: fixed;
  display: flex;
  justify-content: flex-start;
  align-item: center;
`;

const NavIcon = styled(Link)`
  margin-top: 7px;
  margin-left: 2rem;
  font-size: 1.7rem;
  height: 40px;
  display: flex;
  justfy-content: flex-start;
  align-items: center;
`;

const SidebarNav = styled.nav`
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: ${({ status }) => (status ? '0' : '-100%')};
  transition: 250ms;
  z-index: 10;
`;

const SidebarWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const devLoginUser = (userID) => {
  axios.get(`users/${userID}`).then((res) => {
    axios.get(`/devLogin/${res.data[0].userEmail}`).then(() => {
      window.location.replace('/usersTesting');
    });
  });
};

const Sidebar = (props) => {
  const [status, setSideBar] = useState(true);
  const showSidebar = () => {
    props.parentCallback(!status);
    setSideBar(!status);
  };
  // const handleRedirect = () => {
  //   window.location.assign('https://eg.poly.edu/index.php');
  // };

  return (
    <>
      <IconContext.Provider value={{ color: '#57068c' }}>
        <Nav className="nav border-bottom" style={{ zIndex: '99' }}>
          <NavIcon to="#">
            <AiIcons.AiOutlineMenu onClick={showSidebar} />
            {/* <LinkContainer exact to="/" style={{ 'padding-left': '30px' }}>
              <img className="img-fluid logo mx-auto d-block" src={logo} alt="eg logo" />
            </LinkContainer> */}
          </NavIcon>
          {/* <div className="redirectButton">
            <Button onClick={handleRedirect} variant="outline-primary" size="sm">
              Take me to eg.poly.edu
            </Button>
          </div> */}
          <div className="loginButton">
            <LoginButton />
          </div>
        </Nav>
        <SidebarNav status={status} className="shadow-sm bg-light" style={{ zIndex: '100' }}>
          <SidebarWrap>
            <NavIcon to="#" style={{ marginBottom: '18px' }}>
              <AiIcons.AiOutlineMenuFold onClick={showSidebar} />
              <LinkContainer exact to="/">
                <img className="img-fluid logo mx-auto d-block" src={logo} alt="eg logo" />
              </LinkContainer>
            </NavIcon>
            <div style={{ overflowY: 'scroll', display: 'block' }}>
              {SidebarData.map((item) => (
                <SubBar item={item} key={item.title} />
              ))}
            </div>
            <div style={{ display: 'block' }}>
              {process.env.NODE_ENV === 'development' && (
                <div className="dev-userSelector">
                  <p>
                    <b>Login as</b>
                  </p>
                  <UsersDropdown groupID="all" parentCallback={devLoginUser} />
                </div>
              )}
            </div>
          </SidebarWrap>
        </SidebarNav>
      </IconContext.Provider>
    </>
  );
};

export default Sidebar;

Sidebar.propTypes = {
  parentCallback: propTypes.func,
};

Sidebar.defaultProps = {
  parentCallback: () => {},
};
