import React, { Component } from 'react';
import axios from 'axios';
import { Table, Form, Row, Col, Button } from 'react-bootstrap';
import propTypes from 'prop-types';

import ItemDropdown from '../convenientDropdowns/itemDropdown';

const config = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
};
const qs = require('querystring-es3');

export default class IndividualItems extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: props.items, // array of all individual items currently on display
      itemID: props.itemID, // the item that we want to look at the individual items of

      itemNumber: props.itemNumber, // the number of the new item we're adding
    };
  }

  getIndividualItems = (itemID) => {
    axios.get(`/inventory-individualItems/${itemID}`).then((res) => {
      this.setState({
        items: res.data,
        itemID,
      });
    });
  };

  updateItemNumber = (e) => {
    this.setState({
      itemNumber: e.target.value,
    });
  };

  addNewItem = (event) => {
    const curState = this.state;
    event.preventDefault(); // prevents reloading of the page, mainly for convenience while developing
    if (curState.itemID === -1 || curState.itemID === '-1') return;
    const obj = {
      itemID: curState.itemID,
      itemNumber: curState.itemNumber,
    };

    // console.log("data to post to API: ", obj);
    axios
      .post('/inventory-individualItems/', qs.stringify(obj), config)
      .then(() => {
        this.getIndividualItems(curState.itemID); // lets user see newly added item on page
      })
      .catch((err) => {
        console.log(err);
      });
  };

  deleteIndividualItem = (itemID) => {
    const curState = this.state;
    axios.delete(`/inventory-individualItems/${itemID}`).then(() => {
      this.getIndividualItems(curState.itemID);
    });
  };

  render() {
    const curState = this.state;
    console.log('individual items state: ', curState);
    const trs = curState.items.map((item) => {
      const individualItemID = item.id; // auto-incrementing PK ID of the individual item.
      const { itemNumber } = item;
      const isCheckedOut = item.isCheckedOut ? 'yes' : 'no';
      const groupID = item.isCheckedOut ? item.groupID : 'n/a'; // TODO: eventaully want to make this link with the contract for this group
      return (
        <tr key={`tr-${individualItemID}`}>
          <td key={`itemNumber-${individualItemID}`}>{itemNumber}</td>
          <td key={`isCheckedOut-${individualItemID}`}>{isCheckedOut}</td>
          <td key={`groupID-${individualItemID}`}>{groupID}</td>
          <td key={`action-${individualItemID}`}>
            <Button onClick={() => this.deleteIndividualItem(individualItemID)} variant="danger">
              Delete
            </Button>
          </td>
        </tr>
      );
    });
    if (curState.itemID === -1 || curState.itemID === '-1')
      return <ItemDropdown default={curState.itemID} parentCallback={this.getIndividualItems} />;
    return (
      <div>
        <ItemDropdown default={curState.itemID} parentCallback={this.getIndividualItems} />
        <br />
        <hr />
        <br />
        {trs.length === 0 && (
          <p>
            <b>No individual items for this item, but you can add some below:</b>
          </p>
        )}
        {trs.length !== 0 && (
          <div>
            <h4>Individual Items</h4>
            <p>
              Below you&apos;ll find a table that contains information for each individual item of
              the selected item.
            </p>
            <Table responsive striped bordered hover>
              <thead>
                <tr>
                  <th>Item Number</th>
                  <th>Checked Out</th>
                  <th>Group ID (if checked out)</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>{trs}</tbody>
            </Table>
          </div>
        )}
        <Form>
          <Row>
            <Col xs={3}>
              <b>New Individual Item:</b>
            </Col>
            <Col xs={5}>
              <Form.Control
                required
                onChange={this.updateItemNumber}
                type="number"
                placeholder="Enter item number"
              />
            </Col>
            <Col xs={4}>
              <Button onClick={this.addNewItem} variant="primary" type="submit">
                Add New Item
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

IndividualItems.propTypes = {
  itemID: propTypes.string,
  items: propTypes.arrayOf(
    propTypes.shape({
      groupID: propTypes.number,
      id: propTypes.number,
      isCheckedOut: propTypes.number,
      itemID: propTypes.number,
      itemNumber: propTypes.number,
    }),
  ),
  itemNumber: propTypes.string,
};

IndividualItems.defaultProps = {
  itemID: '-1',
  items: [
    {
      groupID: -1,
      id: -1,
      isCheckedOut: -1,
      itemID: -1,
      itemNumber: -1,
    },
  ],
  itemNumber: '-1',
};
