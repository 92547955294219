import React, { Component } from 'react';
import propTypes from 'prop-types';
import axios from 'axios';
import { Form } from 'react-bootstrap';

export default class SectionsDropdown extends Component {
  _isMounted = true;

  constructor(props) {
    super(props);

    this.state = {
      sections: props.sections,
    };
    this.updateCurSelection = this.updateCurSelection.bind(this);
  }

  componentDidMount() {
    axios.get('/sections/').then((res) => {
      if (this._isMounted) {
        this.setState({
          sections: res.data,
        });
      }
    });
  }

  componentWillUnount() {
    this._isMounted = false;
  }

  updateCurSelection(e) {
    const { props } = this;
    props.parentCallback(e.target.value);
  }

  render() {
    const { props } = this;
    const curState = this.state;
    const allSections = curState.sections;
    const selectOptions = allSections.map((section) => (
      <option id={section.secID} key={section.secID} value={section.secID}>
        {section.secName}
      </option>
    ));

    return (
      <div>
        <Form.Control value={props.default} as="select" onChange={this.updateCurSelection}>
          <option value="-1">---Select Section---</option>
          {selectOptions}
        </Form.Control>
      </div>
    );
  }
}

SectionsDropdown.propTypes = {
  sections: propTypes.arrayOf(
    propTypes.shape({
      secgroupID: propTypes.number,
      secgroupName: propTypes.string,
      secID: propTypes.number,
      secName: propTypes.string,
      active: propTypes.number,
      secProf: propTypes.number,
      secTA1: propTypes.number,
      secTA2: propTypes.number,
      secRecTA: propTypes.number,
      secWC: propTypes.number,
      secGrader: propTypes.number,
      secRoamer: propTypes.number,
      secObserver: propTypes.number,
      labRoom: propTypes.string,
      recRoom: propTypes.string,
      lecRoom: propTypes.string,
      isHSSec: propTypes.number,
    }),
  ),
  parentCallback: propTypes.func,
  default: propTypes.string,
};

SectionsDropdown.defaultProps = {
  sections: [
    {
      secgroupID: -1,
      secgroupName: '-1',
      secID: -1,
      secName: '-1',
      active: -1,
      secProf: -1,
      secTA1: -1,
      secTA2: -1,
      secRecTA: -1,
      secWC: -1,
      secGrader: -1,
      secRoamer: -1,
      secObserver: -1,
      labRoom: '-1',
      recRoom: '-1',
      lecRoom: '-1',
      isHSSec: -1,
    },
  ],
  default: '-1',
  parentCallback: () => console.log('forgot to give parent callback to sections dropdown'),
};
