import React, { Component } from 'react';
import Alert from 'react-bootstrap/Alert';
import styled from 'styled-components';
import propTypes from 'prop-types';
import './AlertCard.scss';

const AlertWrapper = styled.div`
  position: absolute;
  width: 40vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 100;
  top: 5px;
`;

const FixedWrapper = styled.div`
  position: fixed;
  width: 40vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 30vw;
  right: 30vw;
  z-index: 100;
  top: 5px;
`;

const types = {
  1: 'danger', // red Important
  2: 'warning', // Yellow Warning
  3: 'success', // Green Sucess
};

const timer = (alerts) => {
  const newAlerts = [...alerts];
  for (let i = 0; i < alerts.length; i += 1) {
    newAlerts[i].timeIn = new Date();
  }
  return newAlerts;
};

export default class Alerts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      alerts: timer(props.alert),
    };
  }

  addAlert = (alert) => {
    const curself = this.state;
    const newAlert = alert;
    newAlert.timeIn = new Date();
    const test = [newAlert, ...curself.alerts];
    this.setState({ alerts: test });
  };

  removeAlert = (index) => {
    const { alerts } = this.state;
    alerts.splice(index, 1);
    this.setState({ alerts });
  };

  render() {
    const curself = this.state;
    return (
      <FixedWrapper>
        <AlertWrapper>
          {curself.alerts.map((alert, ind) => (
            <Alert variant={types[alert.type]} onClose={() => this.removeAlert(ind)} dismissible>
              {alert.title ? <Alert.Heading>{alert.title}</Alert.Heading> : null}
              <p className="text">{alert.text ? alert.text : null}</p>
            </Alert>
          ))}
        </AlertWrapper>
      </FixedWrapper>
    );
  }
}

Alerts.propTypes = {
  alert: propTypes.arrayOf(
    propTypes.shape({
      type: propTypes.number,
      text: propTypes.string,
      title: propTypes.string,
    }),
  ),
};

Alerts.defaultProps = {
  alert: [],
};
